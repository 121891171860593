import {
    FocusableComponentLayout,
    FocusContext,
    useFocusable,
    setFocus,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';

import { CartItem } from '../../../../../redux/slices/cartSlice';
import { Extra, Sauce } from '../../../../../redux/slices/dbFoodMenuSlice';
import ThreeToppingImages from './ThreeToppingImages';

type cartItemProps = {
    cartItem: CartItem;
    focusKey: string;
    cartLength: number;
    cartIndex: number;
    onFocus: (layout: FocusableComponentLayout, props: unknown, details: object) => void;
};
const PastOrderCartItemRow = ({
    cartItem,
    focusKey: focusKeyParam,
    cartLength,
    cartIndex,
    onFocus,
}: cartItemProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const onArrowPress = (direction: string) => {
        if (direction === `down` && cartIndex >= cartLength - 1) {
            console.log('Got inside the function@@@@@@@', direction, cartIndex, cartLength);

            setFocus('add-pastorder-to-cart-button');
        }
        return true;
    };

    const { ref, focusKey, focused } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: onFocus,
        onArrowPress: onArrowPress,
    });

    const calculateTotalPrice = (cart: CartItem): number => {
        let totalPrice = 0;
        totalPrice += cart.item.price;
        // Add extras price
        cart.extras.forEach((extra) => {
            totalPrice += extra.price;
        });
        if (cart.sauces) {
            cart.sauces.forEach((sauce) => {
                totalPrice += sauce.price * sauce.sauceQuantity;
            });
        }
        totalPrice = Math.round(totalPrice * 100) / 100;
        return totalPrice;
    };

    const extraIconUrl = ``;

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ref={ref}
                className={`w-[100%] my-[1vw] ${cartLength > 1 && `border-b-gray-400 border-b-[0.05vw] `}  flex flex-col p-[2vh] relative ${(focused || isHovered) && `!border-white !border-[0.08vw] !rounded-lg`}`}
            >
                <div className="w-full flex items-start justify-between ml-[1.2vw]">
                    <img
                        className="w-[5vw] h-[5vw] bg-gray-card  rounded-[1.4vw] object-cover"
                        src={`${cartItem.item.icon ? cartItem.item.icon : `https://png.pngtree.com/png-clipart/20230320/ourmid/pngtree-round-pizza-realistic-fast-food-digital-artwork-png-image_6657786.png`}`}
                        alt="cartItemIcon"
                    />

                    <div className="flex flex-col flex-1 mx-[1vw] pt-[1.5vw] pr-[1vw]">
                        <div className="flex justify-between text-[1.2vw] font-bold">
                            <span className="text-[1.5vw]">{cartItem.item.name}</span>
                            <span className="text-[1.5vw]">₪{cartItem.item.price}</span>
                        </div>
                        <div className="flex flex-col justify-center m-[1vw]">
                            {cartItem.extras.map((extra: Extra, index: number) => (
                                <div
                                    key={`cartItem-Row-${index}`}
                                    className={'flex items-center justify-between mb-[1.5vw]'}
                                >
                                    <div className="flex items-center my-[0.8vw]">
                                        {/* <img
                                            className="w-[2vw] h-[2vw] ml-[1vw]"
                                            src={`${extra.icon ? extra.icon : `https://cdn-icons-png.flaticon.com/512/149/149071.png`}`}
                                            alt=""
                                        /> */}
                                        <span
                                            className="text-[1.4vw] ml-[1vw] text-center"
                                            key={index}
                                        >{`${extra.name}`}</span>
                                    </div>
                                    <div className="flex items-center ml-[10vw] w-[18vw] justify-evenly">
                                        <ThreeToppingImages whatSizeSelected={extra} />
                                        <span
                                            className="text-[1.4vw] min-w-[6vw]"
                                            key={index}
                                        >{`+ ${extra.price} ₪`}</span>
                                    </div>
                                </div>
                            ))}
                            {cartItem.sauces &&
                                cartItem.sauces.map((sauce: Sauce, index: number) => (
                                    <div
                                        key={`cartItem-Row-sauce-${index}`}
                                        className={'flex flex-col mb-[1.5vw]'}
                                    >
                                        {Array(sauce.sauceQuantity)
                                            .fill(0)
                                            .map((_, index) => {
                                                return (
                                                    <div
                                                        className="flex justify-between w-[100%] mb-[0.8vw]"
                                                        key={`cartItem-secondRow-sauce-${index}`}
                                                    >
                                                        <div className="flex items-center">
                                                            <img
                                                                className="w-[2vw] h-[2vw] ml-[1vw]"
                                                                src={`${sauce.icon ? sauce.icon : `https://cdn-icons-png.flaticon.com/512/149/149071.png`}`}
                                                                alt=""
                                                            />
                                                            <span
                                                                className="text-[1.4vw] text-center"
                                                                key={index}
                                                            >{`${sauce.name}`}</span>
                                                        </div>
                                                        <span
                                                            className="text-[1.4vw]"
                                                            key={index}
                                                        >{`+ ${sauce.price} ₪`}</span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ))}
                            {cartItem.extras.length > 0 ||
                                (cartItem.sauces && cartItem.sauces.length > 0 && (
                                    <span className="text-[1.5vw] font-bold absolute left-[2vw] bottom-[0.4vw]">
                                        סך הכל: ₪{calculateTotalPrice(cartItem)}
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default PastOrderCartItemRow;
