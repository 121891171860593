import { ExtraSizesType } from '../../redux/slices/dbFoodMenuSlice';
import { getToppingExtraImageCss } from './EditFoodScreenHelperFunctions';

type ToppingExtraImageProps = {
    url: string;
    menuItemName: string;
    toppingSize: ExtraSizesType;
};
const ToppingExtraImage = ({ url, menuItemName, toppingSize }: ToppingExtraImageProps) => {
    return (
        <div
            className={`z-7 absolute object-contain w-[15vw] h-[15vw] bg-cover bg-center ${getToppingExtraImageCss(menuItemName, toppingSize)}`}
            style={{ backgroundImage: `url(${url})` }}
        ></div>
    );
};

export default ToppingExtraImage;
