import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useRef } from 'react';

type OTPSingleInputProps = {
    focusKey: string;
    handleOtpChange: (otp: string, index: number) => void;
    otpIndex: number;
    otpLength: number;
    inputValue: string;
};

export const OTPSingleInput = ({
    focusKey: focusKeyParam,
    handleOtpChange,
    otpIndex,
    inputValue,
}: OTPSingleInputProps) => {
    const { ref, focused, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        onFocus: () => {
            OtpInputRef.current?.focus();
            OtpInputRef.current?.select();
        },
        onBlur: () => {
            OtpInputRef.current?.blur();
        },
    });
    const OtpInputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = Number(value);
        if ((isNaN(numericValue) || numericValue < 0 || numericValue > 9) && value !== '') {
            return;
        }
        handleOtpChange(value, otpIndex);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputValue !== '') {
            return;
        }
        if (e.key === 'Backspace' && otpIndex > 0) {
            e.preventDefault();
            handleOtpChange('', otpIndex);
        }
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex items-center justify-center" ref={ref}>
                <input
                    ref={OtpInputRef}
                    value={inputValue ? inputValue : ''}
                    maxLength={1}
                    placeholder={''}
                    onInput={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className={`m-[0.5vw] border-white border-[0.1vw] !w-[5.5vw] !h-[6.5vw] rounded-[30%] bg-black text-center text-[1.5vw] ${focused && `!outline-none !border-pizzahut-red`}`}
                />
            </div>
        </FocusContext.Provider>
    );
};
