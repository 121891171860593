import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import PersonalAssistantLogo from '../../PersonalAssistantLogo';
import { AppDispatch, useSelector } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ResetChosenExtras,
    ResetChosenItemToKeep,
} from '../../../../redux/slices/personalAssistantSlice';
import { SuggestedItemAndButtons } from './SuggestedItemAndButtons';
import StepBackButton from '../StepBackButton';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type ChooseToKeepItemProps = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
    handleResetPageFunction: () => void;
    setChooseItemKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseExtrasKey: React.Dispatch<React.SetStateAction<number>>;
    setChooseToKeepItemKey: React.Dispatch<React.SetStateAction<number>>;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
};
const ChooseToKeepItem = ({
    focusKey: focusKeyParam,
    onRowFocus,
    handleResetPageFunction,
    setChooseItemKey,
    setChooseExtrasKey,
}: ChooseToKeepItemProps) => {
    const chooseToKeepItemTranslation = createTranslateFunction(
        'personal_assistant_screen.choose_to_keep_item',
    );
    const dispatch = useDispatch<AppDispatch>();
    const currentAssistantStep = useSelector((state) => state.personalAssistant.assistantStep);
    const usersName = useSelector((state) => state.userAuth.user?.first_name ?? '');
    const { chosenItem } = useSelector((state) => state.personalAssistant);
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        onFocus: onRowFocus,
        isFocusBoundary: true,
        focusBoundaryDirections: ['up'],
        preferredChildFocusKey: `add-suggested-item-to-cart-button`,
    });

    const handleStepBackFunction = () => {
        if (chosenItem?.extras && chosenItem.extras.length > 0) {
            dispatch(ResetChosenItemToKeep());
            setFocus('STT-CHOOSE-EXTRAS');
            setChooseExtrasKey((prev) => prev + 1);
        } else {
            setFocus('STT-CHOOSE-ITEM');
            dispatch(ResetChosenExtras());
            setChooseItemKey((prev) => prev + 1);
        }
    };

    useEffect(() => {
        if (currentAssistantStep === 'chooseToKeepItem') {
            focusSelf();
        }
    }, [currentAssistantStep]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="w-full flex flex-col mt-[4vw]">
                <div className="flex flex-col w-full justify-start pr-[4vw] pl-[2vw] mb-[7vw] relative">
                    <span className="text-[1.8vw]">
                        {chooseToKeepItemTranslation('header', { name: usersName })}
                    </span>
                    <PersonalAssistantLogo className="!top-[-2vw] !right-[0.5vw]" />
                </div>
                <div ref={ref} className="flex justify-start pr-[24vw] items-center">
                    <StepBackButton
                        focusKey="SBB-CHOOSE-SUGGESTED-ITEM"
                        handleOnClick={handleStepBackFunction}
                        className="ml-[1.5vw] w-[6vw] h-[6vw]"
                    />
                    <SuggestedItemAndButtons handleResetPageFunction={handleResetPageFunction} />
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseToKeepItem;
