const SelectedOptionCheckmark = () => {
    return (
        <div
            className={`absolute object-contain flex justify-center items-center rounded-full border-[0.15vw] border-black bg-pizzahut-red bg-center top-[-0.8vw] right-[-0.8vw] w-[2.7vw] h-[2.7vw]`}
        >
            <img
                className="w-[46%] h-[46%]"
                src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/CheckMark.svg"
                alt=""
            />
        </div>
    );
};

export default SelectedOptionCheckmark;
