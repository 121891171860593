import { Popover, Text } from '@mantine/core';
import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    focusKey?: string;
    onClick?: () => void;
    className?: string;
    onFocusClass?: string;
    focusClassName?: string;
    onBtnFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    popOverContent: string;
};
const TransparentButtonWithPopover = ({
    focusKey: focusKeyParam,
    children,
    onClick,
    className,
    focusClassName,
    onBtnFocus,
    popOverContent,
}: ButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { ref, focused, focusKey } = useFocusable({
        onFocus: onBtnFocus,
        onEnterPress: onClick,
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <Popover
                position="top"
                withArrow
                shadow="sm"
                styles={(theme) => ({
                    arrow: {
                        borderColor: 'transparent', // Set the color for the arrow
                    },
                })}
                opened={focused || isHovered}
            >
                <Popover.Target>
                    <button
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={onClick}
                        ref={ref}
                        className={`
                        bg-white bg-opacity-10 transition px-[1vw] py-[2vh] text-[1vw] text-white rounded-full outline-none ${className}
                        ${(focused || isHovered) && `bg-opacity-30 scale-105 ${focusClassName}`}
                    `}
                    >
                        {children}
                    </button>
                </Popover.Target>
                <Popover.Dropdown
                    style={{
                        pointerEvents: 'none',
                        color: 'white',
                        backgroundColor: 'black',
                        width: '12vw',
                        height: `4vw`,
                        borderRadius: '30vw',
                        textAlign: 'center',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                    }}
                >
                    <Text className="text-[1.2vw]">{`${popOverContent}`}</Text>
                </Popover.Dropdown>
            </Popover>
        </FocusContext.Provider>
    );
};

export default TransparentButtonWithPopover;
