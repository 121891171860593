import { useEffect, useState } from 'react';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.lang = 'he-IL';
recognition.interimResults = false;
recognition.maxAlternatives = 1;

// let recognition: any = null;
// if ("webkitSpeechRecognition" in window) {
//   recognition = new webkitSpeechRecognition();
//   recognition.continuous = true;
//   recognition.lang = "en-US";
// }
const useSpeechRecognition = () => {
    const [voiceRecognitionText, setVoiceRecognitionText] = useState<string>('');
    const [isListening, setIsListening] = useState<boolean>(false);

    useEffect(() => {
        if (!recognition) {
            return;
        }
        recognition.onresult = (event: SpeechRecognitionEvent) => {
            const transcript = event.results[0][0].transcript;
            setVoiceRecognitionText(transcript);
            // recognition.stop();
            recognition.stop = function () {
                console.log('Stopped listening now!');
            };
            setIsListening(false);
        };

        return () => {
            recognition.stop();
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognition = new SpeechRecognition();
            // recognition = new webkitSpeechRecognition();
            recognition.continuous = false;
            recognition.lang = 'he-IL';
        };
    }, []);

    const startListening = () => {
        recognition.stop();
        setVoiceRecognitionText('');
        setIsListening(true);
        recognition.start();
    };
    const stopListening = () => {
        setIsListening(false);
        recognition.stop();
    };

    return {
        voiceRecognitionText,
        setVoiceRecognitionText,
        isListening,
        startListening,
        hasRecognitionSupport: !!recognition,
        stopListening,
    };
};
export default useSpeechRecognition;
