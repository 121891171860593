import ArrowScrollSvg from './ArrowScrollSvg';

type IsAbleToScrollIndicatorProps = {
    indexInRow: number;
    rowLength: number;
    titleString?: string;
};
const IsAbleToScrollIndicator = ({
    indexInRow,
    rowLength,
    titleString,
}: IsAbleToScrollIndicatorProps) => {
    const shouldDisplayRightArrow = indexInRow > 0;
    const shouldDisplayLeftArrow = indexInRow < rowLength - 1;

    return (
        <div className="w-full h-[3vw] p-0 my-[1vw] relative flex">
            {titleString && (
                <span className="text-white text-[1.7vw] font-bold">{`${titleString}`}</span>
            )}
            {shouldDisplayRightArrow && <ArrowScrollSvg direction="right" />}
            {shouldDisplayLeftArrow && <ArrowScrollSvg direction="left" />}
        </div>
    );
};

export default IsAbleToScrollIndicator;
