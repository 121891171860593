import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';
import { addToCart, CartItem, updateExtras } from '../../redux/slices/cartSlice';
import { Extra, ExtraSizesType } from '../../redux/slices/dbFoodMenuSlice';
import Counter from '../../components/Buttons/Counter/Counter';
import RedButton from '../../components/Buttons/RedButton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import BackButton from '../../components/Buttons/BackButton';
import { calculateFinalPrice, getImageUrl } from './EditFoodScreenHelperFunctions';
import ToppingExtraImage from './ToppingExtraImage';

type ItemSideProps = {
    focusKey: string;
    cartIndex: number;
    categoryName: string;
    currentItem: CartItem;
    pizzaToppingsArray: Extra[];
    itemToppingsArrayLength: number;
    IsCategoryPizza: () => boolean;
    setNotEnoughToppingsAlertModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ItemSide = ({
    focusKey: focusKeyParam,
    cartIndex,
    categoryName,
    currentItem,
    pizzaToppingsArray,
    itemToppingsArrayLength,
    IsCategoryPizza,
    setNotEnoughToppingsAlertModalOpen,
}: ItemSideProps) => {
    const [itemCounter, setItemCounter] = useState<number>(1);

    const TranslateFunction = createTranslateFunction('edit_food_screen.personalize');
    const ButtonTranslateFunction = createTranslateFunction('buttons');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const doesProductHaveToppingsOrSauces =
        currentItem.item?.extras?.length > 0 || currentItem.item?.sauces?.length > 0;

    const handleAddToCart = async () => {
        if (
            categoryName === 'סלטים' &&
            currentItem.item.extras.length > 0 &&
            itemToppingsArrayLength <= 2
        ) {
            setNotEnoughToppingsAlertModalOpen(true);
            setFocus('MAX-TOPPINGS-ALERT');
            return;
        }
        for (let i = 0; i < itemCounter; i++) {
            if (cartIndex >= 0) {
                dispatch(updateExtras({ itemIndex: cartIndex, updatedCartItem: currentItem }));
            } else {
                dispatch(addToCart(currentItem));
            }
        }
        navigate('/');
    };

    const getItemIcon = () => {
        return currentItem.item.icon
            ? currentItem.item.icon
            : 'https://i.ibb.co/Fz90kTc/Plain-pizza-5pizza.png';
    };

    const areThereExtras = () => {
        return currentItem.item?.extras.length > 0;
    };
    const areThereSauces = () => {
        return currentItem.item?.sauces?.length > 0;
    };

    const shouldRenderBackButtonFurther = () => {
        if (areThereExtras() === false && areThereSauces() === false) {
            return true;
        }
        return false;
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`flex flex-col relative w-[50%] h-full pt-[9vw] pr-[2vw] ${!doesProductHaveToppingsOrSauces && `!pt-[4vw] pr-0`}`}
            >
                <div
                    className={`${shouldRenderBackButtonFurther() ? `absolute top-[4vw] right-[-18vw]` : `absolute top-[4vw] right-[4vw]`}`}
                >
                    <BackButton />
                </div>
                <div
                    className={`h-[90%] flex flex-col justify-between ${!doesProductHaveToppingsOrSauces && `!justify-evenly`}`}
                >
                    <div className="flex flex-col items-center">
                        <div className="relative">
                            <img
                                className={`w-[26vw] h-[26vw] object-cover mb-[1vw]`}
                                src={`${getItemIcon()}`}
                                alt="Plain pizza image"
                            />
                            {pizzaToppingsArray.map((topping, key) => {
                                return (
                                    <ToppingExtraImage
                                        key={topping.name + key}
                                        url={getImageUrl(topping.name, topping.extraSize ?? 'full')}
                                        menuItemName={`${currentItem?.item?.name}`}
                                        toppingSize={
                                            (topping.extraSize as ExtraSizesType) ?? 'full'
                                        }
                                    />
                                );
                            })}
                        </div>
                        <span className="text-[2vw]">{`${currentItem?.item?.name}`}</span>
                        {currentItem?.item?.description && (
                            <span className="text-[1.5vw] text-gray-400 text-center">{`${currentItem?.item?.description}`}</span>
                        )}
                    </div>
                    <div className="flex justify-between items-center w-[100%]">
                        {!cartIndex && cartIndex != 0 && (
                            <Counter
                                className="w-[30%] flex justify-evenly items-center"
                                stateValue={itemCounter}
                                setStateFunction={setItemCounter}
                                focusKey="quantity-counter-button"
                            />
                        )}
                        <div
                            className={`mx-[3vw] ${cartIndex >= 0 ? 'w-[100%]' : 'w-[44%]'} flex items-center justify-between`}
                        >
                            {IsCategoryPizza() && (
                                <span className="text-[1.2vw]">{`${pizzaToppingsArray.length > 0 ? `${pizzaToppingsArray.length} ${TranslateFunction(`extras_selected`)}` : `${TranslateFunction(`extras_not_selected`)}`}`}</span>
                            )}
                            <RedButton
                                focusKey={'ADD-TO-CART-BUTTON'}
                                className="w-[16vw] h-[5vw] mr-[2vw] !text-[1.2vw]"
                                onClick={() => {
                                    handleAddToCart();
                                }}
                            >
                                {`${ButtonTranslateFunction(`add_to_cart`)} - ${calculateFinalPrice(currentItem)}₪`}
                            </RedButton>
                        </div>
                    </div>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ItemSide;
