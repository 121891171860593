import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useCallback, useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { ChosenMenuRow } from './ChosenMenuRow';
import AddToWishlistButton from '../../../components/Buttons/AddToWishlistButton';
import { useSelector } from '../../../redux/store';
import { Item, MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { setAppBackground, setAppBackgroundTwClass } from '../../../redux/slices/layoutSlice';
import { Header } from '../../../components/Header';

type ChosenMenuScreenProps = {
    focusKey: string;
};

export const ChosenMenuScreen = ({ focusKey: focusKeyParam }: ChosenMenuScreenProps) => {
    const [displaySecondRow, setDisplaySecondRow] = useState<boolean>(false);
    const [selectedFirstRowData, setSelectedFirstRowData] = useState<MenuCategory>();
    const [secondRowItems, setSecondRowItems] = useState<Item[]>([]);

    const dispatch = useDispatch();

    const { chosenMenuScreen }: Readonly<Params<string>> = useParams();

    const allMenuItems = useSelector((state) => state.dbFoodMenu.categories);
    const chosenMenuData = allMenuItems.find(
        (menuCategory) => menuCategory.name === chosenMenuScreen,
    );
    const mergedSubCatAndItems = chosenMenuData
        ? [...chosenMenuData.sub_categories, ...chosenMenuData.items]
        : [];

    const [focusedData, setFocusedData] = useState<MenuCategory | Item>(mergedSubCatAndItems[0]);

    const { ref, focusKey, focused } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'CHOOSENFOODMENUROW',
    });

    const onRowFocus = useCallback(
        ({ y }, isSecondRow: boolean) => {
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
            if (!isSecondRow) {
                setSecondRowItems([]);
                setDisplaySecondRow(false);
            }
        },
        [ref],
    );

    const displaySecondRowFunction = (categoryData: MenuCategory) => {
        setSelectedFirstRowData(categoryData);
        //If category and no second row, display it, if there is a second row, only change it's data, else, close the second row.
        if ('items' in focusedData && !displaySecondRow) {
            setSecondRowItems(focusedData.items);
            setDisplaySecondRow(() => true);
            setFocus('CHOSEN_FOOD_MENU_SECOND_ROW');
        } else if (
            'items' in focusedData &&
            displaySecondRow &&
            JSON.stringify(focusedData.items) !== JSON.stringify(secondRowItems)
        ) {
            setSecondRowItems(focusedData.items);
        } else {
            setFocus('CHOOSENFOODMENUROW');
            setDisplaySecondRow(false);
        }
    };
    const checkIsItemTypeFunction = (input: MenuCategory | Item): input is Item => {
        return (input as Item).price !== undefined;
    };

    const getPriceFromItemFunction = (input: MenuCategory | Item): number | null => {
        if (checkIsItemTypeFunction(input)) {
            return input.price;
        } else {
            return null; // or handle MenuCategory case appropriately
        }
    };

    useEffect(() => {
        if (!focused) {
            setFocus('CHOOSENFOODMENUROW');
        }
    }, []);

    useEffect(() => {
        setDisplaySecondRow(false);
    }, [chosenMenuScreen]);

    useEffect(() => {
        console.log();

        if (focusedData && focusedData.bg_src) {
            const bg_src = focusedData.bg_src;
            dispatch(setAppBackground([bg_src, '']));
        } else if (chosenMenuData && chosenMenuData.bg_src) {
            dispatch(setAppBackground([chosenMenuData.bg_src, 'bg-no-repeat bg-cover bg-center']));
        } else {
            dispatch(
                setAppBackground([
                    'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/couple.jpg',
                    'bg-no-repeat bg-cover bg-center',
                ]),
            );
        }
    }, [focusedData]);

    const renderItemsOrSubMenu = () => {
        return (
            <div className={`h-full w-full flex flex-col p-[1vw] relative`}>
                <Header />
                <div className="absolute top-[12vw] right-[2vw] flex flex-col">
                    <span className="text-[2.4vw] font-bold mb-[0.3vw]">{`${focusedData.name}`}</span>
                    {focusedData.description && (
                        <span className="text-[1.3vw] max-w-[21vw] mb-[2vw]">
                            {`${focusedData.description}`}
                        </span>
                    )}
                    {getPriceFromItemFunction(focusedData) && (
                        <span className="text-[2.2vw] font-semibold mb-[2vw]">{`${getPriceFromItemFunction(focusedData)}₪`}</span>
                    )}
                    {(focusedData as Item).price && (
                        <div className="flex flex-col w-[4vw] justify-center items-center">
                            <AddToWishlistButton item={focusedData as Item} />
                            <span className="text-[1.2vw] text-center">{`הוסף למועדפים`}</span>
                        </div>
                    )}
                </div>
                <div
                    className={`absolute bottom-[6.5vw] ${displaySecondRow && `!bottom-[-4vw]`} transition-all flex flex-col justify-center shrink grow overflow-y-auto overflow-x-auto w-full pl-[2vw]`}
                    ref={ref}
                >
                    {displaySecondRow && (
                        <div className={''}>
                            <ChosenMenuRow
                                onFocus={({ y }) => onRowFocus({ y }, true)}
                                focusKey="CHOSEN_FOOD_MENU_SECOND_ROW"
                                setFocusedData={setFocusedData}
                                menuCategories={secondRowItems}
                                reducedSize={false}
                            />
                        </div>
                    )}
                    <div className={``}>
                        <ChosenMenuRow
                            onFocus={({ y }) => onRowFocus({ y }, false)}
                            focusKey="CHOOSENFOODMENUROW"
                            setFocusedData={setFocusedData}
                            menuCategories={mergedSubCatAndItems}
                            displaySecondRowFunction={displaySecondRowFunction}
                            reducedSize={displaySecondRow}
                            selectedFirstRowData={selectedFirstRowData}
                        />
                    </div>
                </div>
            </div>
        );
    };
    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={`h-full w-full`}>
                {chosenMenuData && renderItemsOrSubMenu()}
            </div>
        </FocusContext.Provider>
    );
};

export default ChosenMenuScreen;
