type PersonalAssistantLogoProps = {
    className?: string;
};
const PersonalAssistantLogo = ({ className }: PersonalAssistantLogoProps) => {
    return (
        <img
            className={`absolute h-[3.5vw] w-[3.5vw] top-[-2vw] right-[-3.5vw] ${className}`}
            src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/PizzahutPersonalAssistantLogo.svg"
        />
    );
};

export default PersonalAssistantLogo;
