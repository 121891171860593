import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import { MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';
import { encodeSlash } from '../../../helpers/url';
import { useEffect, useState } from 'react';

type MenuCardProps = {
    index: number;
    category: MenuCategory;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function MenuCard({
    index,
    category,
    onFocus,
    focusKey: focusKeyParam,
    setCurrentFocusIndex,
}: MenuCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const handleMenuItemClick = () => {
        navigate(`/menu/${encodeSlash(category.name)}`);
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
        onEnterPress: handleMenuItemClick,
    });
    useEffect(() => {
        if (focused) {
            setCurrentFocusIndex(index);
        }
    }, [focused]);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMenuItemClick()}
            ref={ref}
            className={`w-[14.2vw] h-[8vw] mx-[1vw] cursor-pointer bg-white backdrop-blur-lg bg-opacity-20 rounded-categoryRadius flex grow-0 shrink-0 flex-row-reverse justify-evenly items-center ${
                (focused || isHovered) && `border-[0.15vw] border-white`
            }`}
        >
            <span
                className={`z-10 font-semibold flex items-center text-center justify-center text-[1.3vw]`}
            >
                {category.name}
            </span>
        </div>
    );
}
