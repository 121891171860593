import {
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ISauceAsset } from '../../constants/dummy';
import SaladSauceIncrementButton from './SaladSauceIncrementButton';
import { Sauce } from '../../redux/slices/dbFoodMenuSlice';

type SaladSauceProps = {
    sauce: Sauce;
    saucesArray: Sauce[];
    sauceIndex: number;
    totalSaucesQuantity: number;
    changeSauceQuantity: (sauceName: string, sauceQuantity: number) => void;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};
const SaladSauce = ({
    sauce,
    saucesArray,
    sauceIndex,
    totalSaucesQuantity,
    changeSauceQuantity,
    onFocus,
}: SaladSauceProps) => {
    const handleSauceClick = () => {
        const newFocus = `${sauce.name}plus`;
        setFocus(newFocus);
    };

    const { ref, focused, hasFocusedChild } = useFocusable({
        onFocus,
        onEnterPress: handleSauceClick,
        trackChildren: true,
    });

    const calculateSaucePrice = () => {
        if (!sauce.sauceQuantity) {
            return 0;
        } else {
            return sauce.price * sauce.sauceQuantity;
        }
    };

    console.log(saucesArray, 'This is the sauces Array');

    return (
        <div
            ref={ref}
            className={`bg-black bg-opacity-20 px-[0.6vw] m-[1vw] h-[17vw] w-[12.5vw] pb-[1vw] flex flex-col justify-between items-center rounded-categoryRadius 
            ${focused && 'bg-gray-card bg-opacity-60 scale-105'}`}
        >
            <div className="flex flex-col justify-center items-center">
                <img
                    className="w-[6vw] h-[6vw] object-cover"
                    src={`${sauce.icon ? sauce.icon : 'https://static.vecteezy.com/system/resources/previews/024/683/268/original/3d-icon-carrot-vegetable-illustration-concept-icon-render-free-png.png'}`}
                    alt=""
                />

                <span
                    className={`${sauce.name.length > 20 ? 'text-[1.3vw]' : 'text-[1.6vw]'} text-center`}
                >{`${sauce.name}`}</span>
                <span className="text-[1.4vw] text-center">{`₪${calculateSaucePrice()}`}</span>
            </div>
            <div className="w-[90%] flex flex-row-reverse justify-evenly items-center">
                <SaladSauceIncrementButton
                    sauce={sauce}
                    sauceOperation={'increment'}
                    focusKey={`${sauce.name}plus`}
                    saucesArray={saucesArray}
                    changeSauceQuantity={changeSauceQuantity}
                    onFocus={onFocus}
                />
                <span className="text-[1.6vw] text-center">{`${sauce.sauceQuantity !== undefined ? sauce.sauceQuantity : 0}`}</span>
                <SaladSauceIncrementButton
                    sauce={sauce}
                    sauceOperation={'decrement'}
                    focusKey={`${sauce.name}minus`}
                    saucesArray={saucesArray}
                    changeSauceQuantity={changeSauceQuantity}
                    onFocus={onFocus}
                />
            </div>
        </div>
    );
};

export default SaladSauce;
