import { useEffect, useState } from 'react';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useSelector } from '../../redux/store';
import { handleFetchMobilePaymentLink } from './PaymentScreenFunctions';
import QRCode from 'react-qr-code';
import { Loading } from '../../components/Loading';

const ScanToPayQrComponent = () => {
    const [GoogleApplePayLink, setGoogleApplePayLink] = useState<string>();
    const PayWithTranslateFunction = createTranslateFunction('choose_payment.qr_code');
    const OrderId = useSelector((state) => state.deliveryFormData.lastOrder._id);

    const handleGetMobilePaymentLink = async () => {
        if (OrderId) {
            setGoogleApplePayLink(await handleFetchMobilePaymentLink(OrderId));
        }
    };

    useEffect(() => {
        handleGetMobilePaymentLink();
    }, [OrderId]);

    return (
        <div className="flex flex-col items-center h-[60%] pt-[3vw]">
            <div className="flex mb-[3vw]">
                <span className={`text-[2vw]`}>{`${PayWithTranslateFunction(`title`)}`}</span>
            </div>
            <div className="mb-[1.5vw]">
                <img
                    className="w-[20vw] h-[10vw]"
                    src={
                        'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/google_apple_payment.svg'
                    }
                    alt=""
                />
            </div>
            <div>
                {GoogleApplePayLink ? (
                    <QRCode className="w-[14vw] h-[14vw]" value={`${GoogleApplePayLink}`}></QRCode>
                ) : (
                    <div className="w-[14vw] h-[14vw]">
                        <Loading className="!bg-transparent" />
                    </div>
                )}
            </div>
            {/*<button onClick={handleGetOrderStatus}>CHECK ORDER STATUS!</button>*/}
            {/*<button onClick={handleSetOrderStatus}>SET ORDER STATUS!</button>*/}
        </div>
    );
};

export default ScanToPayQrComponent;
