import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../lib/axios';
import { AxiosResponse } from 'axios';
import { getAccessToken } from '../../lib/auth';
import { Item } from './dbFoodMenuSlice';

export type InititalState = {
    loading: boolean;
    error: any;
    pastQueries: any[];
    currentQuery: CurrentQuery;
};

interface MelingoExtra {
    makat: number;
    name: string;
    extraSize: string; // e.g., 'half', 'half_right'
}

interface MelingoItem {
    makat: number;
    name: string;
    Category?: string;
    type: string; // e.g., 'פיצה'
    size: string; // e.g., 'L'
    extras: MelingoExtra[];
    sauces: any[]; // Assuming sauces can be an empty array or have some objects in the future
}

interface MelingoReply {
    items: Item[];
}

interface CurrentQuery {
    reply: MelingoReply;
    query: string;
}

type newCurrentQueryType = {
    reply: MelingoReply;
};

const initialState: InititalState = {
    loading: false,
    error: null,
    pastQueries: [],
    currentQuery: { reply: { items: [] }, query: '' },
};

export const textToSpeechMelingoQuery = createAsyncThunk<AxiosResponse, string>(
    'textToSpeech/textToSpeechMelingoQuery',
    async (textToSpeechString) => {
        return await api.post(
            '/melingo/query',
            {
                message: textToSpeechString,
            },
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            },
        );
    },
);

const textToSpeechSlice = createSlice({
    name: 'textToSpeech',
    initialState,
    reducers: {
        updateNewCurrentQuery: (state, action: PayloadAction<newCurrentQueryType>) => {
            const newCurrentQuery = action.payload;
            state.currentQuery.reply = newCurrentQuery.reply;
            state.currentQuery.query = newCurrentQuery.reply.items[0].name;
        },
        resetCurrentQuery: (state) => {
            state.currentQuery = { reply: { items: [] }, query: '' };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(textToSpeechMelingoQuery.pending, (state) => {
                state.loading = true;
            })
            .addCase(textToSpeechMelingoQuery.fulfilled, (state, action) => {
                state.loading = false;
                state.currentQuery = action.payload.data.data;
                state.pastQueries = [...state.pastQueries, action.payload.data.data];
                state.error = null;
            })
            .addCase(textToSpeechMelingoQuery.rejected, (state, action) => {
                state.loading = false;
                state.error =
                    action.error.message || 'Text To Speech Query from melingo have failed';
            });
    },
});

export const { updateNewCurrentQuery, resetCurrentQuery } = textToSpeechSlice.actions;
export default textToSpeechSlice.reducer;
