import TransparentButton from './Buttons/TransparentButton';
import { IconBasket } from '@tabler/icons-react';
import DateAndTime from './DateAndTime';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../redux/store';
import { useTranslation } from 'react-i18next';
import TrackLastOrderButton from './TrackLastOrderButton';

export const Header = () => {
    const getLastOrderFromLocalStorage = () => {
        const lastOrderDetails = localStorage.getItem('TrackLastOrder');
        if (lastOrderDetails) {
            return JSON.parse(lastOrderDetails);
        } else {
            return null;
        }
    };
    const navigate = useNavigate();
    const { t: GeneralTranslationFunction } = useTranslation();
    const { cart } = useSelector((state) => state.cart);
    type trackLastOrderObject = {
        orderId: string;
        orderStatus: string;
    };
    const [trackLastOrder, setTrackLastOrder] = useState<trackLastOrderObject | null>(
        getLastOrderFromLocalStorage(),
    );

    return (
        <div className={'w-full flex items-center'}>
            <TransparentButton
                className="px-[1.5vw] mt-[2vw] ml-[1vw] w-[13vw] h-[4vw] flex justify-evenly items-center bg-opacity-10 "
                onClick={() => navigate('/checkout')}
            >
                <IconBasket size={'2vw'} />
                <div className="w-[60%] flex justify-between">
                    <span className="text-[1.5vw] ml-[0.7vw]">{`${GeneralTranslationFunction(`buttons.cart`)}`}</span>
                    <span className="text-[1.5vw]">{cart.length}</span>
                </div>
            </TransparentButton>
            {trackLastOrder && (
                <TrackLastOrderButton
                    trackLastOrder={trackLastOrder}
                    focusKey="track_last_order_button"
                />
            )}
            <TransparentButton
                className="w-[13vw] h-[4vw] flex justify-center items-center !text-[1.5vw] mt-[2vw] mr-[1vw]"
                onClick={() => navigate('/personal-assistant')}
            >
                {`העוזר האישי`}
            </TransparentButton>
            <DateAndTime />
        </div>
    );
};
