import api from '../../lib/axios';
import { getAccessToken } from '../../lib/auth';
import { Extra, Item, MenuCategory } from '../../redux/slices/dbFoodMenuSlice';
import { CartItem } from '../../redux/slices/cartSlice';
import { MelingoResponse } from './AssistantSteps/useMelingoQuery';
import { UpdateChosenSubCategory } from '../../redux/slices/personalAssistantSlice';
import { useDispatch } from 'react-redux';

interface MelingoExtra {
    makat: number;
    name: string;
    extraSize: string; // e.g., 'half', 'half_right'
}

interface MelingoItem {
    makat: number;
    name: string;
    type: string; // e.g., 'פיצה'
    size: string; // e.g., 'L'
    extras: MelingoExtra[];
    sauces: any[]; // Assuming sauces can be an empty array or have some objects in the future
}

interface MelingoReply {
    items: MelingoItem[];
}

interface CurrentQuery {
    reply: MelingoReply;
    query: string;
}

export const doesNameIncludePizza = (name: string) => {
    return name.includes('פיצות') || name.includes('פיצה');
};

export const handleMelingoRequest = async (
    textToSpeechString: string,
): Promise<CurrentQuery | undefined> => {
    try {
        const melingoResponse = await api.post(
            '/melingo/query',
            {
                message: textToSpeechString,
            },
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            },
        );
        return melingoResponse.data;
    } catch (error) {
        console.log(error);
    }
};

export const convertToDisplayableItem = (chosenItem: Item, chosenExtras: Extra[]) => {
    console.log(chosenItem, chosenExtras, 'This is the chosenItem and chosenExtras');
    const itemToDisplay: CartItem = {
        item: chosenItem,
        extras: chosenExtras ? chosenExtras : [],
        sauces: [],
    };
    return itemToDisplay;
};

export const removeEnglishLettersFromString = (str: string): string => {
    if (!str) {
        return '';
    }
    const formattedStr = str.replace(/[a-zA-Z]/g, '');
    const formattedNoStartingName = formattedStr.replace('ת. סלט', '');
    return formattedNoStartingName.trimEnd();
};

export const convertExtrasToString = (extras: Extra[]) => {
    let extrasString = '';
    if (extras.length === 0) {
        return '';
    }
    for (const extra of extras) {
        extrasString += removeEnglishLettersFromString(extra.name) + ', ';
    }
    return extrasString.slice(0, -2);
};

export const findWhatIsTheCategory = (menuCategories: MenuCategory[], categoryType: string) => {
    console.log(menuCategories, categoryType, 'These are the values in findWhatIsTheCategory');

    return menuCategories.find((category) => category.name.includes(categoryType));
};

export const handleReceivedSubCategoryDataFromMelingo = (
    menuCategories: MenuCategory[],
    melingoData: MelingoResponse,
) => {
    if (
        !melingoData ||
        !melingoData.data ||
        !melingoData.data.reply ||
        !melingoData.data.reply.items
    ) {
        console.log('No data received from Melingo');
        return;
    }
    const melingoAnswerType = melingoData.data.reply.items[0].Category;
    const whatIsTheCategory = findWhatIsTheCategory(menuCategories, melingoAnswerType);

    if (whatIsTheCategory) {
        return whatIsTheCategory;
    } else {
        console.log('Could not find any matching category');
    }
};

const checkIfCategoryIsPizza = (categoryName: string): boolean => {
    return categoryName.includes('פיצות');
};

const checkIfCategoryIsDrinks = (categoryName: string): boolean => {
    return categoryName.includes('שתייה');
};

export const shouldRenderChoosePizzaSubCategory = (chosenCategory: MenuCategory | null) => {
    if (chosenCategory && checkIfCategoryIsPizza(chosenCategory.name)) {
        return true;
    } else {
        return false;
    }
};

export const shouldRenderChooseSubCategory = (chosenCategory: MenuCategory | null) => {
    if (chosenCategory && checkIfCategoryIsDrinks(chosenCategory.name)) {
        return true;
    } else {
        return false;
    }
};

export const shouldRenderChooseItem = (itemsToDisplay: Item[]) => {
    return itemsToDisplay.length > 0 ? true : false;
};
export const shouldRenderChooseExtras = (chosenItem: Item | null) => {
    if (chosenItem && chosenItem.extras && chosenItem.extras.length > 0) {
        return true;
    } else {
        return false;
    }
};

export const shouldHavePaddingOnBottom = (
    assistantStep: string,
    chosenCategory: MenuCategory | null,
    chosenSubCategory: MenuCategory | null,
) => {
    if (
        (assistantStep === 'chooseCategory' ||
            assistantStep === 'chooseSubCategory' ||
            assistantStep === 'chooseItem') &&
        chosenCategory?.name !== 'מנות נלוות' &&
        chosenCategory?.name !== 'קינוחים' &&
        chosenCategory?.name !== 'רטבים' &&
        chosenCategory?.name !== 'שתייה' &&
        chosenSubCategory?.name !== 'פיצות אהובות' &&
        chosenSubCategory?.name !== 'פיצות מיוחדות'
    ) {
        return true;
    } else {
        return false;
    }
};

export function debounce(func, delay) {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args); // Use the spread operator here instead of .apply()
        }, delay);
    };
}
