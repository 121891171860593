import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from './Buttons/TransparentButton';
import createTranslateFunction from '../i18n/createTranslateFunction';
import { useNavigate } from 'react-router-dom';

type TrackLastOrderButtonProps = {
    focusKey: string;
    trackLastOrder: {
        orderId: string;
        orderStatus: string;
    };
};
const TrackLastOrderButton = ({
    focusKey: focusKeyParam,
    trackLastOrder,
}: TrackLastOrderButtonProps) => {
    const navigate = useNavigate();
    const TrackLastOrderTranslateFunction = createTranslateFunction('buttons');
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <TransparentButton
                    className="mt-[2vw] w-[13vw] h-[4vw] text-[1.5vw]"
                    onClick={() => navigate(`/track-order/${trackLastOrder.orderId}`)}
                >
                    {TrackLastOrderTranslateFunction('last_order')}
                </TransparentButton>
            </div>
        </FocusContext.Provider>
    );
};

export default TrackLastOrderButton;
