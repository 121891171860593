import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../lib/axios';
import { Extra, Item, MenuCategory, Sauce } from './dbFoodMenuSlice';
import { AxiosResponse } from 'axios';
import { getAccessToken } from '../../lib/auth';
import { CartItem } from './cartSlice';

export type personalAssistantSteps =
    | 'chooseCategory'
    | 'chooseSubCategory'
    | 'choosePizzaSubCategory'
    | 'chooseItem'
    | 'chooseExtras'
    | 'chooseSauces'
    | 'chooseToKeepItem';
export type InititalState = {
    isMelingoQueryLoading: boolean;
    error: any;
    assistantStep: personalAssistantSteps;
    chosenCategory: MenuCategory | null;
    chosenSubCategory: MenuCategory | null;
    itemsToDisplay: Item[];
    chosenItem: Item | null;
    chosenExtras: Extra[] | [];
    chosenSauces: Sauce[] | [];
    chooseToKeepItem: CartItem | null;
    proccessFinished: boolean;
    chosenCategoryQuery: string | null;
    chosenSubCategoryQuery: string | null;
    chosenItemQuery: string | null;
    chosenExtrasQuery: string | null;
    chooseToKeepItemQuery: string | null;
};

const initialState: InititalState = {
    isMelingoQueryLoading: false,
    error: null,
    assistantStep: 'chooseCategory',
    chosenCategory: null,
    chosenSubCategory: null,
    itemsToDisplay: [],
    chosenItem: null,
    chosenExtras: [],
    chosenSauces: [],
    chooseToKeepItem: null,
    proccessFinished: false,
    chosenCategoryQuery: null,
    chosenSubCategoryQuery: null,
    chosenItemQuery: null,
    chosenExtrasQuery: null,
    chooseToKeepItemQuery: null,
};

const convertItemToDisplay = () => {
    //Takes chosenItem and chosenExtras, converts them to itemToDisplay as a cartItem.
};

export const textToSpeechMelingoQuery = createAsyncThunk<AxiosResponse, string>(
    'textToSpeech/textToSpeechMelingoQuery',
    async (textToSpeechString) => {
        return await api.post(
            '/melingo/query',
            {
                message: textToSpeechString,
            },
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            },
        );
    },
);
// Create slice
const personalAssistantSlice = createSlice({
    name: 'personalAssistant',
    initialState,
    reducers: {
        ToggleMelingoLoadingTrue: (state) => {
            state.isMelingoQueryLoading = true;
        },
        ToggleMelingoLoadingFalse: (state) => {
            state.isMelingoQueryLoading = false;
        },
        UpdateChosenCategory: (state, action: PayloadAction<MenuCategory>) => {
            const payloadCategory = action.payload;
            state.chosenCategory = payloadCategory;
            if (payloadCategory.name.includes('פיצות')) {
                console.log('Got into the if');
                state.assistantStep = 'choosePizzaSubCategory';
                state.itemsToDisplay = [];
                return;
            }
            if (payloadCategory.name.includes('שתייה')) {
                state.assistantStep = 'chooseSubCategory';
                state.itemsToDisplay = [];
                return;
            }
            state.assistantStep = 'chooseItem';
            const payloadCategoryItems = payloadCategory.items;
            const payloadSubCategoryItems = payloadCategory.sub_categories
                .map((subCategory) => subCategory.items)
                .flat();
            state.itemsToDisplay = [...payloadSubCategoryItems, ...payloadCategoryItems];
        },
        // UpdateChosenCategory: (state, action: PayloadAction<MenuCategory>) => {
        //     const payloadCategory = action.payload;
        //     state.chosenCategory = payloadCategory;
        //     if (payloadCategory.name.includes('פיצות')) {
        //         state.assistantStep = 'chooseSubCategory';
        //         state.itemsToDisplay = [];
        //         return;
        //     }
        //     state.assistantStep = 'chooseItem';
        //     const payloadCategoryItems = payloadCategory.items;
        //     const payloadSubCategoryItems = payloadCategory.sub_categories
        //         .map((subCategory) => subCategory.items)
        //         .flat();
        //     state.itemsToDisplay = [...payloadSubCategoryItems, ...payloadCategoryItems];
        // },
        ResetChosenCategory: (state) => {
            state.chosenCategory = null;
            state.chosenCategoryQuery = null;
            state.chosenSubCategory = null;
            state.assistantStep = 'chooseCategory';
        },
        UpdateChosenSubCategory: (state, action: PayloadAction<MenuCategory>) => {
            const payloadSubCategory = action.payload;
            state.chosenSubCategory = payloadSubCategory;
            state.itemsToDisplay = payloadSubCategory.items;
            state.assistantStep = 'chooseItem';
        },
        UpdateChosenItem: (state, action: PayloadAction<Item>) => {
            const chosenItem = action.payload;
            state.chosenItem = chosenItem;
            state.chooseToKeepItem = { item: chosenItem, extras: [], sauces: [] };
            if (chosenItem.extras.length < 1) {
                state.assistantStep = 'chooseToKeepItem';
            } else {
                state.assistantStep = 'chooseExtras';
            }
        },
        ResetChosenSubCategory: (state) => {
            state.chosenSubCategory = null;
            state.chosenSubCategoryQuery = null;
            state.assistantStep = 'chooseCategory';
        },
        ResetChosenItem: (state) => {
            state.chosenItem = null;
            state.chosenSubCategoryQuery = null;
            state.itemsToDisplay = [];
            state.proccessFinished = false;
        },
        ResetChosenExtras: (state) => {
            state.chosenExtras = [];
            state.chosenExtrasQuery = null;
            state.chosenItemQuery = null;
            state.chosenItem = null;
            state.assistantStep = 'chooseItem';
            state.proccessFinished = false;
        },
        ResetChosenItemToKeep: (state) => {
            state.chosenExtrasQuery = null;
            state.chosenExtras = [];
            state.assistantStep = 'chooseExtras';
            state.proccessFinished = false;
        },
        ToggleProccessFinishedTrue: (state) => {
            state.proccessFinished = true;
        },
        AddPizzaExtraToChosenExtras: (state, action: PayloadAction<Extra>) => {
            const payloadExtra = action.payload;
            const existingIndex = state.chosenExtras?.findIndex(
                (extra) => extra._id === payloadExtra._id,
            );
            if (existingIndex === -1 || existingIndex === undefined) {
                state.chosenExtras = [...state.chosenExtras, payloadExtra];
            } else if (state.chosenExtras[existingIndex].extraSize !== payloadExtra.extraSize) {
                state.chosenExtras[existingIndex] = payloadExtra;
            } else {
                state.chosenExtras = state.chosenExtras.filter(
                    (extra) => extra._id !== payloadExtra._id,
                );
            }
        },
        RemovePizzaExtraFromChosenExtras: (state, action: PayloadAction<Extra>) => {
            const payloadExtra = action.payload;
            state.chosenExtras = state.chosenExtras.filter(
                (extra) => extra._id !== payloadExtra._id,
            );
        },
        AddExtraToChosenExtras: (state, action: PayloadAction<Extra>) => {
            const payloadExtra = action.payload;
            const existingIndex = state.chosenExtras?.findIndex(
                (extra) => extra._id === payloadExtra._id,
            );
            if (existingIndex === -1 || existingIndex === undefined) {
                state.chosenExtras = [...state.chosenExtras, payloadExtra];
            } else {
                state.chosenExtras = state.chosenExtras.filter(
                    (extra) => extra._id !== payloadExtra._id,
                );
            }
        },
        RemoveExtraFromChosenExtras: (state, action: PayloadAction<Extra>) => {
            const payloadExtra = action.payload;
            state.chosenExtras = state.chosenExtras.filter(
                (extra) => extra._id !== payloadExtra._id,
            );
        },
        ChangeAssistantStep: (state, action: PayloadAction<personalAssistantSteps>) => {
            state.assistantStep = action.payload;
        },
        UpdateChooseToKeepItem: (state, action: PayloadAction<CartItem>) => {
            state.chooseToKeepItem = action.payload;
            state.assistantStep = 'chooseToKeepItem';
        },
        UpdateChooseCategoryQuery: (state, action: PayloadAction<string>) => {
            state.chosenCategoryQuery = action.payload;
        },
        UpdateChooseSubCategoryQuery: (state, action: PayloadAction<string>) => {
            state.chosenSubCategoryQuery = action.payload;
        },
        UpdateChooseItemQuery: (state, action: PayloadAction<string>) => {
            state.chosenItemQuery = action.payload;
        },
        UpdateChooseExtrasQuery: (state, action: PayloadAction<string>) => {
            state.chosenExtrasQuery = action.payload;
        },
        UpdateChooseToKeepItemQuery: (state, action: PayloadAction<string>) => {
            state.chooseToKeepItemQuery = action.payload;
        },
        ResetAssistantPage: (state) => {
            (state.assistantStep = 'chooseCategory'),
                (state.chosenCategory = null),
                (state.chosenSubCategory = null),
                (state.itemsToDisplay = []),
                (state.chosenItem = null),
                (state.chosenExtras = []),
                (state.chosenSauces = []),
                (state.chooseToKeepItem = null),
                (state.chosenCategoryQuery = null),
                (state.chosenSubCategoryQuery = null),
                (state.chosenItemQuery = null),
                (state.chosenExtrasQuery = null),
                (state.chooseToKeepItemQuery = null);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(textToSpeechMelingoQuery.pending, (state) => {
                state.isMelingoQueryLoading = true;
            })
            .addCase(textToSpeechMelingoQuery.fulfilled, (state, action) => {
                state.isMelingoQueryLoading = false;
                state.error = null;
            })
            .addCase(textToSpeechMelingoQuery.rejected, (state, action) => {
                state.isMelingoQueryLoading = false;
                state.error =
                    action.error.message || 'Text To Speech Query from melingo have failed';
            });
    },
});

export const {
    ToggleMelingoLoadingTrue,
    ToggleMelingoLoadingFalse,
    UpdateChosenCategory,
    ResetChosenCategory,
    UpdateChosenSubCategory,
    UpdateChosenItem,
    ResetChosenSubCategory,
    ResetChosenItem,
    ResetChosenExtras,
    ResetChosenItemToKeep,
    AddPizzaExtraToChosenExtras,
    RemovePizzaExtraFromChosenExtras,
    AddExtraToChosenExtras,
    RemoveExtraFromChosenExtras,
    ToggleProccessFinishedTrue,
    ChangeAssistantStep,
    UpdateChooseToKeepItem,
    UpdateChooseCategoryQuery,
    UpdateChooseExtrasQuery,
    UpdateChooseItemQuery,
    UpdateChooseSubCategoryQuery,
    UpdateChooseToKeepItemQuery,
    ResetAssistantPage,
} = personalAssistantSlice.actions;
export default personalAssistantSlice.reducer;
