import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { IconArrowBackUp } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    focusKey?: string;
    className?: string;
    onClick?: () => void;
    onFocusClass?: string;
    focusClassName?: string;
    onBtnFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};
const BackButton = ({
    focusKey: focusKeyParam,
    className,
    focusClassName,
    onBtnFocus,
    onClick,
}: ButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        if (onClick !== undefined) {
            onClick();
            return;
        }
        navigate(-1);
    };
    const { ref, focused } = useFocusable({
        onFocus: onBtnFocus,
        onEnterPress: handleBackClick,
        focusKey: focusKeyParam,
    });
    const { t: GeneralTranslationFunction } = useTranslation();

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleBackClick}
            ref={ref}
            className={`bg-white transition px-[1vw] text-[1.4vw] text-white rounded-full bg-opacity-20 w-[8vw] h-[3.2vw] flex justify-center items-center ${className}
       ${(focused || isHovered) && `bg-opacity-60 scale-110 ${focusClassName}`}`}
        >
            <IconArrowBackUp size={`1.5vw`} className="-scale-x-100 ml-[0.5vw]" />
            {`${GeneralTranslationFunction(`buttons.back`)}`}
        </button>
    );
};

export default BackButton;
