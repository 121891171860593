import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../lib/axios';

export type ExtraSizesType = 'full' | 'halfleft' | 'halfright';

export interface Extra {
    _id: string;
    makat: number;
    name: string;
    name_en?: string;
    price: number;
    extraSize: string | undefined;
}
export interface Sauce {
    _id: string;
    makat: number;
    name: string;
    name_en?: string;
    price: number;
    category: string;
    icon?: string;
    sauceQuantity: number;
}

export interface Item {
    _id: string;
    makat: number;
    name: string;
    name_en: string;
    price: number;
    extras: Extra[];
    description?: string;
    icon?: string;
    category: MenuCategory;
    main_category: MenuCategory;
    sauces: Sauce[];
    bg_src?: string;
}

export interface MenuCategory {
    _id: string;
    name: string;
    name_en: string;
    sub_categories: MenuCategory[]; // You may need to define a proper type for this
    items: Item[];
    icon: string;
    description?: string;
    is_main_category: boolean;
    bg_src?: string;
}

type InitialState = {
    categories: MenuCategory[];
    all_items: Item[];
};

// Define initial state
const initialState: InitialState = {
    categories: [],
    all_items: [],
};

type MenuResponse = MenuCategory[];

const customMenuOrder: string[] = [
    'פיצות',
    'מנות נלוות',
    'סלטים',
    'פסטות / קישים',
    'קינוחים',
    'שתייה',
    'רטבים',
];

export const sortByCustomOrder = (categories: MenuCategory[], order: string[]) => {
    const orderMap = new Map(order.map((item, index) => [item, index]));

    return categories.sort((a, b) => {
        const indexA = orderMap.get(a.name) ?? order.length;
        const indexB = orderMap.get(b.name) ?? order.length;
        return indexA - indexB;
    });
};

interface SortingRule {
    categoryName: string;
    order: string[];
}

const sortingRules: SortingRule[] = [
    {
        categoryName: 'קינוחים',
        order: [
            'עוגיות The ShowRoom',
            'מקלות שוקולד',
            'זיווה שוקולד',
            'בייגל שוקולד',
            'גלידה אישית',
            'גלידה משפחתית',
            'וופל בלגי',
            'שלישיית מיסטר דונאטס',
        ],
    },
    {
        categoryName: 'פסטות / קישים',
        order: [
            'פסטה פנה ברוטב עגבניות',
            'פסטה פנה ברוטב רוזה',
            'פסטה פנה ברוטב שמנת פטריות',
            'פסטה פנה ברוטב שמנת',
            'קיש פטריות אישי',
            'קיש בטטה אישי',
        ],
    },
    {
        categoryName: 'מנות נלוות',
        order: [
            'לחם שום',
            'לחם שום פטה',
            'מקלות שום מוצרלה',
            'זיווה',
            'בייגל מוצרלה',
            'מלוואח פיצה',
            'ציפס אישי',
            'טבעות בצל אפויות',
        ],
    },
    {
        categoryName: 'רטבים',
        order: [
            'רוטב פיצה',
            'רוטב שום',
            'רוטב אלף האיים',
            'סירופ מייפל',
            'סירופ שוקולד',
            'רוטב שמן זית מיץ לימון',
            'רוטב חרדל סילאן',
            'בקבוק טבסקו',
            'בקבוק טבסקו מיני',
        ],
    },
    {
        categoryName: 'שתייה גדולה',
        order: [
            'קולה זירו 1.5',
            'קוקה קולה 1.5',
            'דיאט קולה 1.5',
            'פיוזטי 1.5',
            'ספרייט זירו 1.5',
            'ספרייט 1.5',
            'פאנטה 1.5',
            'פריגת ענבים 1.5',
            'פריגת תפוזים 1.5',
            'מים מנרלים 1.5',
        ],
    },
    {
        categoryName: 'שתייה אישית',
        order: [
            'קולה זירו פחית',
            'קוקה קולה פחית',
            'קולה דיאט פחית',
            'פיוזטי קטן',
            'ספרייט זירו פחית',
            'ספרייט פחית',
            'פאנטה פחית',
            'פריגת ענבים קטן',
            'פריגת תפוזים קטן',
            'מים מינרלים',
        ],
    },
];

const sortSpecialPizzaItems = (specialPizzaCategory: MenuCategory) => {
    const specialPizzaOrder = [
        `צ'יזי בייטס`,
        `צ'יזי קראסט`,
        `פיצה חלומי משפחתית עבה`,
        `פיצה חלומי משפחתית דקה`,
        `קראון פיצה`,
        `פיצה לבנה`,
        `פיצה L ללא גלוטן`,
    ];

    if (specialPizzaCategory.name === 'פיצות מיוחדות') {
        specialPizzaCategory.items.sort((a, b) => {
            const indexA = specialPizzaOrder.indexOf(a.name);
            const indexB = specialPizzaOrder.indexOf(b.name);

            return (
                (indexA === -1 ? specialPizzaOrder.length : indexA) -
                (indexB === -1 ? specialPizzaOrder.length : indexB)
            );
        });
    }
};

const sortPanPizzaItems = (panPizzaCategory: MenuCategory) => {
    const panPizzaOrder = ['PAN פיצה S', 'PAN פיצה L', 'PAN פיצה XL'];

    if (panPizzaCategory.name === 'PAN פיצה') {
        panPizzaCategory.items.sort((a, b) => {
            const indexA = panPizzaOrder.indexOf(a.name);
            const indexB = panPizzaOrder.indexOf(b.name);

            return (
                (indexA === -1 ? panPizzaOrder.length : indexA) -
                (indexB === -1 ? panPizzaOrder.length : indexB)
            );
        });
    }
};
const sortLargeDrinks = (drinkCategory: MenuCategory) => {
    const largeDrinksOrder = [
        'קולה זירו 1.5',
        'קוקה קולה 1.5',
        'דיאט קולה 1.5',
        'פיוזטי 1.5',
        'ספרייט זירו 1.5',
        'ספרייט 1.5',
        'פאנטה 1.5',
        'פריגת ענבים 1.5',
        'פריגת תפוזים 1.5',
        'מים מנרלים 1.5',
    ];

    if (drinkCategory.name === 'שתייה גדולה') {
        drinkCategory.items.sort((a, b) => {
            const indexA = largeDrinksOrder.indexOf(a.name);
            const indexB = largeDrinksOrder.indexOf(b.name);

            return (
                (indexA === -1 ? largeDrinksOrder.length : indexA) -
                (indexB === -1 ? largeDrinksOrder.length : indexB)
            );
        });
    }
};

const sortSmallDrinks = (drinkCategory: MenuCategory) => {
    const smallDrinksOrder = [
        'קולה זירו פחית',
        'קוקה קולה פחית',
        'קולה דיאט פחית',
        'פיוזטי קטן',
        'ספרייט זירו פחית',
        'ספרייט פחית',
        'פאנטה פחית',
        'פריגת ענבים קטן',
        'פריגת תפוזים קטן',
        'מים מינרלים',
    ];

    if (drinkCategory.name === 'שתייה אישית') {
        drinkCategory.items.sort((a, b) => {
            const indexA = smallDrinksOrder.indexOf(a.name);
            const indexB = smallDrinksOrder.indexOf(b.name);

            return (
                (indexA === -1 ? smallDrinksOrder.length : indexA) -
                (indexB === -1 ? smallDrinksOrder.length : indexB)
            );
        });
    }
};

export const sortItemsInCategory = (category: MenuCategory, rules: SortingRule[]) => {
    const rule = rules.find((r) => r.categoryName === category.name);

    if (rule) {
        const orderMap = new Map(rule.order.map((item, index) => [item, index]));

        category.items.sort((a, b) => {
            const indexA = orderMap.get(a.name) ?? rule.order.length;
            const indexB = orderMap.get(b.name) ?? rule.order.length;
            return indexA - indexB;
        });
    }
};

export const fetchDBMenuData = createAsyncThunk<MenuResponse, void>(
    'menu/fetchMenuData',
    async () => {
        const { data } = await api.get('menu');

        console.log(data, 'This is the fulll db data!');

        const sortedMenuByCategories = sortByCustomOrder(data, customMenuOrder);

        sortedMenuByCategories.forEach((category) => sortItemsInCategory(category, sortingRules));
        sortPanPizzaItems(sortedMenuByCategories[0].sub_categories[0]);
        sortSpecialPizzaItems(sortedMenuByCategories[0].sub_categories[1]);
        sortLargeDrinks(sortedMenuByCategories[5].sub_categories[0]);
        sortSmallDrinks(sortedMenuByCategories[5].sub_categories[1]);

        return sortedMenuByCategories;
    },
);

export const fetchAllMenuItems = createAsyncThunk<Item[], void>(
    'menu/fetchAllMenuItems',
    async () => {
        const { data } = await api.get('menu/all_items');

        return data;
    },
);

// Create slice
const dbFoodMenuSlice = createSlice({
    name: 'dbFoodMenu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDBMenuData.fulfilled, (state, action) => {
            state.categories = action.payload;
        }),
            builder.addCase(fetchAllMenuItems.fulfilled, (state, action) => {
                state.all_items = action.payload;
            });
    },
});

// Export actions and reducer
// export const dbFoodMenuActions = dbFoodMenuSlice.actions;
export default dbFoodMenuSlice.reducer;
