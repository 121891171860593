import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useLocation, useNavigate } from 'react-router-dom';
import { AtmosDataProperties } from '../../redux/slices/branchesSlice';
import BackButton from '../../components/Buttons/BackButton';
import OpeningHoursTable from './OpeningHoursTable';
import { useDispatch } from 'react-redux';
import { UpdateDeliveryFormData } from '../../redux/slices/deliveryFormSlice';
import { useEffect } from 'react';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import RedButton from '../../components/Buttons/RedButton';

type ChosenBranchScreenProps = {
    focusKey: string;
};

export const ChosenBranchScreen = ({ focusKey: focusKeyParam }: ChosenBranchScreenProps) => {
    const TranslateFunction = createTranslateFunction('chosen_branch_screen');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const chosenBranchData: AtmosDataProperties = state.chosenBranchData;

    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
    });

    const handleOrderFromBranchButton = () => {
        dispatch(
            UpdateDeliveryFormData({
                order_type: 1,
                branch_id: state.branchId,
            }),
        );
        navigate('/checkout');
    };

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-center',
            ]),
        );
    }, []);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="h-full w-full relative flex justify-evenly items-center" ref={ref}>
                <BackButton className="absolute top-[5vw] right-[5vw]" />
                <OpeningHoursTable openingHours={chosenBranchData.working_hours} />
                <div className="flex flex-col justify-center items-center">
                    <span className="text-[3vw]">
                        {TranslateFunction('header', { name: chosenBranchData.name })}
                    </span>
                    {chosenBranchData.kosher && (
                        <img
                            className={`w-[5vw] h-[5vw]`}
                            src="https://order.pizzahut.co.il/assets/img/kosher.svg"
                            alt="Kosher Symbol"
                        />
                    )}
                    <span className="text-[2.4vw] font-semibold mb-[2vw]">
                        {TranslateFunction('phone_number')}
                    </span>
                    <RedButton
                        className="w-[16vw] h-[5vw] !text-[1.8vw]"
                        onFocusClass="!border-white !border-[0.1vw]"
                        onClick={handleOrderFromBranchButton}
                    >
                        {TranslateFunction(`order_from_branch_button`)}
                    </RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};
