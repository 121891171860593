import { useCallback, useState } from 'react';
import {
    useFocusable,
    FocusContext,
    FocusableComponentLayout,
    FocusDetails,
} from '@noriginmedia/norigin-spatial-navigation';
import { ChosenMenuCard } from './ChosenMenuCard';
import { Item, MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';
import IsAbleToScrollIndicator from '../../../components/ScrollingIndicator/IsAbleToScrollIndicator';

type ChosenMenuProps = {
    onFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
    focusKey: string;
    setFocusedData: (categoryOrItem: MenuCategory | Item) => void;
    menuCategories: (MenuCategory | Item)[];
    displaySecondRowFunction?: (categoryData: MenuCategory) => void;
    selectedFirstRowData?: MenuCategory;
    reducedSize: boolean;
};

export const ChosenMenuRow = ({
    onFocus,
    focusKey: focusKeyParam,
    setFocusedData,
    menuCategories,
    displaySecondRowFunction,
    reducedSize,
    selectedFirstRowData,
}: ChosenMenuProps) => {
    const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
    const { ref, focusKey } = useFocusable({
        onFocus,
        focusKey: focusKeyParam,
        preferredChildFocusKey: menuCategories[0]?.name + '0',
    });

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (ref.current) {
                ref.current.scrollLeft = x;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col h-full mb-[1vw]">
                {menuCategories.length > 5 && (
                    <IsAbleToScrollIndicator
                        indexInRow={currentFocusIndex}
                        rowLength={menuCategories.length}
                    />
                )}
                <div ref={ref} className="overflow-x-auto flex">
                    {menuCategories.map((categoryOrItem, index) => (
                        <ChosenMenuCard
                            setFocusedData={setFocusedData}
                            key={categoryOrItem._id}
                            categoryOrItem={categoryOrItem}
                            onFocus={onAssetFocus}
                            focusKey={`${categoryOrItem.name}${index}`}
                            displaySecondRowFunction={displaySecondRowFunction}
                            reducedSize={reducedSize}
                            selectedFirstRowData={selectedFirstRowData}
                            setCurrentFocusIndex={setCurrentFocusIndex}
                            componentIndex={index}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
