import React from 'react';
import createTranslateFunction from '../i18n/createTranslateFunction';

const OrBarComponent = () => {
    const OrBarTranslateFunction = createTranslateFunction('choose_payment.pick_a_card');
    return (
        <div className="flex flex-col items-center justify-evenly h-[50%]">
            <div className="border-full h-[36%] my-[0.5vw] w-[0.2vw] bg-[#ffffff] bg-opacity-25 text-[0.1vw]"></div>
            <span className="text-[1.5vw]">{`${OrBarTranslateFunction(`or`)}`}</span>
            <div className="border-full h-[36%] my-[0.5vw] w-[0.2vw] bg-[#ffffff] bg-opacity-25 text-[0.1vw]"></div>
        </div>
    );
};

export default OrBarComponent;
