import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { Order, changeIsRestored } from '../../../../redux/slices/ordersSlice';
import moment from 'moment';
import 'moment/locale/he';
import { IconArrowBadgeRightFilled, IconChevronLeft, IconRotate } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { CartItem, addToCart, removeFromCartById } from '../../../../redux/slices/cartSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import TransparentButtonWithPopover from '../../../../components/Buttons/TransparentButtonWithPopover';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type PastOrderCardProps = {
    pastOrder: Order;
    focusKey: string;
    onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
};
export function PastOrderCard({
    pastOrder,
    onFocus,
    focusKey: focusKeyParam,
    setCurrentFocusIndex,
    componentIndex,
}: PastOrderCardProps) {
    const pastOrderTranslationFunction = createTranslateFunction('home_screen.past_orders');
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ref, focused, focusKey, focusSelf, hasFocusedChild } = useFocusable({
        onEnterPress: () => {
            focusSelf();
        },
        focusKey: focusKeyParam,
        onFocus,
        trackChildren: true,
    });

    const getDay = (timestamp: Date) => {
        return moment(timestamp).locale('he').format('DD');
    };

    const getMonth = (timestamp: Date) => {
        return moment(timestamp).locale('he').format('MMM');
    };
    const handleRestorePastOrder = () => {
        const state = {
            pastOrder: pastOrder,
        };
        navigate(`/restore-past-order`, { state });
    };

    const calculateTotalCartPrice = (cart: CartItem[]): number => {
        let totalPrice = 0;
        cart.forEach((cartItem) => {
            totalPrice += cartItem.item.price;
            // Add extras price
            cartItem.extras.forEach((extra) => {
                totalPrice += extra.price;
            });
            if (cartItem.sauces) {
                cartItem.sauces.forEach((sauce) => {
                    totalPrice += sauce.price * sauce.sauceQuantity;
                });
            }
            totalPrice = Math.round(totalPrice * 100) / 100;
        });
        return totalPrice;
    };

    const handleRestoreAddToCart = async () => {
        if (!pastOrder.isRestored) {
            for (const currentItem of pastOrder.cart) {
                dispatch(addToCart({ ...currentItem, restoredOrderId: pastOrder._id }));
            }
            dispatch(changeIsRestored({ id: pastOrder._id, isRestored: true }));
            navigate(`/`);
        } else {
            dispatch(removeFromCartById({ id: pastOrder._id }));
            dispatch(changeIsRestored({ id: pastOrder._id, isRestored: false }));
        }
    };

    useEffect(() => {
        if (focused) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ref={ref}
                className={`mr-[2vw] h-[20vw] w-[17vw] cursor-pointer bg-white backdrop-blur-lg bg-opacity-20 px-[1.2vw] py-[1.4vw] rounded-categoryRadius flex flex-col items-center justify-evenly ${
                    (focused || isHovered) && 'border-[0.15vw] border-white'
                } ${hasFocusedChild && `scale-105 border-[0.1vw] border-white`}`}
            >
                <div className="z-[5] w-[14vw] flex justify-between items-center">
                    <div className="bg-black bg-opacity-40 py-[0.8vw] px-[1.4vw] rounded-[2vw] text-center flex flex-col items-center justify-center">
                        <span className="font-semibold text-[1.2vw]">{`${getDay(new Date(pastOrder.created_at))}`}</span>
                        <span className="font-semibold text-[1.2vw]">{`${getMonth(new Date(pastOrder.created_at))}`}</span>
                    </div>
                    <div>
                        <TransparentButtonWithPopover
                            onClick={() => handleRestorePastOrder()}
                            className="h-[3.2vw] w-[3.2vw] flex items-center justify-center"
                            focusClassName="!bg-pizzahut-red shadow-redSmudge"
                            popOverContent={pastOrderTranslationFunction('show_details_popup')}
                        >
                            <IconChevronLeft size={`2.1vw`} />
                        </TransparentButtonWithPopover>
                    </div>
                </div>

                <div className="z-[5] w-[15vw] flex-1 flex items-center justify-start">
                    {pastOrder.cart.slice(0, 3).map((item, index) => (
                        <div
                            key={index}
                            className="text-center flex items-center justify-evenly ml-[0.8vw]"
                        >
                            <img
                                src={`${item.item.icon ? item.item.icon : `/images/pizzas/pan.png`}`}
                                alt=""
                                className="w-[3.9vw] h-[3.9vw] object-cover"
                            />
                        </div>
                    ))}
                    {pastOrder.cart.length > 3 && (
                        <span className="text-[1.6vw] text-center">
                            {pastOrder.cart.length - 3}+
                        </span>
                    )}
                </div>

                <div className="z-[5] h-[20%] w-full flex items-center justify-between">
                    <div className="font-semibold text-center flex flex-col items-center justify-center">
                        <span className="text-[1.6vw] flex items-center justify-center text-unfocused-text">
                            {pastOrderTranslationFunction('price_of_order')}
                        </span>
                        <span className="text-[1.4vw]">{`₪${calculateTotalCartPrice(pastOrder.cart)}`}</span>
                    </div>

                    {pastOrder.isRestored ? (
                        <TransparentButtonWithPopover
                            className="h-[3.2vw] w-[3.2vw] text-[2vw] flex items-center justify-center"
                            onClick={() => handleRestoreAddToCart()}
                            focusClassName="!bg-pizzahut-red shadow-redSmudge"
                            popOverContent={pastOrderTranslationFunction('remove_from_cart_button')}
                        >
                            <IconArrowBadgeRightFilled size={`2.1vw`} />
                        </TransparentButtonWithPopover>
                    ) : (
                        <TransparentButtonWithPopover
                            className="h-[3.2vw] w-[3.2vw] text-[2vw] flex items-center justify-center"
                            onClick={() => handleRestoreAddToCart()}
                            focusClassName="!bg-pizzahut-red shadow-redSmudge"
                            popOverContent={pastOrderTranslationFunction('add_to_cart_button')}
                        >
                            <IconRotate size={`2.1vw`} />
                        </TransparentButtonWithPopover>
                    )}
                </div>
            </div>
        </FocusContext.Provider>
    );
}
