import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useSelector } from '../../../../redux/store';
import RedButton from '../../../../components/Buttons/RedButton';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';
import { useMemo, useState } from 'react';
import OpenModalDownArrowSvg from '../../../../components/OpenModalDownArrowSvg';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { DeliveryAddressModal } from '../DeliveryFormModals/DeliveryAddressModal';
import KosherSymbol from '../../../../components/KosherSymbol';

type DeliveryInputsType = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};
const DeliveryInputs = ({
    focusKey: focusKeyParam,
    onRowFocus,
    handleDeliveryFormUpdation,
}: DeliveryInputsType) => {
    const [deliveryAddressModalOpen, setDeliveryAddressModalOpen] = useState(false);
    const deliveryAddress = useSelector((state) => state.deliveryFormData.deliveryForm.address);

    const TranslateFunction = createTranslateFunction('checkout_screen.delivery_form');

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: onRowFocus,
        preferredChildFocusKey: 'DELIVERYADDRESS-BUTTON',
    });

    const handleDeliveryAddressModal = () => {
        setDeliveryAddressModalOpen(true);
        setFocus(`DELIVERY-ADDRESS-MODAL`);
    };

    const isKosher = useMemo<boolean>(
        () => !!deliveryAddress?.closest_branch?.atmos_data?.kosher || false,
        [deliveryAddress?.closest_branch?.atmos_data?.kosher],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col" ref={ref}>
                <RedButton
                    className={`w-[80%] h-[4vw] mb-[1vw] rounded-[1.1vw] pr-[3vw]`}
                    onClick={() => handleDeliveryAddressModal()}
                    focusKey="DELIVERYADDRESS-BUTTON"
                >
                    <div className="flex justify-start items-center relative">
                        <OpenModalDownArrowSvg className="absolute w-[2vw] h-[2vw] left-[0.6vw]" />
                        <span className="text-[1.2vw] text-white text-start ml-[2vw]">
                            {`${TranslateFunction('modals.delivery_address.title')}`}
                        </span>
                        {deliveryAddress && (
                            <span className="text-[1.2vw]">{`${deliveryAddress.street} ${deliveryAddress.no}, ${deliveryAddress.city}`}</span>
                        )}
                    </div>
                </RedButton>
                <RedButton
                    className={`
                                w-[80%] h-[4vw] mb-[1vw] rounded-[1.1vw] pr-[3vw]
                                    ${!deliveryAddress && 'bg-transparent-card'}
                            `}
                    onClick={() => console.log('test')}
                    focusKey="DELIVERYBRANCH-BUTTON"
                >
                    <div className="flex justify-start items-center relative">
                        <span className="text-[1.2vw] text-white text-start ml-[2vw]">
                            {deliveryAddress
                                ? `${TranslateFunction('modals.delivery_branch.sub_title')}`
                                : `${TranslateFunction('modals.delivery_branch.title')}`}
                        </span>
                        {deliveryAddress && (
                            <span className="text-[1.2vw]">{`${deliveryAddress.closest_branch?.name}`}</span>
                        )}
                        {isKosher && <KosherSymbol />}
                    </div>
                </RedButton>
                {deliveryAddressModalOpen && (
                    <DeliveryAddressModal
                        focusKey="DELIVERY-ADDRESS-MODAL"
                        handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        opened={deliveryAddressModalOpen}
                        close={() => setDeliveryAddressModalOpen(false)}
                    />
                )}
            </div>
        </FocusContext.Provider>
    );
};

export default DeliveryInputs;
