import React from 'react';
import { Extra } from '../../../../../redux/slices/dbFoodMenuSlice';

type ThreeToppingImagesProps = {
    whatSizeSelected: Extra;
};
const ThreeToppingImages = ({ whatSizeSelected }: ThreeToppingImagesProps) => {
    const theSelectedSize = whatSizeSelected.extraSize;
    return (
        <div className="flex w-[16vw]">
            <div
                className={`w-[2.8vw] h-[2.8vw] mx-[0.4vw] bg-food-portion rounded-full flex justify-center items-center border-4  'bg-selected-hut-red'} ${theSelectedSize === 'full' && `bg-selected-hut-red !border-selected-hut-red`}`}
            >
                <img
                    className="w-[1.5vw] h-[1.5vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/full_topping.svg"
                    alt="no svg"
                />
            </div>
            <div
                className={`w-[2.8vw] h-[2.8vw] mx-[0.4vw] bg-food-portion rounded-full flex justify-start items-center border-4 'bg-selected-hut-red'}  ${theSelectedSize === 'halfright' && `bg-selected-hut-red !border-selected-hut-red`}`}
            >
                <img
                    className="w-[1.5vw] h-[1.5vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfright_topping.svg"
                    alt="no svg"
                />
            </div>
            <div
                className={`w-[2.8vw] h-[2.8vw] mx-[0.4vw] bg-food-portion rounded-full flex justify-end items-center border-4 bg-selected-hut-red'}  ${theSelectedSize === 'halfleft' && `bg-selected-hut-red !border-selected-hut-red`}`}
            >
                <img
                    className="w-[1.5vw] h-[1.5vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfleft_topping.svg"
                    alt="no svg"
                />
            </div>
        </div>
    );
};

export default ThreeToppingImages;
