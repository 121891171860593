import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useRef } from 'react';

type SearchInputProps = {
    onChange: (searchTerm: string) => void;
    classNameParams?: string;
    inputValue: string;
    placeHolderValue?: string;
};

export const SearchInput = ({
    onChange,
    classNameParams,
    inputValue,
    placeHolderValue,
}: SearchInputProps) => {
    const { ref, focused, focusKey } = useFocusable({
        onFocus: () => {
            searchInputRef.current?.focus();
        },
        onBlur: () => {
            console.log('SEARCH BLURRED');
            searchInputRef.current?.blur();
        },
    });
    const searchInputRef = useRef<HTMLInputElement>(null);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex items-center justify-center" ref={ref}>
                <input
                    value={inputValue.length > 0 ? inputValue : ''}
                    ref={searchInputRef}
                    placeholder={placeHolderValue}
                    onChange={(e) => onChange(e.target.value)}
                    className={`bg-[transparent] border-white px-6 py-3 rounded-full ${classNameParams}
          ${focused ? 'border-[6px] outline-none' : 'border-2'}
          `}
                />
            </div>
        </FocusContext.Provider>
    );
};
