import { useEffect, useState } from 'react';

let recognition: SpeechRecognition | null = null;

if ('webkitSpeechRecognition' in window) {
    recognition = new webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.lang = 'he-IL';
}

const OLDuseSpeechRecognition = () => {
    const [voiceRecognitionText, setVoiceRecognitionText] = useState<string>('');
    const [isListening, setIsListening] = useState<boolean>(false);

    useEffect(() => {
        if (!recognition) return;

        const handleResult = (event: SpeechRecognitionEvent) => {
            // Ensure we're dealing with the final result
            if (event.results[0].isFinal) {
                const transcript = event.results[0][0].transcript;
                setIsListening(false);
                setVoiceRecognitionText(transcript);
            }
        };

        const handleEnd = () => {
            console.log('Speech recognition ended.');
            setIsListening(false);
        };

        const handleError = (event: SpeechRecognitionErrorEvent) => {
            console.error('Speech recognition error', event);
            setIsListening(false);
        };

        recognition.onresult = handleResult;
        recognition.onend = handleEnd;
        recognition.onerror = handleError;

        return () => {
            // Cleanup event listeners on component unmount or hook reinitialization
            if (recognition) {
                recognition.onresult = null;
                recognition.onend = null;
                recognition.onerror = null;
            }
        };
    }, []); // Empty dependency array ensures this effect runs once on mount

    const startListening = () => {
        if (!recognition || isListening) {
            return;
        }
        setVoiceRecognitionText('');
        setIsListening(true);
        recognition.start();
    };

    const stopListening = () => {
        if (recognition && isListening) {
            recognition.stop();
            setIsListening(false);
        }
    };
    useEffect(() => {
        console.log(isListening, 'This is the is listeningstate');
    }, [isListening]);

    return {
        voiceRecognitionText,
        isListening,
        startListening,
        stopListening,
        hasRecognitionSupport: !!recognition,
    };
};

export default OLDuseSpeechRecognition;
