import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { OTPSingleInput } from './OTPSingleInput';
import { SetStateAction, useEffect, useState } from 'react';

type FocusableOTPInputProps = {
    focusKey: string;
    OTPLength: number;
    setOTPValue: React.Dispatch<SetStateAction<string>>;
};
const FocusableOTPInput = ({
    focusKey: focusKeyParam,
    OTPLength,
    setOTPValue,
}: FocusableOTPInputProps) => {
    const [fullOtpValue, setFullOtpValue] = useState<string[]>(
        Array.from({ length: OTPLength }).fill('') as string[],
    );
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'OTP-INPUT-0',
    });
    const handleOtpChange = (otp: string, index: number) => {
        const newFullOtpValue = [...fullOtpValue];
        newFullOtpValue[index] = otp;
        setFullOtpValue(newFullOtpValue);
        if (otp === '' && index > 0) {
            setFocus('OTP-INPUT-' + (index - 1));
            return;
        }
        if (otp.length > 0 && index < OTPLength - 1) {
            setFocus('OTP-INPUT-' + (index + 1));
        }
    };

    useEffect(() => {
        setOTPValue(fullOtpValue.join(''));
    }, [fullOtpValue]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-row-reverse" ref={ref}>
                {fullOtpValue.map((otp, index) => (
                    <div key={index}>
                        <OTPSingleInput
                            focusKey={`OTP-INPUT-${index}`}
                            handleOtpChange={handleOtpChange}
                            inputValue={otp}
                            otpIndex={index}
                            otpLength={OTPLength}
                        />
                    </div>
                ))}
            </div>
        </FocusContext.Provider>
    );
};

export default FocusableOTPInput;
