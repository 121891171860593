export const items_icon_paths = [
    'icons/items_icons/1000.png',
    'icons/items_icons/10799.png',
    'icons/items_icons/10910.png',
    'icons/items_icons/11057.png',
    'icons/items_icons/11147.png',
    'icons/items_icons/11244.png',
    'icons/items_icons/1131.png',
    'icons/items_icons/1132.png',
    'icons/items_icons/1133.png',
    'icons/items_icons/11548.png',
    'icons/items_icons/11549.png',
    'icons/items_icons/11692.png',
    'icons/items_icons/11919.png',
    'icons/items_icons/11954.png',
    'icons/items_icons/11960.png',
    'icons/items_icons/12021.png',
    'icons/items_icons/12043.png',
    'icons/items_icons/12069.png',
    'icons/items_icons/1244.png',
    'icons/items_icons/1245.png',
    'icons/items_icons/12563.png',
    'icons/items_icons/12565.png',
    'icons/items_icons/12883.png',
    'icons/items_icons/13030.png',
    'icons/items_icons/13031.png',
    'icons/items_icons/1371.png',
    'icons/items_icons/1459.png',
    'icons/items_icons/1827.png',
    'icons/items_icons/21.png',
    'icons/items_icons/2199.png',
    'icons/items_icons/2200.png',
    'icons/items_icons/2255.png',
    'icons/items_icons/23.png',
    'icons/items_icons/237.png',
    'icons/items_icons/2379.png',
    'icons/items_icons/24.png',
    'icons/items_icons/25.png',
    'icons/items_icons/3073.png',
    'icons/items_icons/3097.png',
    'icons/items_icons/3098.png',
    'icons/items_icons/3695.png',
    'icons/items_icons/3697.png',
    'icons/items_icons/4132.png',
    'icons/items_icons/414.png',
    'icons/items_icons/4171.png',
    'icons/items_icons/4323.png',
    'icons/items_icons/433.png',
    'icons/items_icons/44.png',
    'icons/items_icons/45.png',
    'icons/items_icons/461.png',
    'icons/items_icons/48.png',
    'icons/items_icons/514.png',
    'icons/items_icons/516.png',
    'icons/items_icons/5253.png',
    'icons/items_icons/5676.png',
    'icons/items_icons/5789.png',
    'icons/items_icons/6491.png',
    'icons/items_icons/6492.png',
    'icons/items_icons/664.png',
    'icons/items_icons/668.png',
    'icons/items_icons/6919.png',
    'icons/items_icons/72.png',
    'icons/items_icons/73.jpg',
    'icons/items_icons/73.png',
    'icons/items_icons/7388.png',
    'icons/items_icons/7400.png',
    'icons/items_icons/7622.png',
    'icons/items_icons/7623.png',
    'icons/items_icons/7624.png',
    'icons/items_icons/77.png',
    'icons/items_icons/8299.png',
    'icons/items_icons/85.png',
    'icons/items_icons/86.png',
    'icons/items_icons/87.jpg',
    'icons/items_icons/87.png',
    'icons/items_icons/877.png',
    'icons/items_icons/878.png',
    'icons/items_icons/88.png',
    'icons/items_icons/8868.png',
    'icons/items_icons/89.png',
    'icons/items_icons/8971.png',
    'icons/items_icons/93.png',
    'icons/items_icons/94.png',
    'icons/items_icons/9458.png',
    'icons/items_icons/954.png',
    'icons/items_icons/9975.png',
    'icons/items_icons/10057.png',
    'icons/items_icons/9974.png',
    'icons/items_icons/10056.png',
    'icons/items_icons/12293.png',
    'icons/items_icons/12282.png',
    'icons/items_icons/12290.png',
    'icons/items_icons/13524.png',
    'icons/items_icons/13454.png',

    'icons/salad_extras_icons/3053.png',
    'icons/salad_extras_icons/3071.png',
    'icons/salad_extras_icons/46.png',
    'icons/salad_extras_icons/47.png',
    'icons/salad_extras_icons/49.png',
    'icons/salad_extras_icons/50.png',
    'icons/salad_extras_icons/51.png',
    'icons/salad_extras_icons/52.png',
    'icons/salad_extras_icons/53.png',
    'icons/salad_extras_icons/54.png',
    'icons/salad_extras_icons/55.png',
    'icons/salad_extras_icons/56.png',
    'icons/salad_extras_icons/57.png',
    'icons/salad_extras_icons/96.png',
    'icons/salad_extras_icons/97.png',

    'screen-bg/menu/1.jpg',
    'screen-bg/menu/blurred.jpg',
    'screen-bg/menu/box.jpg',
    'screen-bg/menu/box2.jpg',
    'screen-bg/menu/cheesy.jpg',
    'screen-bg/menu/cheesy2.jpg',
    'screen-bg/menu/combo.jpg',
    'screen-bg/menu/couple.jpg',
    'screen-bg/menu/couple2.jpg',
    'screen-bg/menu/crown.jpg',
    'screen-bg/menu/dessert.jpg',
    'screen-bg/menu/faded.png',
    'screen-bg/menu/faded2.jpg',
    'screen-bg/menu/faded3.jpg',
    'screen-bg/menu/pan.jpg',
    'screen-bg/menu/pasta.jpg',
    'screen-bg/menu/salad.jpg',
    'screen-bg/menu/side.jpg',
    'screen-bg/menu/slice.jpg',
    'screen-bg/menu/thin.jpg',
    'screen-bg/menu/threepizzas.jpg',
    'screen-bg/menu/generic.jpg',
    'screen-bg/menu/belgian_waffle.jpg',
    'screen-bg/menu/cream_pasta.jpg',
    'screen-bg/menu/chocolate_bagel.jpg',
    'screen-bg/menu/chocolate_sticks.jpg',
    'screen-bg/menu/chocolate_ziva.jpg',
    'screen-bg/menu/donuts.jpg',
    'screen-bg/menu/cookies.jpg',
    'screen-bg/menu/loved.jpg',
    'screen-bg/menu/argentina.jpg',
    'screen-bg/menu/thick_greek.jpg',
    'screen-bg/menu/thick_kafrit.jpg',
    'screen-bg/menu/thick_hawai.jpg',
    'screen-bg/menu/thin_greek.jpg',
    'screen-bg/menu/thin_kafrit.jpg',
    'screen-bg/menu/thin_hawai.jpg',
    'screen-bg/menu/cheesybites.jpg',
    'screen-bg/menu/cheesycrust.jpg',
    'screen-bg/menu/crown.jpg',
    'screen-bg/menu/haloomi.jpg',
    'screen-bg/menu/batata_kish.jpg',
    'screen-bg/menu/mushrooms_kish.jpg',
    'screen-bg/menu/garlic_feta_bread.jpg',
    'screen-bg/menu/malawah_pizza.jpg',
    'screen-bg/menu/onion_rings.jpg',
    'screen-bg/menu/ziva.jpg',
    'screen-bg/menu/mozzarella_bagel.jpg',
    'screen-bg/menu/mozzarella_sticks.jpg',
    'screen-bg/menu/cream_pasta.jpg',
    'screen-bg/menu/mushroom_cream_pasta.jpg',
    'screen-bg/menu/roze_pasta.jpg',
    'screen-bg/menu/tomato_pasta.jpg',

    'screen-bg/screens/tracking-page/Track_Order_Background.png',
    `icons/assets/pizzahut-logo.svg`,
    'icons/assets/full_topping.svg',
    'icons/assets/halfleft_topping.svg',
    'icons/assets/halfright_topping.svg',
    'icons/assets/google_apple_payment.svg',

    'icons/sidebar/sidebar_branches_icon.svg',
    'icons/sidebar/sidebar_home_icon.svg',
    'icons/sidebar/sidebar_loveditems_icon.svg',
    'icons/sidebar/sidebar_menu_icon.svg',
    'icons/sidebar/sidebar_search_icon.svg',
    'icons/sidebar/sidebar_settings_icon.svg',
];
