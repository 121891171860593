import { useCallback, useRef, useState } from 'react';
import {
    useFocusable,
    FocusContext,
    FocusableComponentLayout,
    FocusDetails,
} from '@noriginmedia/norigin-spatial-navigation';
import { PastOrderCard } from './PastOrderCard';
import { useSelector } from '../../../../redux/store';
import IsAbleToScrollIndicator from '../../../../components/ScrollingIndicator/IsAbleToScrollIndicator';
type PastOrdersRowProps = {
    title: string;
    onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
};

export const PastOrdersRow = ({ title, onFocus, focusKey: focusKeyParam }: PastOrdersRowProps) => {
    const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
    const pastOrders = useSelector((state) => state.orders.orders);
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
        preferredChildFocusKey: `pastordercard-0`,
    });
    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (scrollingRef.current) {
                scrollingRef.current.scrollLeft = x - 150;
                scrollingRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [scrollingRef],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="mt-[3vw] h-[28vw] flex flex-col justify-evenly" ref={ref}>
                <IsAbleToScrollIndicator
                    indexInRow={currentFocusIndex}
                    rowLength={pastOrders.length}
                    titleString={title}
                />
                <div className="flex overflow-x-auto h-[27vw] py-[0.5vw]" ref={scrollingRef}>
                    {pastOrders.map((asset, index) => (
                        <PastOrderCard
                            focusKey={`pastordercard-${index}`}
                            key={index}
                            pastOrder={asset}
                            onFocus={onAssetFocus}
                            setCurrentFocusIndex={setCurrentFocusIndex}
                            componentIndex={index}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
