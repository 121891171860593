import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import FoodToppingPortionButton from './FoodToppingPortionButton';
import { Extra } from '../../redux/slices/dbFoodMenuSlice';

type FoodToppingProps = {
    focusKey: string;
    topping: Extra;
    pizzaToppingsArray: Extra[];
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
    onSelectPizzaTopping: (topping: Extra, extraSize: string) => void;
};
const FoodTopping = ({
    focusKey: focusKeyParam,
    pizzaToppingsArray,
    onRowFocus,
    topping,
    onSelectPizzaTopping,
}: FoodToppingProps) => {
    const [isChildFocused] = useState<boolean>(false);
    const { ref, focused, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: handleFocus,
        onEnterPress: handleToppingClick,
        preferredChildFocusKey: `${focusKeyParam}-full`,
        saveLastFocusedChild: true,
    });

    function handleToppingClick() {
        setFocus(`${focusKey}-full`);
    }

    function handleFocus(layout: FocusableComponentLayout, props: unknown, details: FocusDetails) {
        console.log('FOCUS', focusKey);
        onRowFocus(layout, props, details);
    }

    const isSelectedFunction = (toppingName: string, extraSize: string) => {
        const topping = pizzaToppingsArray.find((topping) => topping.name === toppingName);
        return topping && topping.extraSize === extraSize ? true : false;
    };

    const removeToppingSizeFromString = (input: string): string => {
        const regex = /[a-zA-Z]/g;
        return input.replace(regex, '');
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`bg-gray-card bg-opacity-60 py-[1.5vw] px-[2vw] mx-[2vw] my-[0.3vw] h-[5.5vw] w-[80%] flex items-center justify-between rounded-full ${focused && 'bg-gray-card bg-opacity-80 scale-105'} ${isChildFocused && 'bg-gray-card bg-opacity-80 scale-105'}`}
            >
                <span className="text-[1.5vw]">{`${removeToppingSizeFromString(topping.name)}`}</span>
                <div className="flex justify-evenly w-[40%]">
                    <FoodToppingPortionButton
                        topping={topping}
                        toppingPortion={'full'}
                        focusKey={`${focusKey}-full`}
                        isSelected={isSelectedFunction(topping.name, 'full')}
                        onSelectPizzaTopping={onSelectPizzaTopping}
                    />
                    <FoodToppingPortionButton
                        topping={topping}
                        toppingPortion={'halfleft'}
                        focusKey={`${focusKey}-halfleft`}
                        isSelected={isSelectedFunction(topping.name, 'halfleft')}
                        onSelectPizzaTopping={onSelectPizzaTopping}
                    />
                    <FoodToppingPortionButton
                        topping={topping}
                        toppingPortion={'halfright'}
                        focusKey={`${focusKey}-halfright`}
                        isSelected={isSelectedFunction(topping.name, 'halfright')}
                        onSelectPizzaTopping={onSelectPizzaTopping}
                    />
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default FoodTopping;
