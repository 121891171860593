import { useCallback, useRef } from 'react';
import TransparentButton from '../../components/Buttons/TransparentButton';
import { useSelector } from '../../redux/store';
import { PaymentMethod } from '../../types/paymentMethod';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type pickACardStageOneProps = {
    handleCardClick: (userCard: PaymentMethod) => void;
};
const PickACardStageOne = ({ handleCardClick }: pickACardStageOneProps) => {
    const userCards = useSelector((state) => state.userAuth.user?.payment_methods);
    const TranslateFunction = createTranslateFunction(
        'checkout_screen.delivery_form.modals.pick_a_card',
    );

    const modalContainerRef = useRef<HTMLDivElement>(null);
    const onRowFocus = useCallback(
        ({ y }) => {
            if (modalContainerRef.current) {
                modalContainerRef.current.scrollTop = y - 200;
                modalContainerRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [modalContainerRef],
    );

    return (
        <div className="flex flex-col items-center justify-evenly h-[100%]">
            <span className="text-white text-[2vw] mb-[1vw]">{`${TranslateFunction(`title`)}`}</span>
            {userCards &&
                userCards.map((userCard, index) => (
                    <TransparentButton
                        key={index}
                        className="mt-[0.6vw] border-black border-2 text-black !py-[1vw] !text-[1.7vw] w-[30vw] h-[10vw] rounded-[3vw]"
                        focusClassName={`!bg-pizzahut-red !text-white`}
                        focusKey={`PICKUP-BRANCH-${index}`}
                        onClick={() => {
                            handleCardClick(userCard);
                        }}
                        onBtnFocus={onRowFocus}
                    >
                        {`${userCard.cardId.slice(-4)} **** **** ****`}
                    </TransparentButton>
                ))}
        </div>
    );
};

export default PickACardStageOne;
