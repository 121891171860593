type LoadingProps = {
    className?: string;
};
export const Loading = ({ className }: LoadingProps) => {
    return (
        <div
            className={`animate-pulse w-full h-full flex items-center justify-center bg-black ${className}`}
        >
            <img src="/pizzahut-icon.svg" alt="" className="w-[40vw] h-[40vw]" />
        </div>
    );
};
