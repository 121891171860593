import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import { AppDispatch, useSelector } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import {
    ResetAssistantPage,
    ToggleProccessFinishedTrue,
} from '../../../../redux/slices/personalAssistantSlice';
import { useNavigate } from 'react-router-dom';
import { addToCart } from '../../../../redux/slices/cartSlice';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type AddItemToCartButtonProps = {
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
    handleResetPageFunction: () => void;
};

export function AddItemToCartButton({
    onFocus,
    focusKey: focusKeyParam,
    handleResetPageFunction,
}: AddItemToCartButtonProps) {
    const [isHovered, setIsHovered] = useState(false);
    const chosenCategory = useSelector((state) => state.personalAssistant.chosenCategory);
    const itemToKeep = useSelector((state) => state.personalAssistant.chooseToKeepItem);
    const navigate = useNavigate();
    const chooseToKeepItemTranslation = createTranslateFunction(
        'personal_assistant_screen.choose_to_keep_item',
    );

    const dispatch = useDispatch<AppDispatch>();

    const handleAddItemToCartButtonClick = () => {
        console.log(itemToKeep, 'This is the item to keep');

        if (!itemToKeep) {
            return;
        }
        dispatch(addToCart(itemToKeep));
        dispatch(ToggleProccessFinishedTrue());
        handleResetPageFunction();
        // navigate('/');
        setIsHovered(false);
    };
    const { ref, focused, focusKey } = useFocusable({
        onFocus,
        focusKey: focusKeyParam,
        onEnterPress: handleAddItemToCartButtonClick,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleAddItemToCartButtonClick}
                ref={ref}
                className={`w-[13vw] h-[13vw] flex flex-col rounded-categoryRadius cursor-pointer bg-[#222222] bg-opacity-60 justify-evenly items-center ${
                    (focused || isHovered) && '!bg-pizzahut-red !bg-opacity-100'
                } `}
            >
                <img
                    className="w-[2.5vw] h-[2.5vw]"
                    src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/ShoppingCartIcon.svg"
                    alt="Shopping Cart Icon"
                />
                <span className="text-[1.4vw] text-center max-w-[60%]">
                    {chooseToKeepItemTranslation('add_to_cart_button')}
                </span>
            </div>
        </FocusContext.Provider>
    );
}
