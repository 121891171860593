import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Dispatch, useCallback, useEffect } from 'react';
import TotalSummaryBar from './TotalSummaryBar';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../../components/Buttons/BackButton';
import { useSelector } from '../../../redux/store';
import DeliveryOrPickup from './DeliveryFormInputs/DeliveryOrPickup';
import DeliveryFormInputs from './DeliveryFormInputs/DeliveryFormInputs';

type DeliveryFormProps = {
    focusKey: string;
    setAlertModalTitle: Dispatch<string>;
    setIsAlertModalOpen: Dispatch<boolean>;
    setIsLoading: Dispatch<boolean>;
};
const DeliveryForm = ({
    focusKey: focusKeyParam,
    setAlertModalTitle,
    setIsAlertModalOpen,
    setIsLoading,
}: DeliveryFormProps) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });
    const navigate = useNavigate();
    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);

    const onRowFocus = useCallback(
        ({ y }) => {
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const toggleCheckoutAlertModal = (errorMessage: string) => {
        setAlertModalTitle(errorMessage);
        setIsAlertModalOpen(true);
        setFocus('CHECKOUT-ALERT-MODAL');
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="w-[70%] flex flex-col justify-between">
                <div className="flex flex-col pt-[1vw] pr-[2vw]">
                    <DeliveryFormInputs onRowFocus={onRowFocus} focusKey="delivery-form-inputs" />
                </div>
                <TotalSummaryBar
                    toggleCheckoutAlertModal={toggleCheckoutAlertModal}
                    setIsLoading={setIsLoading}
                    focusKey="TOTAL-SUMMARY-BAR"
                />
            </div>
        </FocusContext.Provider>
    );
};

export default DeliveryForm;
