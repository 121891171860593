import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import { AppDispatch, useSelector } from '../../redux/store';
import useSpeechRecognition from '../../components/SpeechToText/OLDuseSpeechRecognition';
import { useDispatch } from 'react-redux';
import { setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import DeviceNotSupportedScreen from './DeviceNotSupportedScreen';
import PersonalAssistant from './PersonalAssistant';
import PersonalAssistantLoader from './PersonalAssistantLoader';

type PersonalAssistantScreenProps = {
    focusKey: string;
};

export const PersonalAssistantScreen = ({
    focusKey: focusKeyParam,
}: PersonalAssistantScreenProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isMelingoQueryLoading } = useSelector((state) => state.personalAssistant);
    const { hasRecognitionSupport } = useSpeechRecognition();
    const { focusKey, ref } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    useEffect(() => {
        dispatch(
            setAppBackgroundTwClass(
                // 'bg-[url("assets/images/screen-bg/pizzas/faded.png")] bg-no-repeat bg-cover bg-opacity-95',
                'bg-black bg-no-repeat bg-cover bg-opacity-95',
            ),
        );
    }, []);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="w-full h-full">
                {hasRecognitionSupport ? (
                    <div className="w-full h-full relative">
                        {isMelingoQueryLoading ? <PersonalAssistantLoader /> : null}
                        <PersonalAssistant focusKey="PERSONAL-ASSISTANT" />
                    </div>
                ) : (
                    <DeviceNotSupportedScreen />
                )}
            </div>
        </FocusContext.Provider>
    );
};
