import React from 'react';

type PickACardStageMeter = {
    pickACardStage: number;
};
const PickACardStageMeter = ({ pickACardStage }: PickACardStageMeter) => {
    return (
        <div className="flex mb-[2vw] items-center">
            <div
                className={`bg-gray-500 text-[1.4vw] h-[3vw] w-[3vw] rounded-full text-center flex items-center justify-center text-white border-[0.1vw] border-gray-500`}
            >
                1
            </div>
            <div
                className={`bg-gray-400 w-[3.5vw] h-[0.3vw] ${pickACardStage === 2 && 'bg-gray-500'}`}
            ></div>
            <div
                className={`bg-transparent text-[1.4vw] h-[3vw] w-[3vw] rounded-full text-center flex items-center justify-center text-white border-[0.1vw] border-gray-400 ${pickACardStage === 2 && '!bg-gray-500 !border-gray-500'}`}
            >
                2
            </div>
        </div>
    );
};

export default PickACardStageMeter;
