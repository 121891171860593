import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { PizzaToppingsList } from '../../components/Toppings/PizzaToppingsList';
import { ToppingsList } from '../../components/Toppings/ToppingsList';
import { SaladSaucesList } from '../../components/Toppings/SaladSaucesList';
import { Extra, Sauce } from '../../redux/slices/dbFoodMenuSlice';
import { CartItem } from '../../redux/slices/cartSlice';
import { calculatePizzaToppingsAmount } from './EditFoodScreenHelperFunctions';

type ExtrasSideProps = {
    focusKey: string;
    categoryName: string;
    pizzaToppingsArray: Extra[];
    itemToppingsArray: Extra[];
    currentItem: CartItem;
    saucesArray: Sauce[];
    setSaucesArray: React.Dispatch<React.SetStateAction<Sauce[]>>;
    setPizzaToppingsArray: React.Dispatch<React.SetStateAction<Extra[]>>;
    setItemToppingsArray: React.Dispatch<React.SetStateAction<Extra[]>>;
    setIsMaxToppingsAlertModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    IsCategoryPizza: () => boolean;
};
const ExtrasSide = ({
    focusKey: focusKeyParam,
    categoryName,
    pizzaToppingsArray,
    itemToppingsArray,
    currentItem,
    saucesArray,
    setSaucesArray,
    setPizzaToppingsArray,
    setItemToppingsArray,
    setIsMaxToppingsAlertModalOpen,
    IsCategoryPizza,
}: ExtrasSideProps) => {
    const TranslateFunction = createTranslateFunction('edit_food_screen.personalize');

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const areThereExtras = () => {
        return currentItem.item?.extras.length > 0;
    };
    const areThereSauces = () => {
        return currentItem.item?.sauces?.length > 0;
    };

    const maxToppingAmount = () => {
        if (categoryName === 'פיצות') {
            return 3;
        } else if (categoryName === 'סלטים') {
            return 8;
        }
    };

    const isPizzaExtraInToppingsArray = (toppingName: string, extraSize: string) => {
        const topping = pizzaToppingsArray.find((topping) => topping.name === toppingName);
        return topping && topping.extraSize === extraSize ? true : false;
    };

    const onSelectPizzaTopping = (topping: Extra, extraSize: string) => {
        const tempPizzaArray = [...pizzaToppingsArray];
        if (isPizzaExtraInToppingsArray(topping.name, extraSize)) {
            setPizzaToppingsArray((prev) =>
                prev.filter((pizzaTopping) => pizzaTopping.name !== topping.name),
            );
            return;
        }

        const toppingsQuantity = calculatePizzaToppingsAmount(pizzaToppingsArray);
        const currentTopping = tempPizzaArray.find(
            (arrayTopping) => arrayTopping.name === topping.name,
        );

        if (currentTopping) {
            if (currentTopping.extraSize === 'full') {
                toppingsQuantity[0] = toppingsQuantity[0] - 1;
                toppingsQuantity[1] = toppingsQuantity[1] - 1;
            } else if (currentTopping.extraSize === 'halfleft') {
                toppingsQuantity[0] = toppingsQuantity[0] - 1;
            } else if (currentTopping.extraSize === 'halfright') {
                toppingsQuantity[1] = toppingsQuantity[1] - 1;
            }
        }

        if (extraSize === 'full') {
            toppingsQuantity[0] = toppingsQuantity[0] + 1;
            toppingsQuantity[1] = toppingsQuantity[1] + 1;
        } else if (extraSize === 'halfleft') {
            toppingsQuantity[0] = toppingsQuantity[0] + 1;
        } else if (extraSize === 'halfright') {
            toppingsQuantity[1] = toppingsQuantity[1] + 1;
        }

        if (toppingsQuantity[0] > 3 || toppingsQuantity[1] > 3) {
            setIsMaxToppingsAlertModalOpen(true);
            setFocus('MAX-TOPPINGS-ALERT');
            return;
        }
        const newTopping = { ...topping, extraSize: extraSize };
        if (currentTopping?.extraSize !== extraSize) {
            setPizzaToppingsArray([
                ...tempPizzaArray.filter((pizzaTopping) => pizzaTopping.name !== topping.name),
                newTopping,
            ]);
            return;
        }
        setPizzaToppingsArray((prevToppings) => [...prevToppings, newTopping]);
    };

    const onSelectItemTopping = (topping: Extra) => {
        const newToppings = [...itemToppingsArray];
        if (newToppings.find((newTopping) => newTopping.name === topping.name)) {
            setItemToppingsArray(
                newToppings.filter((newTopping) => newTopping.name !== topping.name),
            );
        } else {
            if (newToppings.length < 8) {
                setItemToppingsArray([...newToppings, topping]);
            } else {
                setIsMaxToppingsAlertModalOpen(true);
                setFocus('MAX-TOPPINGS-ALERT');
                return;
            }
        }
    };
    if (areThereExtras() === false && areThereSauces() === false) {
        return null;
    }

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="w-[50%]">
                {areThereExtras() && (
                    <div className="flex flex-col justify-start h-full pt-[1vw]">
                        <div className="flex flex-col my-[2vw]">
                            <span className="text-[2vw]">{`${TranslateFunction(`title`)}`}</span>
                            <span className="text-[1.4vw] text-gray-500">{`${TranslateFunction(`sub_title`)}`}</span>
                            {!IsCategoryPizza() && (
                                <div className="flex items-center">
                                    <span className="text-[1.4vw] pl-[2vw]">{`${TranslateFunction(`condition`, { count: maxToppingAmount() })}`}</span>
                                    <div className="w-[3.5vw] h-[3.5vw] flex justify-center items-center bg-pizzahut-red rounded-full">
                                        <span className="text-[1.2vw]">{`${pizzaToppingsArray.length > 3 ? '3+' : itemToppingsArray.length}`}</span>
                                    </div>
                                    {/* {itemToppingsArray.length <= 2 && (
                                        <span className="text-[1.4vw] mr-[14vw] text-pizzahut-red">{`${TranslateFunction(`obligatory`)}`}</span>
                                    )} */}
                                </div>
                            )}
                        </div>
                        {IsCategoryPizza() ? (
                            <PizzaToppingsList
                                pizzaToppingsArray={pizzaToppingsArray}
                                focusKey="TOPPINGSLIST"
                                toppings={currentItem.item.extras}
                                onSelectPizzaTopping={onSelectPizzaTopping}
                            />
                        ) : (
                            <ToppingsList
                                itemToppingsArray={itemToppingsArray}
                                focusKey="TOPPINGSLIST"
                                toppings={currentItem.item.extras}
                                onSelectItemTopping={onSelectItemTopping}
                            />
                        )}
                    </div>
                )}
                {areThereSauces() && (
                    <SaladSaucesList
                        focusKey="SALADSAUCES"
                        saucesArray={saucesArray}
                        setSauces={setSaucesArray}
                    />
                )}
            </div>
        </FocusContext.Provider>
    );
};

export default ExtrasSide;
