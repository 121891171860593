import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';

type StepBackButtonProps = {
    focusKey: string;
    className?: string;
    handleOnClick: () => void;
};
const StepBackButton = ({
    className,
    focusKey: focusKeyParam,
    handleOnClick,
}: StepBackButtonProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const { ref, focusKey, focused } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        onEnterPress: handleOnClick,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ref={ref}
                className={`w-[4vw] h-[4vw] rounded-full flex justify-center items-center cursor-pointer bg-gray-600 ${className} ${(isHovered || focused) && `border-[0.2vw] border-white scale-115`}`}
                onClick={handleOnClick}
            >
                <img
                    className="w-[50%] h-[50%]"
                    src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/ArrowUp.svg"
                    alt="Delete Last Step Button"
                />
            </div>
        </FocusContext.Provider>
    );
};

export default StepBackButton;
