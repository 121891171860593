import React, { useEffect, useState } from 'react';
import { Order } from '../../../../redux/slices/ordersSlice';
import moment from 'moment';
import 'moment/locale/he';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type PastOrderTimeAndDateProps = {
    pastOrder: Order;
};

type organizedDataProps = {
    pastOrderLength: number;
    dates: {
        date: string;
        timeOfOrder: string;
    };
};
const PastOrderTimeAndDate = ({ pastOrder }: PastOrderTimeAndDateProps) => {
    const pastOrderTranslationFunction = createTranslateFunction('home_screen.past_orders');

    const [organizedData, setOrganizedData] = useState<organizedDataProps>({
        pastOrderLength: 0,
        dates: { date: '', timeOfOrder: '' },
    });

    useEffect(() => {
        const dateOfOrder = new Date(pastOrder?.created_at);
        const dates = {
            date: moment(dateOfOrder).locale('he').format('LL'),
            timeOfOrder: `${moment(dateOfOrder).locale('he').format('LT')}`,
        };
        const state = {
            pastOrderLength: pastOrder?.cart.length,
            dates: dates,
        };
        setOrganizedData(state);
    }, [pastOrder]);

    return (
        <div className="flex flex-col w-[50%] h-full items-center justify-center">
            <div className="flex flex-col justify-center items-center w-[16vw] h-[16vw] mb-[2vw] rounded-[5vw] bg-black bg-opacity-50">
                <span className="text-[5vw] font-semibold">{`${organizedData?.pastOrderLength}`}</span>
                <span className="text-[2.4vw]">{pastOrderTranslationFunction('items')}</span>
            </div>
            <div className="flex flex-col items-center">
                <span className="text-[2vw]">{`${organizedData?.dates?.date}`}</span>
                <span className="text-[1.4vw]">{`${pastOrderTranslationFunction('time_of_order')} : ${organizedData?.dates?.timeOfOrder}`}</span>
            </div>
        </div>
    );
};

export default PastOrderTimeAndDate;
