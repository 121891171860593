import { useFocusable, FocusContext, setFocus } from '@noriginmedia/norigin-spatial-navigation';
import RedButton from '../../components/Buttons/RedButton';
import { useDispatch } from 'react-redux';
import { closeCouponCode } from '../../redux/slices/couponCode';
import TransparentButton from '../../components/Buttons/TransparentButton';
import TransperentInput from '../../components/Inputs/TransperentInput';
import { useEffect, useState } from 'react';

type CouponCodePopupProps = {
    focusKey: string;
};

export function CouponCodePopup({ focusKey: focusKeyParam }: CouponCodePopupProps) {
    const [couponCodeValue, setCouponCodeValue] = useState<string>('');
    const dispatch = useDispatch();
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        trackChildren: true,
        focusKey: focusKeyParam,
        onBlur: () => {
            // setFocus('MENU-ROW');
            dispatch(closeCouponCode());
        },
        preferredChildFocusKey: 'coupon-code-input',
    });
    useEffect(() => {
        focusSelf();
    }, []);
    const submitCouponCode = () => {
        console.log(couponCodeValue);
    };
    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`flex flex-col items-center p-[1vw] absolute w-[36%] h-full left-0 top-0 transition-all duration-400 ease-in-out bg-black bg-opacity-80 z-20`}
            >
                <div className="flex justify-end w-full mb-[4vw]">
                    <TransparentButton
                        className="border-full w-[4vw] h-[4vw] text-[1.6vw] flex justify-center items-center"
                        focusKey="coupon-closebutton"
                        onClick={() => {
                            setFocus('MENU-ROW');
                            dispatch(closeCouponCode());
                        }}
                    >
                        {`✖`}
                    </TransparentButton>
                </div>
                <span className={`text-white text-[2.4vw] w-[25vw] font-semibold`}>
                    האם יש לך קופון?
                </span>
                <span className="text-white text-[1.2vw] w-[25vw] font-extralight mb-[2vw]">
                    {`רשמו כאן את קוד הקופון שלכם`}
                </span>
                <div className="flex flex-col justify-center">
                    <TransperentInput
                        placeholderValue="רשום כאן"
                        focusKey="coupon-code-input"
                        setStateValue={setCouponCodeValue}
                        className="mb-[1vw]"
                    ></TransperentInput>
                    <RedButton
                        onClick={() => submitCouponCode()}
                        className="w-[25vw]"
                        focusKey="apply-coupon-button"
                    >
                        מימוש
                    </RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
}
