import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../lib/axios';
import {
    AccessToken,
    getAccessToken,
    getUserFromStorage,
    setAccessToken,
    setUserToStorage,
    clearStorage,
} from '../../lib/auth';
import { User } from '../../types/user';

// Types
type InitAuthArgs = {
    phone_number: string;
};

type InitAuthResponse = {
    session: string;
};

type VerifyAuthArgs = {
    phone_number: string;
    session: string;
    code: string;
};

type VerifyAuthResponse = {
    user: any; // Adjust 'any' to match the shape of your user object
    token: AccessToken;
};

type UpdateUserArgs = {
    payload: any; // Assuming you have a type for user info
};

type UpdateUserResponse = {
    user: any; // Adjust 'any' to match the shape of your user object
};

type GetACodeResponse = {
    token: AccessToken;
    user: User;
    message: string;
};

// Initial state
type InitialState = {
    user: User | null;
    token: AccessToken | null;
    session: string | null;
    loading: boolean;
    error: any;
};

const initialState: InitialState = {
    user: getUserFromStorage() || null,
    token: getAccessToken(),
    session: null,
    loading: false,
    error: null,
};

// {import.meta.env.MOBILE_APP_URL}/associate-tv?tv_name=${tv_name}

// Async Thunks
export const initiateSmsAuth = createAsyncThunk<InitAuthResponse, InitAuthArgs>(
    'userAuth/initiateSmsAuth',
    async ({ phone_number }: { phone_number: string }, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/initiate_sms_auth', { phone_number });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const verifySmsAuth = createAsyncThunk<VerifyAuthResponse, VerifyAuthArgs>(
    'userAuth/verifySmsAuth',
    async ({ phone_number, session, code }, { rejectWithValue }) => {
        try {
            const response = await api.post('/auth/verify_sms_auth', {
                phone_number,
                session,
                code,
            });
            return response.data; // Expected to include { user: ..., token: {} }
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const updateUserInfo = createAsyncThunk<UpdateUserResponse, UpdateUserArgs>(
    'user/updateUserInfo',
    async ({ payload }, { rejectWithValue }) => {
        try {
            const response = await api.post(`/user`, payload, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()?.access_token}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const getACode = createAsyncThunk<GetACodeResponse, string>(
    'user/getACode',
    async (code, { rejectWithValue }) => {
        try {
            const response = await api.get(`/get_a_code/${code}`, {});
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);
export const fetchMe = createAsyncThunk<User, void>(
    'user/getMe',
    async (_, { rejectWithValue }) => {
        try {
            const userToken = await getAccessToken()?.access_token;
            if (!userToken) {
                return;
            }
            const response = await api.get(`/user/me`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

// export const toggleFavoriteItem = createAsyncThunk<toggleFavoriteResponse, toggleFavoriteArgs>(
//     'userAuth/toggleFavoriteItem',
//     async (item, { rejectWithValue }) => {
//         try {
//             const response = await api.post('/user/toggle-favorite', { item });
//             return response.data;
//         } catch (error: any) {
//             return rejectWithValue(error.response.data);
//         }
//     },
// );

// Slice
export const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState: initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.session = null;
            state.error = null;
            clearStorage();
        },
        deleteError: (state) => {
            state.error = null;
        },
        toggleFavoriteItem: (state, action) => {
            const item = action.payload;
            if (state.user) {
                if (state.user.favorites.find((favorite) => favorite._id === item._id)) {
                    state.user.favorites = state.user.favorites.filter(
                        (favorite) => favorite._id !== item._id,
                    );
                } else {
                    state.user.favorites.push(item);
                }
            } else {
                return;
            }
        },
        removeFavoriteItem: (state, action) => {
            const item = action.payload;
            if (state.user) {
                state.user.favorites = state.user.favorites.filter(
                    (favorite) => favorite._id !== item._id,
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            /* Initiate SMS Auth reducers */
            .addCase(initiateSmsAuth.pending, (state) => {
                state.loading = true;
            })
            .addCase(initiateSmsAuth.fulfilled, (state, action) => {
                state.session = action.payload.session;
                state.loading = false;
            })
            .addCase(initiateSmsAuth.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            /* Verify SMS Auth reducers */
            .addCase(verifySmsAuth.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifySmsAuth.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.token = action.payload.token;
                setUserToStorage(action.payload.user);
                setAccessToken(action.payload.token);
                state.loading = false;
            })
            .addCase(verifySmsAuth.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            /* Update User Info reducers */
            .addCase(updateUserInfo.pending, (state, action) => {
                console.log(action.meta.arg, 'This is the action');
                const { payload } = action.meta.arg;

                for (const key in payload) {
                    if (Object.prototype.hasOwnProperty.call(payload, key)) {
                        state.user![key] = payload[key];
                    }
                }
                state.loading = true;
            })
            .addCase(updateUserInfo.fulfilled, (state, action) => {
                state.user = action.payload.user;
                setUserToStorage(action.payload.user);
                state.loading = false;
            })
            .addCase(updateUserInfo.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getACode.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getACode.fulfilled, (state, action) => {
                state.user = action.payload.user;
                state.token = action.payload.token;
                setUserToStorage(action.payload.user);
                setAccessToken(action.payload.token);
                state.loading = false;
            })
            .addCase(fetchMe.fulfilled, (state, action) => {
                state.user = action.payload;
                setUserToStorage(action.payload);
                state.loading = false;
            })
            .addCase(fetchMe.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

// Actions
export const { setUser, logout, deleteError } = userAuthSlice.actions;

// Reducer
export default userAuthSlice.reducer;
