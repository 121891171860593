import { useEffect, useState } from 'react';
import { Item } from '../../redux/slices/dbFoodMenuSlice';
import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import RedButton from '../../components/Buttons/RedButton';
import TransparentButton from '../../components/Buttons/TransparentButton';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from '../../redux/slices/userAuthSlice';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, useSelector } from '../../redux/store';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';

type favoriteItemProps = {
    focusKey: string;
    item: Item;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
};
const FavoriteItem = ({ focusKey: focusKeyParam, onRowFocus, item }: favoriteItemProps) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const { user } = useSelector((state) => state.userAuth);
    const favoriteItemTranslateFunction = createTranslateFunction('favorite_items_screen');
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) =>
            onRowFocus(layout, props, details),
    });

    const handleDeleteFavoriteItem = () => {
        const newFavorites = user?.favorites.filter((favorite) => favorite._id !== item._id);
        dispatch(updateUserInfo({ payload: { favorites: newFavorites } }));
    };

    const handlePurchaseFavoriteItem = () => {
        const categoryName = item.main_category ? item.main_category.name : item.category.name;

        const cartItem = {
            item: item,
            extras: [],
        };
        const state = {
            item: cartItem,
            categoryName,
        };
        navigate(`/editfoodscreen`, { state });
    };

    useEffect(() => {
        dispatch(
            setAppBackgroundTwClass(
                'bg-[url("https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/couple2.jpg")] bg-no-repeat bg-cover',
            ),
        );
    }, []);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="relative w-[100%] h-[14vw] flex my-[3vw]" ref={ref}>
                {confirmModalOpen && (
                    <ConfirmModal
                        title={favoriteItemTranslateFunction('confirm_delete_modal_text')}
                        focusKey="CONFIRM-DELETE-MODAL"
                        opened={confirmModalOpen}
                        close={() => setConfirmModalOpen(false)}
                        modalAnswerFunction={handleDeleteFavoriteItem}
                    />
                )}
                <div className="flex">
                    <img className="w-[14vw] ml-[2vw] object-cover" src={item.icon} alt="" />
                    <div className="flex flex-col justify-evenly">
                        <div className="flex flex-col">
                            <span className="text-[1.8vw]">{item.name}</span>
                            <span className="text-[1.4vw]">{item.description}</span>
                        </div>
                        <RedButton
                            onClick={() => handlePurchaseFavoriteItem()}
                            focusKey={`${item.name}-order-button`}
                            className="w-[10vw]"
                        >
                            {favoriteItemTranslateFunction('order_button')}
                        </RedButton>
                    </div>
                </div>
                <div className="absolute left-[10%] top-[60%]">
                    <TransparentButton
                        className="w-[10vw]"
                        onClick={() => {
                            setConfirmModalOpen(true);
                            setFocus('CONFIRM-DELETE-MODAL');
                        }}
                    >
                        {favoriteItemTranslateFunction('remove_button')}
                    </TransparentButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default FavoriteItem;
