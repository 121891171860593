import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../lib/axios';
import { AxiosResponse } from 'axios';
import { getAccessToken } from '../../lib/auth';

export type BranchTimeRange = {
    start: string;
    end: string;
};

export type AtmosDayHours = {
    active: boolean;
    all_day: boolean;
    times: BranchTimeRange[];
};

export type BranchesOperationHours = {
    always_open: boolean;
    days: {
        sunday: AtmosDayHours;
        monday: AtmosDayHours;
        tuesday: AtmosDayHours;
        wednesday: AtmosDayHours;
        thursday: AtmosDayHours;
        friday: AtmosDayHours;
        saturday: AtmosDayHours;
    };
};

export type WorkingHoursProperties = {
    branch_id: number;
    delivery: BranchesOperationHours;
    pickup: BranchesOperationHours;
    restaurant: BranchesOperationHours;
};

export type AtmosDataProperties = {
    name: string;
    name_en: string;
    city: string;
    city_en: string;
    kosher: boolean;
    working_hours: WorkingHoursProperties;
};

export type BranchProperties = {
    _id: string;
    external_branch_id: number;
    properties: {
        name: string;
        description: string;
        store: string;
        delivery_charge: string;
        minimum_order: string;
        ignore: string;
    };
    atmos_data: AtmosDataProperties;
};

type InitialState = {
    loading: boolean;
    error: string | null;
    branchesList: BranchProperties[];
};
const initialState: InitialState = {
    loading: false,
    error: null,
    branchesList: [],
};

export const fetchBranches = createAsyncThunk<any>('branches/fetchBranches', async () => {
    const data = await api.get('/branches_list', {
        headers: {
            Authorization: `Bearer ${getAccessToken()?.access_token}`,
        },
    });

    return data;
});

function sortStoresByName(data: BranchProperties[]): BranchProperties[] {
    return data.sort((a, b) => {
        const nameA = a.atmos_data.name.toLowerCase();
        const nameB = b.atmos_data.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

const branchesSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBranches.pending, (state) => {
                console.log('pending');
                state.loading = true;
            })
            .addCase(fetchBranches.fulfilled, (state, action) => {
                console.log('fulfilled, action:', action);
                state.loading = false;
                state.branchesList = sortStoresByName(action.payload.data);
                state.error = null;
            })
            .addCase(fetchBranches.rejected, (state, action) => {
                console.log('rejected, action:', action);
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch orders';
            });
    },
});
export default branchesSlice.reducer;
