import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './resources/en-US';
import heIL from './resources/he-IL';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: 'en-US',
        fallbackLng: 'he-IL',
        detection: {
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],
        },
        debug: true,
        resources: {
            'en-US': {
                translation: enUS,
            },
            'he-IL': {
                translation: heIL,
            },
        },
        interpolation: {
            escapeValue: false, // React already does escaping
        },
        // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
        // set returnNull to false (and also in the i18next.d.ts options)
        // returnNull: false,
    });

export default i18next;
