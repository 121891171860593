import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useSelector } from '../../../../redux/store';
import {
    UpdateDeliveryFormData,
    UpdateDeliveryFormDataType,
} from '../../../../redux/slices/deliveryFormSlice';
import { useDispatch } from 'react-redux';
import DeliveryInputs from './DeliveryInputs';
import PickupInputs from './PickupInputs';
import ChooseTimeOfOrder from './ChooseTimeOfOrder';
import OrderNotesInput from './OrderNotesInput';
import DeliveryOrPickup from './DeliveryOrPickup';
import BackButton from '../../../../components/Buttons/BackButton';
import { useNavigate } from 'react-router-dom';

type DeliveryFormInputsProps = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
};
const DeliveryFormInputs = ({ focusKey: focusKeyParam, onRowFocus }: DeliveryFormInputsProps) => {
    const navigate = useNavigate();
    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);
    const isDeliveryOrPickup = deliveryFormData.order_type === 0 ? 'Delivery' : 'Pickup';

    const dispatch = useDispatch();
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const handleDeliveryFormUpdation = (updates: UpdateDeliveryFormDataType) => {
        dispatch(UpdateDeliveryFormData(updates));
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col mb-[2vw]" ref={ref}>
                <BackButton
                    className="py-[0.6vw] bg-transparent-card"
                    onClick={() => navigate('/')}
                    onBtnFocus={onRowFocus}
                />
                <DeliveryOrPickup onRowFocus={onRowFocus} focusKey="delivery-or-pickup" />
                <div className="flex flex-col">
                    {isDeliveryOrPickup === 'Delivery' ? (
                        <DeliveryInputs
                            focusKey="DELIVERY-INPUTS"
                            onRowFocus={onRowFocus}
                            handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        />
                    ) : (
                        <PickupInputs
                            focusKey="PICKUP-INPUTS"
                            onRowFocus={onRowFocus}
                            handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        />
                    )}
                    <ChooseTimeOfOrder
                        focusKey="CHOOSE-TIME-OF-ORDER"
                        handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                    />
                    <OrderNotesInput
                        onRowFocus={onRowFocus}
                        focusKey="CHECKOUT-NOTE-INPUT-CONTAINER"
                    />
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default DeliveryFormInputs;
