import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { MenuRow } from './MenuRow/MenuRow';
import { PastOrdersRow } from './PastOrders/PastOrderRow/PastOrdersRow';
import { useSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import { Header } from '../../components/Header';
import createTranslateFunction from '../../i18n/createTranslateFunction';
// import useScrollSensor from '../../helpers/webOS/useScrollSensor';

type HomeScreenProps = {
    focusKey: string;
};

export const HomeScreen = ({ focusKey: focusKeyParam }: HomeScreenProps) => {
    const TranslateFunction = createTranslateFunction('home_screen');
    const dispatch = useDispatch();
    const location = useLocation();
    // const { shouldScrollLeft, shouldScrollRight } = useScrollSensor(200, 100);

    const userAuth = useSelector((state) => state.userAuth);
    const userCart = useSelector((state) => state.cart);

    const navigate = useNavigate();

    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'MENU-ROW',
    });

    const onRowFocus = useCallback(
        ({ y }) => {
            console.log(y);
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/faded3.jpg',
                'bg-no-repeat bg-cover bg-center',
            ]),
        );
        if (!userAuth.user?.auth) {
            navigate('/welcome');
        }
    }, []);
    useEffect(() => {
        if (location.pathname === '/') {
            const handleBackButton = (event) => {
                event.preventDefault();
                const confirmExit = window.confirm('Do you want to leave the app?');
                if (confirmExit) {
                    window.webOS.platformBack();
                }
            };
            window.addEventListener('popstate', handleBackButton);

            return () => {
                window.removeEventListener('popstate', handleBackButton);
            };
        }
    }, [location]);
    return (
        <FocusContext.Provider value={focusKey}>
            <div className={`h-full w-full flex flex-col justify-between pr-[2.5vw] pb-0`}>
                {/* <ChooseDeliveryTypeComponent/> */}
                <Header />
                <div className="w-[100%] h-[20vw] flex flex-col justify-center mt-[3vw] mb-[3vw]">
                    <span className="text-[4.6vw] font-bold mb-[1vw]">{`${TranslateFunction(`title`, { name: userAuth.user?.first_name })}`}</span>
                    {userCart.cart.length > 0 && (
                        <span className="text-[3.7vw] w-[50%] mb-[1vw]">
                            {`${TranslateFunction(`sub_title_withcart_item`)}`}
                        </span>
                    )}
                    {userCart.cart.length < 1 && (
                        <span className="text-[2.3vw] w-[50%] mb-[1vw]">
                            {`${TranslateFunction(`sub_title`)}`}
                        </span>
                    )}
                </div>
                <div ref={ref} className="shrink h-[42vw] grow overflow-y-auto overflow-x-hidden">
                    <MenuRow
                        focusKey="MENU-ROW"
                        title={`${TranslateFunction(`menu_row_title`)}`}
                        onFocus={onRowFocus}
                    />
                    <PastOrdersRow
                        focusKey="PAST-ORDERS-ROW"
                        title={`${TranslateFunction(`past_orders_row_title`)}`}
                        onFocus={onRowFocus}
                    />
                </div>
            </div>
        </FocusContext.Provider>
    );
};
