import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AtmosDataProperties } from '../../redux/slices/branchesSlice';

type BranchCardProps = {
    branchData: AtmosDataProperties;
    branchId: number;
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
};
const BranchCard = ({
    branchData,
    branchId,
    focusKey: focusKeyParam,
    onRowFocus,
}: BranchCardProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const navigateToBranchPage = () => {
        navigate(`/chosen-branch`, { state: { chosenBranchData: branchData, branchId: branchId } });
    };
    const { ref, focused, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: handleFocus,
        onEnterRelease: navigateToBranchPage,
    });

    function handleFocus(layout: FocusableComponentLayout, props: unknown, details: FocusDetails) {
        onRowFocus(layout, props, details);
    }

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={navigateToBranchPage}
                ref={ref}
                className={`bg-gray-card cursor-pointer bg-opacity-60 p-[1vw] my-[1vw] !h-[6vw] w-[40vw] text-center rounded-full ${(focused || isHovered) && 'border-2 border-white bg-gray-card bg-opacity-80 scale-105'}`}
            >
                <span className="text-[2vw]">{`${branchData.name}`}</span>
            </div>
        </FocusContext.Provider>
    );
};

export default BranchCard;
