import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput';
import { AppDispatch, useSelector } from '../../redux/store';
import { Item } from '../../redux/slices/dbFoodMenuSlice';
import { ItemCard } from '../HomeScreen/MenuRow/ItemCard';
import useSpeechRecognition from '../../components/SpeechToText/OLDuseSpeechRecognition';
import SpeechToTextButton from '../../components/SpeechToText/OLDSpeechToTextButton';
import { useDispatch } from 'react-redux';
import { textToSpeechMelingoQuery } from '../../redux/slices/textToSpeechSlice';
import { Loading } from '../../components/Loading';
import { ConfirmTextToSpeechItemModal } from '../../components/ConfirmModal/TextToSpeechModal/ConfirmTextToSpeechItemModal';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { addToCart, CartItem } from '../../redux/slices/cartSlice';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';

type SearchScreenProps = {
    focusKey: string;
};

export const SearchScreen = ({ focusKey: focusKeyParam }: SearchScreenProps) => {
    const TranslateFunction = createTranslateFunction('search_screen');
    const dispatch = useDispatch<AppDispatch>();
    const textToSpeechData = useSelector((state) => state.textToSpeech);
    const [voiceRecognitionText, setVoiceRecognitionText] = useState<string>('');
    const { hasRecognitionSupport } = useSpeechRecognition();
    const allMenu = useSelector((state) => state.dbFoodMenu.categories);
    const [allMenuItems] = useState(
        allMenu
            .map((menuCategory) => {
                if (menuCategory.items.length > 0) {
                    return menuCategory.items;
                } else {
                    return menuCategory.sub_categories.map((subCategory) => subCategory.items);
                }
            })
            .flat(2),
    );
    const [filteredMenuItems, setFilteredMenuItems] = useState(allMenuItems);
    const [searchTerm, setSearchTerm] = useState('');
    const [isTextToSpeechModalOpen, setIsTextToSpeechModalOpen] = useState<boolean>(false);
    const [results, setResults] = useState<Item[]>([]);
    // console.log(allMenuItems, 'These are menu items after filter');
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    const handleInputChange = (value: string) => {
        if (value.length < 34) {
            setSearchTerm(value);
        }
    };

    const handleTextToSpeechModalConfirm = (cart: CartItem[]) => {
        console.log('Item has been added to the cart');
        for (const cartItem of cart) {
            dispatch(addToCart(cartItem));
        }
    };

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-center',
            ]),
        );
    }, []);

    useEffect(() => {
        setFilteredMenuItems(
            allMenuItems.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
        );
    }, [searchTerm]);

    useEffect(() => {
        setResults(filteredMenuItems.slice(0, 10));
    }, [filteredMenuItems]);

    useEffect(() => {
        if (voiceRecognitionText.length > 0) {
            // setSearchTerm(voiceRecognitionText);
            dispatch(textToSpeechMelingoQuery(voiceRecognitionText));
        }
    }, [voiceRecognitionText]);

    useEffect(() => {
        console.log(
            textToSpeechData?.currentQuery,
            'This is the data from the speech to text functions',
        );
        if (textToSpeechData.currentQuery?.reply?.items?.length > 0) {
            setIsTextToSpeechModalOpen(true);
            setFocus('CONFIRM-TEXT-TO-SPEECH-MODAL');
        }
    }, [textToSpeechData]);

    return (
        <FocusContext.Provider value={focusKey}>
            {textToSpeechData.loading ? (
                <Loading />
            ) : (
                <div ref={ref} className="w-full flex flex-col items-center">
                    <h1 className="text-white text-center text-[2.6vw] font-semibold mt-[3vw] mb-[2.4vw]">
                        {`${TranslateFunction(`header`)}`}
                    </h1>
                    {/*
                    
                    */}
                    <div className="flex w-[40vw] justify-between">
                        {/*
                        {hasRecognitionSupport && (
                            <div className="flex">
                                <SpeechToTextButton
                                    focusKey="SPEECH-TO-TEXT-BUTTON"
                                    setSpeechAnswerString={(transcript) => {
                                        console.log(' hasRecognitionSupport transcript', transcript);
                                        console.log('hasRecognitionSupport', hasRecognitionSupport);
                                        
                                        setVoiceRecognitionText(transcript);
                                    }}
                                />
                            </div>
                        )}

                         */}

                        <SearchInput
                            classNameParams="!w-[38vw] h-[5vw] text-[2vw]"
                            onChange={handleInputChange}
                            inputValue={searchTerm}
                            placeHolderValue={`${TranslateFunction(`input_placeholder`)}`}
                        />
                    </div>

                    {isTextToSpeechModalOpen && (
                        <ConfirmTextToSpeechItemModal
                            opened={isTextToSpeechModalOpen}
                            close={() => setIsTextToSpeechModalOpen(false)}
                            title={`תוצאות עבור: ${textToSpeechData.currentQuery?.query}`}
                            focusKey="CONFIRM-TEXT-TO-SPEECH-MODAL"
                            modalAnswerFunction={(cart) => handleTextToSpeechModalConfirm(cart)}
                            allMenuItems={allMenuItems}
                        />
                    )}

                    {results.length > 0 && (
                        <div className="flex flex-1 flex-col overflow-hidden w-[60%] items-center mt-8">
                            <div className="flex overflow-x-auto flex-wrap gap-8 justify-center">
                                {results.map((result, index) => (
                                    <ItemCard
                                        focusKey={`${focusKey}-${index}`}
                                        key={index}
                                        item={result}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </FocusContext.Provider>
    );
};
