import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import PersonalAssistantLogo from '../../PersonalAssistantLogo';
import { useSelector } from '../../../../redux/store';
import { CategoryCard } from './CategoryCard';
import { useEffect, useState } from 'react';
import useMelingoQuery from '../useMelingoQuery';
import { MenuCategory } from '../../../../redux/slices/dbFoodMenuSlice';
import useSpeechRecognition from '../../../../components/SpeechToText/useSpeechRecognition';
import SpeechToTextButton from '../../../../components/SpeechToText/SpeechToTextButton';
import {
    UpdateChooseCategoryQuery,
    UpdateChosenCategory,
} from '../../../../redux/slices/personalAssistantSlice';
import { useDispatch } from 'react-redux';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';

type ChooseCategoryProps = {
    focusKey: string;
    setChooseCategoryKey: React.Dispatch<React.SetStateAction<number>>;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
};
const ChooseCategory = ({
    focusKey: focusKeyParam,
    setChooseCategoryKey,
    handleErrorMessageFromMelingo,
}: ChooseCategoryProps) => {
    const { isMelingoLoading, melingoData, melingoError, fetchMelingoData } = useMelingoQuery();
    const { assistantStep, proccessFinished, chosenCategoryQuery } = useSelector(
        (state) => state.personalAssistant,
    );
    const [categoryTypeFromUserInput, setCategoryTypeFromUserInput] = useState<MenuCategory | null>(
        null,
    );
    const {
        isListening,
        startListening,
        stopListening,
        voiceRecognitionText,
        setVoiceRecognitionText,
    } = useSpeechRecognition();
    const menuCategories = useSelector((state) => state.dbFoodMenu.categories);
    const dispatch = useDispatch();
    const categoryTranslate = createTranslateFunction('personal_assistant_screen.choose_category');
    const [currentFocusIndex, setCurrentFocusIndex] = useState<number>(0);
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: `STT-CHOOSE-CATEGORY-BUTTON`,
    });

    const findWhatIsTheCategory = (categoryType: string) => {
        return menuCategories.find((category) => category.name.includes(categoryType));
    };

    const handleReceivedCategoryDataFromMelingo = () => {
        if (
            !melingoData ||
            !melingoData.data ||
            !melingoData.data.reply ||
            !melingoData.data.reply.items
        ) {
            console.log('No data received from Melingo');
            return;
        }
        const melingoAnswerType = melingoData.data.reply.items[0].Category;
        const whatIsTheCategory = findWhatIsTheCategory(melingoAnswerType);

        if (whatIsTheCategory) {
            setCategoryTypeFromUserInput(whatIsTheCategory);
            dispatch(UpdateChosenCategory(whatIsTheCategory));
        } else {
            handleErrorMessageFromMelingo(
                `לא מצאנו את הקטגוריה "${chosenCategoryQuery}", בואו ננסה שוב`,
            );
            console.log('Could not find any matching category');
            setChooseCategoryKey((prev) => prev + 1);
        }
    };

    const getCategoryHeader = () => {
        return proccessFinished
            ? categoryTranslate('already_ordered_header')
            : categoryTranslate('header');
    };

    // Debounce timeout
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (voiceRecognitionText.length > 0) {
            // Clear any existing timeout
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            // Set a new timeout to process the voice input after a delay
            const timeout = setTimeout(() => {
                console.log('Processing voice input');
                dispatch(UpdateChooseCategoryQuery(voiceRecognitionText));
                console.log('Sending to Melingo ', voiceRecognitionText);
                fetchMelingoData(voiceRecognitionText);
                setVoiceRecognitionText(''); // Reset the text after processing
            }, 1000); // Adjust the delay as needed (1000 ms = 1 second)

            setDebounceTimeout(timeout);
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
        };
    }, [voiceRecognitionText]);

    useEffect(() => {
        if (melingoData && melingoData.data) {
            setVoiceRecognitionText('');
            handleReceivedCategoryDataFromMelingo();
        }
    }, [melingoData]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                className={`flex flex-col animate-slowFadeIn items-start justify-center w-full pr-[4vw] my-[2vw] ${assistantStep === `chooseCategory` ? `` : `pointer-events-none`}`}
            >
                <div className="flex relative mb-[2vw]">
                    <PersonalAssistantLogo />
                    <span className="text-[2vw]">{getCategoryHeader()}</span>
                </div>

                <div ref={ref} className="flex">
                    <div className="mt-[2.5vw] ml-[2vw]">
                        <SpeechToTextButton
                            focusKey="STT-CHOOSE-CATEGORY-BUTTON"
                            isListening={isListening}
                            startClickFunction={startListening}
                            stopClickFunction={stopListening}
                        />
                    </div>
                    <div className="flex flex-wrap items-center justify-start w-[70vw] px-[0.5vw]">
                        {menuCategories.map((category, index) => {
                            return (
                                <CategoryCard
                                    categoryData={category}
                                    setCurrentFocusIndex={setCurrentFocusIndex}
                                    componentIndex={index}
                                    key={`category-card-${index}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseCategory;
