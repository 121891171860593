import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';
import { Sauce } from '../../redux/slices/dbFoodMenuSlice';

type FoodToppingPortionButtonProps = {
    sauce: Sauce;
    sauceOperation: string;
    focusKey: string;
    saucesArray: Sauce[];
    changeSauceQuantity: (sauceName: string, sauceQuantity: number) => void;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};

const SaladSauceIncrementButton = ({
    sauce,
    sauceOperation,
    saucesArray,
    changeSauceQuantity,
    onFocus,
    focusKey: focusKeyParam,
}: FoodToppingPortionButtonProps) => {
    const handleToppingPortionClick = () => {
        let sauceQuantity = sauce.sauceQuantity;
        if (!sauceQuantity) {
            sauceQuantity = 0;
        }
        if (sauceOperation === 'increment') {
            changeSauceQuantity(sauce.name, sauceQuantity + 1);
        } else if (sauceOperation === 'decrement' && sauceQuantity > 0) {
            changeSauceQuantity(sauce.name, sauceQuantity - 1);
        }
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
        onEnterPress: handleToppingPortionClick,
    });

    const ButtonCssValue = `w-[2.5vw] h-[2.5vw] text-[1.1vw] bg-food-portion rounded-full flex justify-center items-center ${focused && '!bg-selected-hut-red'}`;

    return (
        <div ref={ref} onClick={handleToppingPortionClick}>
            {sauceOperation === 'increment' && (
                <button className={`${ButtonCssValue}`} ref={ref}>
                    {`+`}
                </button>
            )}
            {sauceOperation === 'decrement' && (
                <button className={`${ButtonCssValue}`} ref={ref}>
                    {`-`}
                </button>
            )}
        </div>
    );
};

export default SaladSauceIncrementButton;
