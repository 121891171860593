import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Extra } from '../../../../redux/slices/dbFoodMenuSlice';
import { useSelector } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { AddExtraToChosenExtras } from '../../../../redux/slices/personalAssistantSlice';

type ExtraCardProps = {
    extraData: Extra;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
};

export function ExtraCard({
    extraData,
    setCurrentFocusIndex,
    componentIndex,
    handleErrorMessageFromMelingo,
}: ExtraCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const [isExtraSelected, setIsExtraSelected] = useState(false);
    const dispatch = useDispatch();
    const { chosenExtras } = useSelector((state) => state.personalAssistant);

    const imageUrlBaseLink = `https://pizzahut-assets.s3.amazonaws.com/icons/salad_extras_icons/${extraData.makat}.png`;

    const handleExtraCardClick = () => {
        if (chosenExtras.length > 7 && !isExtraSelected) {
            handleErrorMessageFromMelingo(`לא ניתן להוסיף יותר מ8 תוספות לצד`);
            return;
        }
        setIsHovered(false);
        dispatch(AddExtraToChosenExtras(extraData));
    };
    const { ref, focused, hasFocusedChild, focusKey } = useFocusable({
        onEnterPress: handleExtraCardClick,
        trackChildren: true,
    });
    const removeToppingInfoFromString = (input: string): string => {
        const formattedString = input.replace('ת. סלט', '');
        return formattedString;
    };

    const isExtraSelectedFunction = () => {
        if (!chosenExtras) {
            return false;
        }
        const isCurrentExtraSelected = chosenExtras.find(
            (extra) => extra.makat === extraData.makat,
        );
        if (isCurrentExtraSelected) {
            setIsExtraSelected(true);
        } else {
            setIsExtraSelected(false);
        }
    };

    useEffect(() => {
        if (focused || isHovered) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused, isHovered]);

    useEffect(() => {
        isExtraSelectedFunction();
    }, [chosenExtras]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                onClick={handleExtraCardClick}
                className={`w-[13vw] h-[11.5vw] transition-all cursor-pointer flex flex-col items-center justify-evenly rounded-categoryRadius bg-[#2a2a2a] ${focused && 'bg-gray-card border-white border-[0.1vw] scale-105'}
                ${isExtraSelected && 'bg-selected-hut-red !bg-opacity-100'}`}
            >
                <img className="w-[5vw] h-[5vw] object-cover" src={imageUrlBaseLink} alt="" />
                <span className="text-[1.5vw]">{`${removeToppingInfoFromString(extraData.name)}`}</span>
            </div>
        </FocusContext.Provider>
    );
}
