import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect, useRef, useState } from 'react';

type inputProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    placeholderValue?: string;
    focusKey?: string;
    setStateValue?: React.Dispatch<React.SetStateAction<string>>;
    className?: string;
    onFocusFunction?: string; //Function or a class to be called on focus.
    isInputOpen?: boolean;
    handleNoteChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleScrollToTop?: () => void;
};
const TransperentInput = ({
    placeholderValue,
    focusKey: focusKeyParam,
    setStateValue,
    className,
    onFocusFunction,
    isInputOpen,
    handleNoteChange,
    handleScrollToTop,
}: inputProps) => {
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const { ref, focused, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: () => inputRef.current?.focus(),
        onBlur: () => inputRef.current?.blur(),
        onEnterPress: () => setIsReadOnly(!isReadOnly),
    });

    const isReadOnlyFunction = () => {
        if (isInputOpen) {
            return false;
        } else {
            return isReadOnly;
        }
    };

    useEffect(() => {
        if (handleScrollToTop) {
            handleScrollToTop();
        }
    }, [focused]);
    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={`${focused && `${onFocusFunction}`}`}>
                <input
                    onClick={() => setIsReadOnly(false)}
                    placeholder={`${placeholderValue}`}
                    onChange={(e) =>
                        handleNoteChange ? handleNoteChange(e) : setStateValue!(e.target.value)
                    }
                    ref={inputRef}
                    readOnly={isReadOnlyFunction()}
                    className={`w-[25vw] h-[4vw] bg-transparent outline-none rounded-itemRadius text-center my-4 text-gray-300 text-[1vw] border-gray-400 border-2 ${className}
           ${focused && 'scale-105 !border-5 !border-white'}
          `}
                />
            </div>
        </FocusContext.Provider>
    );
};

export default TransperentInput;
