import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import TransparentButton from '../../components/Buttons/TransparentButton';
import { useNavigate } from 'react-router';
import QRCode from 'react-qr-code';
import { fetchMe, getACode } from '../../redux/slices/userAuthSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../redux/store';
import { env } from '../../config/environment';
import { useTranslation } from 'react-i18next';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import BackButton from '../../components/Buttons/BackButton';
import { Loading } from '../../components/Loading';
import FocusableOTPInput from '../../components/OTPInputs/FocusableOTPInput';

type RegisterScreenProps = {
    focusKey: string;
};
const RegisterScreen = ({ focusKey: focusKeyParam }: RegisterScreenProps) => {
    const TranslateFunction = createTranslateFunction('register');
    const { t: GeneralTranslateFunction } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { user, token, error } = useSelector((state) => state.userAuth);

    const [passedSections, setPassedSections] = useState<boolean[]>([false, false]);
    const [registerOneTimePassword, setRegisterOneTimePassword] = useState<string>('');
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'first-register-step-button',
    });
    const changePassedSections = (index: number) => {
        const newPassedSections = [...passedSections];
        newPassedSections[index] = true;
        setPassedSections(newPassedSections);
    };

    function navigateBasedOnSections(): void {
        const passedSectionsTemp = [...passedSections];
        if (passedSectionsTemp[0] === false) {
            navigate('/welcome');
        } else if (passedSectionsTemp[0] === true) {
            setPassedSections([false, false]);
        }
    }
    const submitOtpFunction = async () => {
        setIsLoading(true);
        await dispatch(getACode(registerOneTimePassword) as any);
        setIsLoading(false);
    };

    useEffect(() => {
        setFocus(focusKey);
    }, []);

    useEffect(() => {
        if (user && token) {
            setPassedSections([true, true]);
            dispatch(fetchMe());
            setTimeout(() => {
                navigate('/');
            }, 1000);
        }
    }, [token, user]);

    // Show the submit button only when the user has entered the OTP fully.
    useEffect(() => {
        if (registerOneTimePassword.length > 3) {
            setFocus('confirm-register-otp-button');
        }
    }, [registerOneTimePassword]);

    useEffect(() => {
        if (error) {
            alert('קרתה בעיה, נא הקש קוד מחדש');
            setRegisterOneTimePassword('');
        }
    }, [error]);

    const QRCodeData = env.QR_CODE_DATA;

    return (
        <FocusContext.Provider value={focusKey}>
            {isLoading ? (
                <Loading />
            ) : (
                <div ref={ref} className="flex justify-center bg-black items-center h-full w-full">
                    <img
                        className="absolute h-[6vw] bottom-[3vw] right-[3vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/pizzahut_logo.svg"
                        alt="Pizzahut Logo"
                    />
                    <div className="z-[0] absolute bg-red-radial-gradient bg-opacity-60 rounded-full top-[-40vw] right-[-40vw] h-[80vw] w-[80vw]"></div>
                    <div className="flex flex-col items-start justify-evenly w-[40%] h-[80%] z-[1]">
                        <BackButton onClick={navigateBasedOnSections} />
                        <div className={`flex flex-col justify-evenly h-[40%]`}>
                            <span
                                className={`text-[2vw]`}
                            >{`${TranslateFunction(`step_one.title`)}`}</span>
                            <span className="text-[1.2vw] max-w-[60%]">{`${TranslateFunction(`step_one.description`)}`}</span>
                            {!passedSections[0] && (
                                <TransparentButton
                                    focusKey="first-register-step-button"
                                    onClick={() => changePassedSections(0)}
                                    className="w-[10vw]"
                                >{`${TranslateFunction(`step_one.next_step_button`)}`}</TransparentButton>
                            )}
                        </div>
                        <div
                            className={`flex flex-col justify-evenly h-[20%] opacity-20 ${passedSections[0] && `!opacity-100`}`}
                        >
                            <span
                                className={`text-[2vw]`}
                            >{`${TranslateFunction(`step_two.title`)}`}</span>
                            <span className="text-[1.2vw] max-w-[60%]">{`${TranslateFunction(`step_two.description`)}`}</span>
                        </div>
                        <div
                            className={`flex flex-col justify-evenly h-[20%] opacity-20 ${passedSections[1] && `!opacity-100`}`}
                        >
                            <span
                                className={`text-[2vw]`}
                            >{`${TranslateFunction(`step_three.title`)}`}</span>
                            <span className="text-[1.2vw] max-w-[60%]">{`${TranslateFunction(`step_three.description`)}`}</span>
                        </div>
                    </div>
                    {!passedSections[0] ? (
                        <div className='relative mt-[8vw] bg-[url("https://pizzahut-assets.s3.amazonaws.com/icons/assets/phone-scanning-qr.png")] z-1 w-[34vw] h-[45vw] bg-contain bg-no-repeat'>
                            <QRCode
                                className="w-[14vw] h-[14vw] absolute top-[30%] left-[16%] border-white !z-10"
                                value={`${QRCodeData}`}
                            />
                        </div>
                    ) : null}
                    {passedSections[0] ? (
                        <div className="w-[30%] h-[80%] flex flex-col justify-center items-center">
                            <div className="flex flex-col items-center mb-[3vw]">
                                <span
                                    className={`text-[2vw]`}
                                >{`${TranslateFunction(`write_a_code`)}`}</span>
                                <span className="text-[1.2vw]">{`${TranslateFunction(`write_a_code_description`)}`}</span>
                            </div>
                            <div ref={ref} className="flex justify-center w-[90%] mb-[3vw]">
                                <FocusableOTPInput
                                    focusKey="login-verify-personal-otp-input"
                                    OTPLength={4}
                                    setOTPValue={setRegisterOneTimePassword}
                                />
                            </div>
                            {registerOneTimePassword.length > 3 && (
                                <TransparentButton
                                    focusKey="confirm-register-otp-button"
                                    onClick={() => submitOtpFunction()}
                                    className="w-[10vw]"
                                >{`${GeneralTranslateFunction(`buttons.confirm`)}`}</TransparentButton>
                            )}
                        </div>
                    ) : null}
                </div>
            )}
        </FocusContext.Provider>
    );
};

export default RegisterScreen;
