import { useEffect } from 'react';

type UserInputTextRenderProps = {
    textToDisplay: string;
    className?: string;
    onRowFocus?: () => void;
};
const UserInputTextRender = ({
    textToDisplay,
    className,
    onRowFocus,
}: UserInputTextRenderProps) => {
    useEffect(() => {
        if (onRowFocus) {
            onRowFocus();
        }
    }, []);
    return (
        <div
            className={`w-full my-[4vw] animate-slowFadeIn flex justify-end items-center pl-[10%] ${className}`}
        >
            <div className="relative">
                <p className="text-[1.5vw] text-center max-w-[20vw]">{textToDisplay}</p>
                <div
                    className={`w-[3.5vw] h-[3.5vw] pl-[0.2vw] absolute top-[-3vw] left-[-3vw] flex justify-center items-center bg-cover bg-[url("https://pizzahut-assets.s3.amazonaws.com/icons/assets/TalkingBubbleIcon.svg")]`}
                ></div>
            </div>
        </div>
    );
};

export default UserInputTextRender;
