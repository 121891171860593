import createTranslateFunction from '../../i18n/createTranslateFunction';
import { CartItem } from '../../redux/slices/cartSlice';
import { Extra, ExtraSizesType } from '../../redux/slices/dbFoodMenuSlice';

const editFoodScreenTranslateFunction = createTranslateFunction('edit_food_screen.personalize');

// ${topping.extraSize === 'full' && `top-[4.3vw] right-[4vw]`} ${topping.extraSize === 'halfleft' && `!w-[12vw] !h-[22vw] top-[2.3vw] left-[1.2vw]`}
// ${topping.extraSize === 'halfright' && `!w-[12vw] !h-[22vw] top-[2.3vw] right-[1.2vw]`}

const menuItemCssConfig = {
    'PAN פיצה S': {
        full: 'top-[5.8vw] right-[5.5vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    'PAN פיצה L': {
        full: 'top-[5.8vw] right-[5.5vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    'PAN פיצה XL': {
        full: 'top-[5.8vw] right-[5.5vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    'קלאסית דקה S': {
        full: '!w-[16vw] !h-[16vw] top-[5.4vw] right-[4.9vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    'קלאסית דקה L': {
        full: '!w-[16vw] !h-[16vw] top-[5.4vw] right-[4.9vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    'קלאסית דקה XL': {
        full: '!w-[16vw] !h-[16vw] top-[5.4vw] right-[4.9vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
    "צ'יזי בייטס": {
        full: '!w-[13vw] !h-[13vw] top-[6.8vw] right-[5.2vw]',
        halfleft: '!w-[9vw] !h-[16vw] top-[4.9vw] left-[4.9vw]',
        halfright: '!w-[9vw] !h-[16vw] top-[4.9vw] right-[3.2vw]',
    },
    "צ'יזי קראסט": {
        full: '!w-[14vw] !h-[14vw] top-[5vw] right-[8.2vw]',
        halfleft: '!w-[9vw] !h-[14vw] top-[5.2vw] left-[2vw]',
        halfright: '!w-[9vw] !h-[14vw] top-[5.2vw] right-[5.8vw]',
    },
    'קראון פיצה': {
        full: '!w-[12vw] !h-[12vw] top-[7.1vw] right-[6.8vw]',
        halfleft: '!w-[9vw] !h-[12vw] top-[6.7vw] left-[4.9vw]',
        halfright: '!w-[9vw] !h-[12vw] top-[6.7vw] right-[4.5vw]',
    },
    'פיצה L ללא גלוטן': {
        full: 'opacity-0',
        halfleft: 'opacity-0',
        halfright: 'opacity-0',
    },
    default: {
        full: 'top-[5.8vw] right-[5.5vw]',
        halfleft: '!w-[11vw] !h-[19vw] top-[4vw] left-[2.4vw]',
        halfright: '!w-[11vw] !h-[19vw] top-[4vw] right-[2.4vw]',
    },
};

export const getToppingExtraImageCss = (menuItemName: string, toppingSize: ExtraSizesType) => {
    const itemCss = menuItemCssConfig[`${menuItemName}`] ?? menuItemCssConfig['default'];
    return itemCss[toppingSize] || itemCss['full'];
};

export const calculatePizzaToppingsAmount = (toppingsArray: Extra[]): [number, number] => {
    return toppingsArray.reduce(
        (acc, curr) => {
            const [leftCounter, rightCounter] = acc;

            if (curr.extraSize === 'full') {
                return [leftCounter + 1, rightCounter + 1];
            } else if (curr.extraSize === 'halfleft') {
                return [leftCounter + 1, rightCounter];
            } else if (curr.extraSize === 'halfright') {
                return [leftCounter, rightCounter + 1];
            }

            return acc;
        },
        [0, 0],
    );
};

const preloadImagesFunction = (imageUrls) => {
    return Promise.all(
        imageUrls.map((url) => {
            return new Promise<void>((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve();
                img.onerror = (err) => {
                    console.error(`Failed to load image at URL: ${url}`, err);
                    reject(err);
                };
            });
        }),
    );
};

const getPreloadImagePaths = (toppingName: string): string[] => {
    const noEnglishChars = toppingName.replace(/[a-zA-Z]/g, '');
    const noSpaces = noEnglishChars.replace(/\s+/g, '_');
    const baseUrl = `https://pizzahut-assets.s3.amazonaws.com/icons/extras_icons/${noSpaces}`;
    if (noSpaces.charAt(noSpaces.length - 1) !== '_') {
        return [baseUrl + '_full.png', baseUrl + '_halfleft.png', baseUrl + '_halfright.png'];
    } else {
        return [baseUrl + 'full.png', baseUrl + 'halfleft.png', baseUrl + 'halfright.png'];
    }
};

export const handlePreloadImagesFunction = (
    currentItemObject: CartItem,
    setExtrasImagesLoaded: () => void,
) => {
    let imagePaths: any[] = [];
    for (const topping of currentItemObject.item.extras) {
        const currentToppingImagePaths = getPreloadImagePaths(topping.name);
        imagePaths = imagePaths.concat(currentToppingImagePaths);
    }

    preloadImagesFunction(imagePaths)
        .then(() => {
            setExtrasImagesLoaded();
            console.log('Images loaded');
        })
        .catch((err) => {
            console.error('Error preloading images:', err);
            setExtrasImagesLoaded();
        });
};
const maxToppingAmount = (categoryName: string) => {
    if (categoryName === 'פיצות') {
        return 3;
    } else if (categoryName === 'סלטים') {
        return 8;
    }
};

export const titleForAlertModal = (
    isMaxToppingsAlertModalOpen: boolean,
    notEnoughToppingsAlertModalOpen: boolean,
    categoryName: string,
): string => {
    if (isMaxToppingsAlertModalOpen) {
        // return editFoodScreenTranslateFunction('max_toppings_alert_title');
        return editFoodScreenTranslateFunction(`condition`, {
            count: maxToppingAmount(categoryName),
        });
    }
    if (notEnoughToppingsAlertModalOpen) {
        return editFoodScreenTranslateFunction('not_enough_toppings_alert_title');
    }
    return editFoodScreenTranslateFunction('general_alert_title');
};

export const calculateFinalPrice = (cartItem: CartItem): number => {
    let finalPrice = cartItem?.item?.price;
    for (const addedExtra of cartItem.extras) {
        finalPrice += addedExtra.price;
    }
    for (const addedSauce of cartItem.sauces) {
        finalPrice += addedSauce.price * addedSauce.sauceQuantity;
    }
    if (cartItem.sauces.length > 0) {
        finalPrice -= 1.5;
    }
    finalPrice = Math.round(finalPrice * 100) / 100;

    return finalPrice;
};

export const calculateTotalCartPrice = (cart: CartItem[], deliveryPrice: number): number => {
    let totalPrice = 0;
    cart.forEach((cartItem) => {
        totalPrice += cartItem.item.price;
        // Add extras price
        cartItem.extras.forEach((extra) => {
            totalPrice += extra.price;
        });
        if (cartItem.sauces.length > 0) {
            totalPrice -= 1.5;
            cartItem.sauces.forEach((sauces) => {
                totalPrice += sauces.price * sauces.sauceQuantity;
            });
        }
        totalPrice = Math.round(totalPrice * 100) / 100;
    });
    totalPrice += deliveryPrice || 0;
    return totalPrice;
};

export const calculateTotalCartSuggestionPrice = (cart: CartItem[]): number => {
    console.log(cart, 'This is the cart');

    let totalPrice = 0;
    cart.forEach((cartItem) => {
        totalPrice += cartItem.item.price;
        // Add extras price
        cartItem.extras.forEach((extra) => {
            totalPrice += extra.price;
        });
        if (cartItem.sauces.length > 0) {
            totalPrice -= 1.5;
            cartItem.sauces.forEach((sauces) => {
                totalPrice += sauces.price * sauces.sauceQuantity;
            });
        }
        totalPrice = Math.round(totalPrice * 100) / 100;
    });
    totalPrice;
    return totalPrice;
};

export const removeSizeLetterFromName = (itemName: string): string => {
    const sizeLetterRegex = /\s([LMSX]+)$/;
    return itemName.replace(sizeLetterRegex, '');
};

export const getImageUrl = (toppingName: string, toppingSize: string): string => {
    const baseUrl = 'https://pizzahut-assets.s3.amazonaws.com/icons/extras_icons';
    const imageName = fixToppingNameForImageImport(toppingName, toppingSize ?? 'full');
    return `${baseUrl}/${imageName}.png`;
};

export const fixToppingNameForImageImport = (toppingName: string, extraSize: string): string => {
    if (!toppingName) {
        return '';
    }
    const noEnglishChars = toppingName.replace(/[a-zA-Z]/g, '');
    const noSpaces = noEnglishChars.replace(/\s+/g, '_');
    if (noSpaces.charAt(noSpaces.length - 1) !== '_') {
        return noSpaces + '_' + extraSize;
    } else {
        return noSpaces + extraSize;
    }
};
