import React, { useEffect } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import TransparentButton from '../../components/Buttons/TransparentButton';
import QRCode from 'react-qr-code';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/Buttons/BackButton';
import { IconArrowBackUp } from '@tabler/icons-react';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../redux/store';

export const SettingsScreen = ({ focusKey: focusKeyParam }) => {
    const TranslateFunction = createTranslateFunction('settings_screen');
    const dispatch = useDispatch();
    const { t: GeneralTranslateFunction } = useTranslation();
    const navigate = useNavigate();
    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'RETURN-HOME-PAGE-BUTTON',
    });

    const userAuth = useSelector((state) => state.userAuth);

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );
        if (!userAuth.user?.auth) {
            navigate('/welcome');
        }
    }, []);

    const signOutFunction = () => {
        localStorage.clear();
        //set time out for one second and then navigate
        const timeout = setTimeout(() => {
            navigate('/welcome');
            location.reload();
        }, 1000);
        return () => clearTimeout(timeout);
    };
    return (
        <FocusContext.Provider value={focusKey}>
            <div
                className="h-full w-full flex flex-col justify-evenly items-center relative"
                ref={ref}
            >
                <TransparentButton
                    className="absolute flex justify-center items-center top-[3vw] right-[10vw] !text-[1.5vw] w-[10vw] h-[4vw]"
                    onClick={() => signOutFunction()}
                >
                    {`${GeneralTranslateFunction(`buttons.sign_out`)}`}
                </TransparentButton>
                <TransparentButton
                    className="absolute flex justify-center items-center top-[3vw] !w-[10vw] h-[4vw] right-[22vw] !text-[1.5vw]"
                    onClick={() => navigate('/')}
                >
                    <IconArrowBackUp size={`1.5vw`} className="-scale-x-100 ml-[0.5vw]" />
                    {`${GeneralTranslateFunction(`buttons.back`)}`}
                </TransparentButton>
                {/* <TransparentButton
                    focusKey="RETURN-HOME-PAGE-BUTTON"
                    className="text-[2vw] w-[16vw] h-[6vw]"
                    onClick={() => navigate('/')}
                >
                    {`${TranslateFunction(`main_page_button`)}`}
                </TransparentButton> */}
                <div className="flex flex-col items-center">
                    <span className="text-[1.8vw]">{`${TranslateFunction(`disclaimer`)}`}</span>
                    <span className="text-[1.8vw]">{`${TranslateFunction(`instruction`)}`}</span>
                </div>
                <div>
                    <QRCode
                        className="w-[16vw] h-[16vw]"
                        value={'https://mobile-dev-pizzahut.tlvtech.io/'}
                    ></QRCode>
                </div>
            </div>
        </FocusContext.Provider>
    );
};
