import React, { useRef, useCallback } from 'react';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import Topping from './Topping';
import { Extra } from '../../redux/slices/dbFoodMenuSlice';

type SaladToppingsListProps = {
    focusKey: string;
    toppings: Extra[];
    itemToppingsArray: Extra[];
    onSelectItemTopping: (topping: Extra) => void;
};

export const ToppingsList = ({
    focusKey: focusKeyParam,
    toppings,
    itemToppingsArray,
    onSelectItemTopping,
}: SaladToppingsListProps) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        trackChildren: true,
        preferredChildFocusKey: `extra-container-0`,
    });
    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ y }) => {
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const checkIfSelectedFunction = (topping: Extra) => {
        if (itemToppingsArray.find((itemTopping) => itemTopping.name === topping.name)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={'overflow-y-auto py-[0.5vw]'}>
                <div
                    className={`flex flex-row flex-wrap justify-center overflow-y-auto`}
                    ref={scrollingRef}
                >
                    {toppings.map((topping, index) => (
                        <Topping
                            key={index}
                            focusKey={`extra-container-${index}`}
                            topping={topping}
                            onSelectItemTopping={onSelectItemTopping}
                            isSelected={checkIfSelectedFunction(topping)}
                            onFocus={onAssetFocus}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
