import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useCallback, useRef } from 'react';
import { ItemCard } from '../../../screens/HomeScreen/MenuRow/ItemCard';
import { SuggestionItemCard } from '../../../screens/SearchScreen/SuggestionItemCard';

export const SuggestionsRow = ({ suggestions, focusKey: focusKeyParam }) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: `${focusKeyParam}-0`,
    });

    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (scrollingRef.current) {
                scrollingRef.current.scrollLeft = x;
                scrollingRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [scrollingRef],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="flex flex-col items-center my-[1.5vw]">
                <span
                    className={'text-white text-[1.8vw] font-bold my-[1.5vw]'}
                >{`מוצרים דומים:`}</span>

                <div className="flex items-center w-[80%] justify-evenly overflow-x-hidden text-white">
                    {suggestions.map((item, index) => (
                        <SuggestionItemCard
                            focusKey={`${focusKey}-${index}`}
                            onFocus={onAssetFocus}
                            key={index}
                            item={item}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
