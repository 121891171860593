import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { Extra } from '../../redux/slices/dbFoodMenuSlice';
import { useState } from 'react';

type SaladToppingProps = {
    focusKey: string;
    topping: Extra;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    onSelectItemTopping: (topping: Extra) => void;
    isSelected: boolean;
};
const Topping = ({
    focusKey: focusKeyParam,
    onFocus,
    topping,
    onSelectItemTopping,
    isSelected,
}: SaladToppingProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { ref, focused, focusKey } = useFocusable({
        onFocus,
        focusKey: focusKeyParam,
        onEnterPress: () => onSelectItemTopping(topping),
    });

    const fixToppingName = (toppingName: string) => {
        const regex = /ת\. סלט/g;
        return toppingName.replace(regex, '');
    };

    const toppingIconUrl = `https://pizzahut-assets.s3.amazonaws.com/icons/salad_extras_icons/${topping.makat}.png`;

    return (
        <div
            onClick={() => onSelectItemTopping(topping)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={ref}
            className={`bg-black cursor-pointer bg-opacity-20 m-[0.5vw] p-[0.6vw] h-[9.6vw] w-[9.6vw] flex flex-col justify-center items-center rounded-categoryRadius 
            ${(focused || isHovered) && 'bg-gray-card bg-opacity-60 scale-105'} ${isSelected && 'bg-selected-hut-red !bg-opacity-100'}`}
        >
            <img className="object-cover w-[4vw] h-[4vw]" src={toppingIconUrl} alt="" />
            <span className="text-[1.4vw] text-center">{`${fixToppingName(topping.name)}`}</span>
        </div>
    );
};

export default Topping;
