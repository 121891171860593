import createTranslateFunction from '../../i18n/createTranslateFunction';

type CartDeliveryPriceCardProps = {
    deliveryPrice: number;
};

const CartDeliveryPriceCard = ({ deliveryPrice }: CartDeliveryPriceCardProps) => {
    const GeneralTranslationFunction = createTranslateFunction('checkout_screen.cart_popup');
    return (
        <div>
            {deliveryPrice > 0 ? (
                <div className="w-full flex items-center justify-between ml-[1.2vw]">
                    <img
                        className="w-[4vw] h-[4vw] rounded-[1.4vw] object-contain"
                        src="https://www.svgrepo.com/show/396278/delivery-truck.svg"
                        alt="Delivery Icon"
                    />
                    <div className="flex flex-col flex-1 mx-[1vw]">
                        <div className="flex justify-between text-[1.2vw] font-bold">
                            <span className="text-[1.5vw]">{`עלות משלוח`}</span>
                            <span className="text-[1.5vw]">₪{deliveryPrice}</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <span className="text-[1.5vw]">{`${GeneralTranslationFunction(`no_delivery_cost`)}`}</span>
                </div>
            )}
        </div>
    );
};

export default CartDeliveryPriceCard;
