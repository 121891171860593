import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    focusKey?: string;
    onClick?: () => void;
    className?: string;
    focusClassName?: string;
    onBtnFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};
const TransparentButton = ({
    focusKey: focusKeyParam,
    children,
    onClick,
    className,
    focusClassName,
    onBtnFocus,
}: ButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { ref, focused, focusKey } = useFocusable({
        onFocus: onBtnFocus,
        onEnterPress: onClick,
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={onClick}
                ref={ref}
                className={`
                    bg-white bg-opacity-10 transition px-[1vw] py-[2vh] text-[1vw] text-white rounded-full outline-none ${className}
                    ${(focused || isHovered) && `bg-opacity-30 scale-105 ${focusClassName}`}
                `}
            >
                {children}
            </button>
        </FocusContext.Provider>
    );
};

export default TransparentButton;
