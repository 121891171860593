import { useFocusable, FocusContext, setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarItem } from './SidebarItem';
import { useDispatch } from 'react-redux';
import { toggleMenu, toggleMenuFalse, openMenu } from '../../redux/slices/menuSlice';
import { useSelector } from '../../redux/store';
import { SideMenuRow } from '../../screens/HomeScreen/MenuRow/SideMenuRow';
import { useState } from 'react';

type MenuItem = {
    label: string;
    icon: string;
};
type SidebarProps = {
    focusKey: string;
    menuItems: MenuItem[];
};

export function Sidebar({ focusKey: focusKeyParam, menuItems }: SidebarProps) {
    const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
    const allMenuItems = useSelector((state) => state.dbFoodMenu.categories);
    const [whatSidebarIsFocused, setWhatSidebarIsFocused] = useState<string>('');

    const location = useLocation();
    const dispatch = useDispatch();
    const { ref, hasFocusedChild, focusKey, focused } = useFocusable({
        focusable: true,
        trackChildren: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'מסך הבית-Sidebar',
        isFocusBoundary: true,
    });

    const navigate = useNavigate();

    /**
     * TODO:
     * NEED TO CHECK IF ALL THE FOCUS KEYS FROM EACH SCREEN ARE LISTED HERE
     * SO THAT WHEN THE USER NAVIGATES FROM SIDEBAR TO SCREEN,
     * THE FOCUS WILL BE SET TO THE CORRECT ITEM.
     */

    const pathnameToFocusKey = (pathname: string) => {
        if (pathname === '/track-order') return 'TRACK-ORDER-SCREEN';
        if (pathname === '/search') return 'SEARCH';
        if (pathname === '/branches') return 'BRANCHES';
        if (pathname === '/profile') return 'PROFILE';
        if (pathname === '/settings') return 'SETTINGS';
        if (pathname === '/favorite-items') return 'FAVORITE-ITEMS';
        if (pathname === '/editfoodscreen') return 'EDITFOODSCREEN';
        if (pathname === '/editsaladscreen') return 'EDITSALADSCREEN';
        if (pathname === '/checkout') return 'CHECKOUTSCREEN';
        if (pathname === '/restore-past-order') return 'RESTORE-PAST-ORDER';
        if (pathname.includes('/menu')) return 'CHOSENMENU';
        if (pathname === '/') return 'HOME';
        if (pathname === '/choose-payment') return 'CHOOSE-PAYMENT-SCREEN';
        if (pathname === '/personal-assistant') return 'PERSONAL-ASSISTANT-SCREEN';
        return '';
    };
    //A function that checks what is the focused item, and returns the according path and focusKey values in an object.
    const checkWhatIsFocused = (item: string) => {
        let path = '';
        let focusKey = '';
        switch (item) {
            case 'חיפוש':
                path = 'search';
                focusKey = 'SEARCH';
                break;
            case 'מסך הבית':
                // we don't set the path here because it's the default route
                focusKey = 'HOME';
                break;
            case 'תפריט':
                path = '';
                focusKey = 'FOODMENU';
                break;
            case 'סניפים':
                path = 'branches';
                focusKey = 'BRANCHES';
                break;
            case 'חשבון':
                path = 'profile';
                focusKey = 'PROFILE';
                break;
            case 'הגדרות':
                path = 'settings';
                focusKey = 'SETTINGS';
                break;
            case 'אהבתי':
                path = 'favorite-items';
                focusKey = 'FAVORITE-ITEMS';
                break;
            default:
                break;
        }
        return { path: path, focusKey: focusKey };
    };

    //A function that handles the focus of the menu items. Navigates the user to the according route when FOCUSED.
    const handleMenuItemFocus = (item: string) => {
        const whatIsFocused = checkWhatIsFocused(item);
        if (whatIsFocused.focusKey === 'FOODMENU') {
            dispatch(toggleMenu());
        } else {
            dispatch(toggleMenuFalse());
        }
        // navigate(whatIsFocused.path);
    };
    //A function that handles the press of the menu items. Navigates the user to the according route when PRESSED.
    const handleMenuItemPress = (item: string) => {
        const whatIsFocused = checkWhatIsFocused(item);
        if (whatIsFocused.focusKey === 'FOODMENU') {
            dispatch(openMenu());
            setFocus('SIDEFOODMENU');
        } else {
            dispatch(toggleMenuFalse());
            setFocus(whatIsFocused.focusKey);
            navigate(whatIsFocused.path);
        }
    };
    // console.log(whatSidebarIsFocused, 'This is what sidebar is focused at');

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`
                    flex flex-col
                    transition-all duration-400 ease-in-out
                    bg-gradient-to-l from-black 
                    h-full
                    z-10
                    ${hasFocusedChild ? 'w-[14vw] from-55%' : 'w-[10vw] from-45%'}
                    ${isMenuOpen && '!from-100%'}
                    `}
            >
                <div className="absolute top-[2vw] right-[2.5vw]">
                    <img
                        className="w-[3vw] h-[2vw]"
                        src={`https://pizzahut-assets.s3.amazonaws.com/icons/assets/pizzahut-logo.svg`}
                        alt=""
                    />
                </div>
                <div className="flex-1 flex flex-col justify-center items-start mr-[2vw]">
                    {menuItems.map((item) => (
                        <SidebarItem
                            key={item.label}
                            focusKey={`${item.label}-Sidebar`}
                            label={item.label}
                            icon={item.icon}
                            previousFocus={pathnameToFocusKey(location.pathname)}
                            displayLabel={hasFocusedChild}
                            onPressed={() => handleMenuItemPress(item.label)}
                            onFocused={() => handleMenuItemFocus(item.label)}
                            whatSidebarIsFocused={whatSidebarIsFocused}
                            setWhatSidebarIsFocused={setWhatSidebarIsFocused}
                            isSideMenuOpen={isMenuOpen}
                            isSidebarFocused={hasFocusedChild}
                        />
                    ))}
                </div>
                {isMenuOpen && (
                    <SideMenuRow focusKey="SIDEFOODMENU" menuCategories={allMenuItems} />
                )}
            </div>
        </FocusContext.Provider>
    );
}
