import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useSelector } from '../../redux/store';
import FavoriteItem from './FavoriteItem';
import { useCallback, useEffect } from 'react';
import NoFavoriteItems from './NoFavoriteItems';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import BackButton from '../../components/Buttons/BackButton';

type FavoriteItemsScreenProps = {
    focusKey: string;
};

const FavoriteItemsScreen = ({ focusKey: focusKeyParam }: FavoriteItemsScreenProps) => {
    const lovedItems = useSelector((state) => state.userAuth.user?.favorites);
    const dispatch = useDispatch();
    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
    });

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                '',
            ]),
        );
    }, []);

    const onRowFocus = useCallback(
        ({ y }) => {
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const areThereLovedItems = lovedItems && lovedItems.length > 0;

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="w-full h-full pr-[2vw] pt-[2vw]">
                <div className="flex flex-col w-[100%] h-[100%]">
                    {areThereLovedItems ? (
                        <div ref={ref} className="overflow-y-auto mt-[5vw]">
                            <BackButton className="absolute top-[3vw] right-[4vw]" />
                            {lovedItems.map((item, index) => {
                                return (
                                    <FavoriteItem
                                        focusKey={`favorite-item${index}`}
                                        key={index}
                                        item={item}
                                        onRowFocus={onRowFocus}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <NoFavoriteItems />
                    )}
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default FavoriteItemsScreen;
