import React, { useCallback } from 'react';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import FoodTopping from './FoodTopping';
import { Extra } from '../../redux/slices/dbFoodMenuSlice';

type ToppingsListProps = {
    focusKey: string;
    toppings: Extra[];
    pizzaToppingsArray: Extra[];
    onSelectPizzaTopping: (topping: Extra, extraSize: string) => void;
};

export const PizzaToppingsList = ({
    focusKey: focusKeyParam,
    toppings,
    pizzaToppingsArray,
    onSelectPizzaTopping,
}: ToppingsListProps) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        trackChildren: true,
    });

    const onAssetFocus = useCallback(
        ({ y }) => {
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={`overflow-y-auto flex flex-col`}>
                {toppings.map((topping, index) => (
                    <FoodTopping
                        key={topping._id}
                        topping={topping}
                        pizzaToppingsArray={pizzaToppingsArray}
                        onRowFocus={onAssetFocus}
                        focusKey={`${focusKey}-${index}`}
                        onSelectPizzaTopping={onSelectPizzaTopping}
                    />
                ))}
            </div>
        </FocusContext.Provider>
    );
};
