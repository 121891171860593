type downArrowComponent = {
    className?: string;
};
const OpenModalDownArrowSvg = ({ className }: downArrowComponent) => {
    return (
        <svg
            className={`${className}`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#FFFFFF"
            transform="rotate(0)"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M7 10L12 15L17 10"
                    stroke="#FFFFFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </g>
        </svg>
    );
};

export default OpenModalDownArrowSvg;
