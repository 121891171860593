const DeviceNotSupportedScreen = () => {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <img
                className={`w-[10vw] h-[10vw] object-contain`}
                src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/pizzahut-logo.svg"
                alt=""
            />
            <span className="text-[2.5vw]">{`למכשיר שברשותך אין מיקרופון, ולכן אין אפשרות להשתמש במסך זה :-(`}</span>
        </div>
    );
};

export default DeviceNotSupportedScreen;
