import { Modal } from '@mantine/core';
import { useRef } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

import RedButton from '../Buttons/RedButton';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type ConfirmModalProps = {
    opened: boolean;
    close: () => void;
    title: string;
    focusKey: string;
    // setConfirmModalAnswer: (value: React.SetStateAction<boolean>) => void;
    modalAnswerFunction: () => void;
};

const GeneralTranslateFunction = createTranslateFunction('buttons');
export const ConfirmModal = ({
    opened,
    close,
    title,
    focusKey: focusKeyParam,
    // setConfirmModalAnswer,
    modalAnswerFunction,
}: ConfirmModalProps) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'CONFIRM-MODAL-BUTTON',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`40vw`}
                    radius={`3vw`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.2,
                        blur: 12,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                            outline: 'none',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center justify-evenly w-[100%] overflow-y-auto max-h-[45vh] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <span className="text-[2vw] text-white mb-[2vw] max-w-[30vw] text-center">{`${title}`}</span>
                        <RedButton
                            className="mt-[0.6vw] py-[0.8vh] outline-none bg-white bg-opacity-15 !text-[1.6vw] w-[16vw] rounded-[3vh] mb-[1.2vw]"
                            onFocusClass={`!bg-pizzahut-red !text-white !scale-110`}
                            focusKey={`CONFIRM-MODAL-BUTTON`}
                            onClick={() => {
                                modalAnswerFunction();
                                close();
                            }}
                        >
                            {`${GeneralTranslateFunction('confirm')}`}
                        </RedButton>
                        <RedButton
                            className="mt-[0.6vw] py-[0.8vh] outline-none bg-white bg-opacity-15 !text-[1.6vw] w-[16vw] rounded-[3vh]"
                            onFocusClass={`!bg-pizzahut-red !text-white !scale-110`}
                            focusKey={`DECLINE-MODAL-BUTTON`}
                            onClick={() => {
                                close();
                            }}
                        >
                            {`${GeneralTranslateFunction('decline')}`}
                        </RedButton>
                    </div>
                </Modal>
            </div>
            /
        </FocusContext.Provider>
    );
};
