import { Dispatch } from 'react';
import RedButton from '../../../components/Buttons/RedButton';
import createTranslateFunction from '../../../i18n/createTranslateFunction';
import { createNewOrder } from '../../../redux/slices/deliveryFormSlice';
import { AppDispatch, useSelector } from '../../../redux/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { calculateTotalCartPrice } from '../../EditFoodScreen/EditFoodScreenHelperFunctions';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

type TotalSummaryBarProps = {
    focusKey: string;
    toggleCheckoutAlertModal: (errorMessage: string) => void;
    setIsLoading: Dispatch<boolean>;
};
const TotalSummaryBar = ({
    focusKey: focusKeyParam,
    toggleCheckoutAlertModal,
    setIsLoading,
}: TotalSummaryBarProps) => {
    const TranslateFunction = createTranslateFunction('checkout_screen.delivery_form');
    const TranslateAlertsFunction = createTranslateFunction('checkout_screen.alerts');
    const deliveryFormData = useSelector((state) => state.deliveryFormData);
    const { deliveryForm } = deliveryFormData;

    const { cart } = useSelector((state) => state.cart);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'PAY-NOW-BUTTON',
    });

    const isAbleToCheckout = () => {
        const updatedCart = JSON.parse(localStorage.getItem('cart') || '[]');
        if (updatedCart.length < 1) {
            toggleCheckoutAlertModal(TranslateAlertsFunction('empty_cart'));
            return false;
        }
        if (deliveryForm.order_type === 0 && !deliveryForm.address) {
            toggleCheckoutAlertModal(TranslateAlertsFunction('empty_address'));
            return false;
        }
        if (deliveryForm.address && !deliveryForm?.address.closest_branch) {
            toggleCheckoutAlertModal(TranslateAlertsFunction('empty_branch'));
            return false;
        }
        if (!deliveryForm.future_date) {
            toggleCheckoutAlertModal(TranslateAlertsFunction('empty_date'));
            return false;
        }
        return true;
    };

    const navigateToPaymentPage = async () => {
        if (!isAbleToCheckout()) {
            return;
        }
        setIsLoading(true);
        await dispatch(createNewOrder(deliveryFormData.deliveryForm));

        if (deliveryFormData.orderSubmissionError) {
            toggleCheckoutAlertModal(TranslateAlertsFunction('order_submission_error'));
            return;
        }
        setIsLoading(false);
        navigate('/choose-payment');
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className="h-[13vw] w-[95%] px-[2vw] bg-white bg-opacity-[3%] rounded-t-[0.5vw] flex items-center justify-between"
            >
                <div>
                    <div className="flex items-center w-[16vw] justify-between">
                        <span className="text-[1.8vw] font-semibold text-white ml-[2vw]">{`${TranslateFunction(`sub_headers.total_cost`)}`}</span>
                        <span className="text-[1.8vw] font-semibold text-white">
                            {calculateTotalCartPrice(cart, deliveryForm.delivery_price)}
                            {`₪`}
                        </span>
                    </div>
                    {deliveryForm.delivery_price > 0 && (
                        <div className="flex items-center w-[16vw] justify-between">
                            <span className="text-[1.5vw] text-white ml-[2vw]">{`${TranslateFunction(`sub_headers.delivery_cost`)}`}</span>
                            <span className="text-[1.5vw] text-white">
                                {`${deliveryForm.delivery_price}₪`}
                            </span>
                        </div>
                    )}
                </div>
                <div className={'flex items-center'}>
                    <RedButton
                        className="!text-[1.6vw] font-semibold !w-[12vw]"
                        onFocusClass={'border-[0.15vw] border-[#ffffff]'}
                        onClick={navigateToPaymentPage}
                        focusKey={'PAY-NOW-BUTTON'}
                    >{`${TranslateFunction(`buttons.pay_now`)}`}</RedButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default TotalSummaryBar;
