import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Extra } from '../../../../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import {
    AddPizzaExtraToChosenExtras,
    RemovePizzaExtraFromChosenExtras,
} from '../../../../../redux/slices/personalAssistantSlice';

type ExtraPortionButtonProps = {
    extraData: Extra;
    toppingPortion: string;
    focusKey: string;
    isSelected: boolean;
};

const ExtraPortionButton = ({
    focusKey: focusKeyParam,
    extraData,
    toppingPortion,
    isSelected,
}: ExtraPortionButtonProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isHovered, setIsHovered] = useState(false);
    const handleToppingPortionClick = () => {
        if (isSelected) {
            dispatch(RemovePizzaExtraFromChosenExtras(extraData));
        } else {
            dispatch(AddPizzaExtraToChosenExtras({ ...extraData, extraSize: toppingPortion }));
        }
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onEnterPress: handleToppingPortionClick,
    });

    const PortionSizesLinks = {
        full: 'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/full_topping.svg',
        halfright:
            'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfright_topping.svg',
        halfleft:
            'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfleft_topping.svg',
        full_css: `justify-center`,
        halfright_css: `justify-start`,
        halfleft_css: `justify-end`,
    };

    return (
        <div
            ref={ref}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <button
                className={`w-[2.5vw] h-[2.5vw] bg-food-portion rounded-full flex ${PortionSizesLinks[`${toppingPortion}_css`]} items-center border-[0.1vw] ${(focused || isHovered) && 'bg-selected-hut-red'} ${isSelected && `bg-selected-hut-red border-selected-hut-red`} ${focused && isSelected && `!border-red-600`}`}
                ref={ref}
                onClick={handleToppingPortionClick}
            >
                <img
                    className="w-[55%] h-[55%]"
                    src={`${PortionSizesLinks[toppingPortion]}`}
                    alt="no svg of size"
                />
            </button>
        </div>
    );
};

export default ExtraPortionButton;
