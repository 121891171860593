import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    focusKey?: string;
    className?: string;
    onFocusClass?: string;
    onClick: () => void;
    onBtnFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};

const RedButton = ({
    focusKey: focusKeyParam,
    children,
    className,
    onFocusClass,
    onClick,
    onBtnFocus,
}: ButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const { ref, focused, focusKey } = useFocusable({
        onFocus: onBtnFocus,
        onEnterPress: onClick,
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => onClick()}
                ref={ref}
                className={`bg-pizzahut-red transition px-[1vw] py-[2vh] text-[1vw] text-white rounded-full ${className} 
      ${(focused || isHovered) && `bg-selected-hut-red scale-105 ${onFocusClass}`}`}
            >
                {children}
            </button>
        </FocusContext.Provider>
    );
};

export default RedButton;
