export type AccessToken = {
    access_token: string;
    id_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
};

export const getAccessToken = (): AccessToken | null => {
    const token = localStorage.getItem('access_token');
    if (token === 'undefined') {
        localStorage.removeItem('access_token');
        window.location.reload();
    }
    if (!token) {
        return null;
    }
    return JSON.parse(token);
};

export const setAccessToken = (token: object) => {
    localStorage.setItem('access_token', JSON.stringify(token));
};

export const getUserFromStorage = () => {
    const user = localStorage.getItem('user');
    if (user === 'undefined') {
        localStorage.removeItem('user');
        window.location.reload();
    }
    if (!user) {
        return null;
    }
    return JSON.parse(user);
};

export const setUserToStorage = (user: object) => {
    if (user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
};

export const clearStorage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
};
