import { Modal } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import RedButton from '../../Buttons/RedButton';
import ItemSuggestionPopup from './ItemSuggestionPopup';
import { useSelector } from '../../../redux/store';
import { Extra, Item, Sauce } from '../../../redux/slices/dbFoodMenuSlice';
import { CartItem } from '../../../redux/slices/cartSlice';
import { SuggestionsRow } from './SuggestionsRow';
import { useDispatch } from 'react-redux';
import { resetCurrentQuery } from '../../../redux/slices/textToSpeechSlice';
type ConfirmTextToSpeechItemModalProps = {
    opened: boolean;
    close: () => void;
    title: string;
    focusKey: string;
    modalAnswerFunction: (cart: CartItem[]) => void;
    allMenuItems: Item[];
};
export const ConfirmTextToSpeechItemModal = ({
    opened,
    close,
    title,
    focusKey: focusKeyParam,
    modalAnswerFunction,
    allMenuItems,
}: ConfirmTextToSpeechItemModalProps) => {
    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'CONFIRM-TEXTTOSPEECH-MODAL-BUTTON',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { currentQuery } = useSelector((state) => state.textToSpeech);

    //chosenSuggestionCart is an array of cartItems, It resembles the rendered item suggestion, with extras item and sauces (Lets say pizza with toppings)
    const [chosenSuggestionCart, setChosenSuggestionCart] = useState<CartItem[]>([]);
    //Alternative resembles the alternative (suggestions) for other products
    const [alternativeOptions, setAlternativeOptions] = useState<Item[] | null>([]);

    const areExtraNamesSimilar = (extraName1: string, extraName2: string): boolean => {
        const removeTrailingEnglishLetters = (name: string) => {
            return name.replace(/[a-zA-Z]+$/, '').trim();
        };
        const cleanExtraName1 = removeTrailingEnglishLetters(extraName1);
        const cleanExtraName2 = removeTrailingEnglishLetters(extraName2);

        return cleanExtraName1 === cleanExtraName2;
    };

    const returnExtrasFromItem = (itemFromMenu: Item, currentQueryItem: Item) => {
        const extrasThatAreInCurrentItem = itemFromMenu?.extras.filter((itemMenuExtra) => {
            return currentQueryItem.extras.find((currentQueryExtra) =>
                areExtraNamesSimilar(itemMenuExtra.name, currentQueryExtra.name),
            );
        });
        const extrasWithSize = extrasThatAreInCurrentItem.map((extraInCurrentItem) => {
            const extraSize = currentQueryItem.extras.find((currentQueryExtra) =>
                areExtraNamesSimilar(extraInCurrentItem.name, currentQueryExtra.name),
            )?.extraSize;
            return {
                ...extraInCurrentItem,
                extraSize: extraSize ? extraSize : 'full',
            };
        });
        return extrasWithSize;
    };

    const returnSaucesFromItem = (itemFromMenu: Item, currentQueryItem: Item) => {
        const saucesFromItem = itemFromMenu?.sauces.filter((sauce) => {
            return currentQueryItem.sauces.find((sauceReply) => sauceReply.makat === sauce.makat);
        });
        return saucesFromItem;
    };

    const convertCurrentQueryToCart = (currentQueryItem: Item) => {
        const tempCart: CartItem[] = [];
        let tempExtras: Extra[] = [];
        let tempSauces: Sauce[] = [];

        const itemFromMenu = allMenuItems.find((item) => item.makat === currentQueryItem.makat);
        if (!itemFromMenu) {
            return;
        }

        if (itemFromMenu.extras) {
            tempExtras = returnExtrasFromItem(itemFromMenu, currentQueryItem);
        }
        if (itemFromMenu?.sauces) {
            tempSauces = returnSaucesFromItem(itemFromMenu, currentQueryItem);
        }

        tempCart.push({
            item: itemFromMenu,
            extras: tempExtras,
            sauces: tempSauces,
        });

        return tempCart;
    };
    const convertCurrentQueryToSuggestion = (currentQueryItem: Item) => {
        const subCategoryItemsIds = allMenuItems.find(
            (item) => item.makat === currentQueryItem.makat,
        )?.category.items;

        // get the items from allMenuItems that are in the same subCategoryItemsIds
        const alternativeOptionsTemp = allMenuItems.filter((item) => {
            return (subCategoryItemsIds as unknown as string[])?.includes(item._id);
        });
        return alternativeOptionsTemp.filter((a) => a.makat !== currentQueryItem.makat);
    };

    const changeSuggestionsAndAlternativeOptions = (currentQueryItem: Item) => {
        const newCart = convertCurrentQueryToCart(currentQueryItem);
        if (newCart) {
            setChosenSuggestionCart(newCart);
        }
        const newSuggestions = convertCurrentQueryToSuggestion(currentQueryItem);
        if (newSuggestions) {
            setAlternativeOptions(newSuggestions);
        }
    };

    const resetSelectionsAndSuggestions = () => {
        setChosenSuggestionCart([]);
        setAlternativeOptions([]);
        // dispatch(resetCurrentQuery());
    };

    const onConfirmButton = () => {
        modalAnswerFunction(chosenSuggestionCart);
        close();
    };
    const onCancelButton = () => {
        close();
    };

    useEffect(() => {
        focusSelf();
        return () => {
            resetSelectionsAndSuggestions();
            dispatch(resetCurrentQuery());
            close();
        };
    }, []);

    useEffect(() => {
        const currentQueryItem = currentQuery.reply.items[0];
        if (currentQuery.reply.items?.length < 1) {
            return;
        }
        changeSuggestionsAndAlternativeOptions(currentQueryItem);
    }, [currentQuery]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`60vw`}
                    radius={'3vw'}
                    padding={'2vw'}
                    title={title}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.2,
                        blur: 12,
                    }}
                    styles={{
                        root: {},
                        body: {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'black',
                        },
                        header: {
                            backgroundColor: 'black',
                            justifyContent: 'center',
                        },
                        title: {
                            color: 'white',
                            fontSize: '2.5vw',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center justify-evenly w-[100%] overflow-y-auto max-h-[75vh] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <ItemSuggestionPopup
                            chosenSuggestionCart={chosenSuggestionCart}
                            focusKey="ITEM-SUGGESTION-POPUP"
                        />
                        {/* display the alternatives as as well */}
                        {alternativeOptions && (
                            <SuggestionsRow
                                suggestions={alternativeOptions}
                                focusKey={'SUGGESTIONS-ROW'}
                            />
                        )}

                        <div className="w-[100%] flex justify-evenly items-center">
                            <RedButton
                                className="mt-[0.6vw] border-black border-2 text-black py-[0.8vh] text-[1.8vw] w-[16vw]  rounded-[3vh]"
                                onFocusClass={`!bg-pizzahut-red !text-white !border-white`}
                                focusKey={`CONFIRM-TEXTTOSPEECH-MODAL-BUTTON`}
                                onClick={onConfirmButton}
                            >
                                {`אישור`}
                            </RedButton>
                            <RedButton
                                className="mt-[0.6vw] border-black border-2 text-black py-[0.8vh] text-[1.8vw] w-[16vw]  rounded-[3vh]"
                                onFocusClass={`!bg-pizzahut-red !text-white !border-white`}
                                focusKey={`DECLINE-TEXTTOSPEECH-MODAL-BUTTON`}
                                onClick={onCancelButton}
                            >
                                {`ביטול`}
                            </RedButton>
                        </div>
                    </div>
                </Modal>
            </div>
        </FocusContext.Provider>
    );
};
