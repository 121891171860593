import { Modal } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../components/Buttons/TransparentButton';
import { useSelector } from '../../redux/store';
import { IconX } from '@tabler/icons-react';
import { PaymentMethod } from '../../types/paymentMethod';
import api from '../../lib/axios';
import { getAccessToken } from '../../lib/auth';
import PickACardStageOne from './PickACardStageOne';
import PickACardStageTwo from './PickACardStageTwo';
import PickACardStageMeter from './PickACardStageMeter';
import { UpdateDeliveryFormDataType } from '../../redux/slices/deliveryFormSlice';

type PickACardModalProps = {
    opened: boolean;
    close: () => void;
    focusKey: string;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};

export const PickACardModal = ({
    opened,
    close,
    focusKey,
    handleDeliveryFormUpdation,
}: PickACardModalProps) => {
    const [pickACardStage, setPickACardStage] = useState(1);
    const [isQrCodeGenerating, setIsQrCodeGenerating] = useState(false);
    const orderData = useSelector((state) => state.deliveryFormData.lastOrder);

    const { ref } = useFocusable({
        focusKey,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'PICKUP-BRANCH-0',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);

    // const handleCreateOrderQr = async (userCard: PaymentMethod) => {
    //     try {
    //         const accessToken = getAccessToken()?.access_token;

    //         const { data } = await api.post(
    //             'cg/generate_transaction',
    //             {
    //                 cardId: userCard.cardId,
    //                 expiration: userCard.expiration,
    //                 orderId: orderData._id,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             },
    //         );
    //         return data.data.link;
    //     } catch (error) {
    //         return error;
    //     }
    // };

    const handleCreateOrderQrNoHash = async (userCard: PaymentMethod) => {
        try {
            const accessToken = getAccessToken()?.access_token;

            const { data } = await api.post(
                'cg/generate_transaction_no_hash',
                {
                    cardId: userCard.cardId,
                    expiration: userCard.expiration,
                    orderId: orderData._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );
            return data.data.link;
        } catch (error) {
            return error;
        }
    };

    const handleCardClick = async (userCard: PaymentMethod) => {
        setIsQrCodeGenerating(true);
        setPickACardStage(2);
        handleDeliveryFormUpdation({
            payment_card: userCard,
            payment_link: await handleCreateOrderQrNoHash(userCard),
        });
        setIsQrCodeGenerating(false);
        // close();
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`40vw`}
                    radius={`4vw`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.01,
                        blur: 5,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center overflow-y-auto h-[36vw] max-h-[36vw] p-[2vw] rounded-[4vw]"
                        ref={modalContainerRef}
                    >
                        <PickACardStageMeter pickACardStage={pickACardStage} />
                        <TransparentButton
                            className="absolute top-[3vw] right-[3vw] w-[3.3vw] h-[3.3vw] flex items-center justify-center"
                            onClick={() => close()}
                        >
                            <IconX size={'1.7vw'} stroke={2} />
                        </TransparentButton>
                        {pickACardStage === 1 && (
                            <PickACardStageOne
                                handleCardClick={handleCardClick}
                            ></PickACardStageOne>
                        )}
                        {pickACardStage === 2 && (
                            <PickACardStageTwo isQrGenerating={isQrCodeGenerating} />
                        )}
                    </div>
                </Modal>
            </div>
        </FocusContext.Provider>
    );
};
