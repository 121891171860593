const ArrowScrollSvg = ({ direction }: { direction: 'left' | 'right' }) => {
    const classNameValue = 'w-[2.2vw] h-[2.2vw] object-contain absolute';
    const upperCaseDirection = direction[0].toUpperCase() + direction.slice(1);
    return (
        <img
            className={`${classNameValue} ${direction}-[2vw]`}
            src={`https://pizzahut-assets.s3.amazonaws.com/icons/assets/${upperCaseDirection}ArrowScrollSvg.svg`}
            alt=""
        />
    );
};

export default ArrowScrollSvg;
