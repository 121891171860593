import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import { Item } from '../../../redux/slices/dbFoodMenuSlice';
import { useState } from 'react';

type ItemCardProps = {
    item: Item;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
};

export function ItemCard({ item, onFocus, focusKey: focusKeyParam }: ItemCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const handleMenuItemClick = () => {
        const categoryName = item.main_category ? item.main_category.name : item.category.name;
        const cartItem = {
            item: item,
            extras: [],
            sauces: [],
        };

        const state = {
            item: cartItem,
            categoryName,
        };
        navigate(`/editfoodscreen`, { state });
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
        onEnterPress: handleMenuItemClick,
    });

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMenuItemClick()}
            ref={ref}
            className={`w-[13vw] h-[8vw] mx-[1vw] cursor-pointer bg-white backdrop-blur-lg bg-opacity-20 rounded-categoryRadius flex grow-0 shrink-0 flex-row-reverse justify-evenly items-center ${
                (focused || isHovered) && `border-[0.15vw] border-white`
            }`}
            data-testid="menu-item"
        >
            <span className={`pl-2 flex items-center text-center justify-center text-[1.2vw]`}>
                {item.name}
            </span>
            <img
                className={`rounded w-[3vw] h-[3vw] object-cover ml-[0.8vw]`}
                src={item.icon}
                alt=""
            />
        </div>
    );
}
