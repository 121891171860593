import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { ResetAssistantPage } from '../../../../redux/slices/personalAssistantSlice';

type DiscardItemButtonProps = {
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
    handleResetPageFunction: () => void;
};

export function DiscardItemButton({
    onFocus,
    focusKey: focusKeyParam,
    handleResetPageFunction,
}: DiscardItemButtonProps) {
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const handleDiscardItemButtonClick = () => {
        handleResetPageFunction();
        setIsHovered(false);
    };
    const { ref, focused, focusKey } = useFocusable({
        onFocus,
        focusKey: focusKeyParam,
        onEnterPress: handleDiscardItemButtonClick,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleDiscardItemButtonClick}
                ref={ref}
                className={`w-[13vw] h-[13vw] flex flex-col rounded-categoryRadius cursor-pointer bg-[#222222] bg-opacity-60 justify-evenly items-center ${
                    (focused || isHovered) && '!bg-pizzahut-red !bg-opacity-100'
                } `}
            >
                <img
                    className="w-[2.5vw] h-[2.5vw]"
                    src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/TrashCanIcon.svg"
                    alt="Shopping Cart Icon"
                />
                <span className="text-[1.4vw] text-center max-w-[60%]">בואו נתחיל מחדש</span>
            </div>
        </FocusContext.Provider>
    );
}
