import {
    FocusableComponentLayout,
    FocusContext,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { Extra, Sauce } from '../../redux/slices/dbFoodMenuSlice';
import FoodToppingPortionSticker from '../../components/FoodToppingPortionSticker';

type cartItemProps = {
    cartItem: any;
    focusKey: string;
    onFocus: (layout: FocusableComponentLayout, props: unknown, details: object) => void;
};
const CartItemRow = ({ cartItem, focusKey: focusKeyParam, onFocus }: cartItemProps) => {
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
    });

    const isCategoryPizza = () => {
        return cartItem.item.category.name.includes('פיצה');
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className="w-[100%] my-[1vw] border-b-[1px] border-b-gray-400 flex flex-col p-[2vh]"
            >
                <div className="w-full flex items-start justify-between ml-[1.2vw]">
                    <img
                        className="w-[5vw] h-[5vw] rounded-[1.4vw] object-cover"
                        src={
                            cartItem.item.icon
                                ? cartItem.item.icon
                                : 'https://png.pngtree.com/png-clipart/20230320/ourmid/pngtree-round-pizza-realistic-fast-food-digital-artwork-png-image_6657786.png'
                        }
                        alt="cartItemIcon"
                    />

                    <div className="flex flex-col flex-1 mx-[1vw]">
                        <div className="flex justify-between text-[1.2vw] font-bold">
                            <span>{cartItem.item.name}</span>
                            <span>₪{cartItem.item.price}</span>
                        </div>
                        <div className="flex flex-col text-[1vw] my-[1vw]">
                            {cartItem.extras.map((extra: Extra, index: number) => (
                                <div
                                    key={`cart-${extra.name}-${index}-row`}
                                    className={'flex justify-between mb-[1vw]'}
                                >
                                    <div className="flex items-center">
                                        {isCategoryPizza() && (
                                            <FoodToppingPortionSticker
                                                extraSize={`${extra.extraSize}`}
                                                focusKey={`${extra.name}-${extra.extraSize}-topping`}
                                            />
                                        )}
                                        <span className="text-[1.2vw]">{`${extra.name}`}</span>
                                    </div>
                                    <span className="text-[1.2vw]">{`+ ${extra.price} ₪`}</span>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col text-[1vw] m-[0.7vw]">
                            {cartItem.sauces &&
                                cartItem.sauces.map((sauce: Sauce, index: number) => {
                                    return (
                                        <div key={`cart-${sauce.name}-${index}-firstrow`}>
                                            {Array(sauce.sauceQuantity)
                                                .fill(0)
                                                .map((_, index) => {
                                                    return (
                                                        <div
                                                            key={`cart-${sauce.name}-${index}-row`}
                                                            className={
                                                                'flex mb-[0.2vw] justify-between'
                                                            }
                                                        >
                                                            <span className="text-[1.2vw]">{`${sauce.name}`}</span>
                                                            <span className="text-[1.2vw]">{`+ ${sauce.price} ₪`}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default CartItemRow;
