import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '../../redux/store';
import ChooseCategory from './AssistantSteps/ChooseCategory/ChooseCategory';
import ChooseItem from './AssistantSteps/ChooseItem/ChooseItem';
import ChoosePizzaSubCategory from './AssistantSteps/ChoosePizzaSubCategory/ChoosePizzaSubCategory';
import ChooseExtras from './AssistantSteps/ChooseExtras/ChooseExtras';
import ChooseToKeepItem from './AssistantSteps/ChooseToKeepItem/ChooseToKeepItem';
import UserInputTextRender from './AssistantSteps/UserInputTextRender';
import {
    doesNameIncludePizza,
    shouldHavePaddingOnBottom,
    shouldRenderChooseExtras,
    shouldRenderChooseItem,
    shouldRenderChoosePizzaSubCategory,
    shouldRenderChooseSubCategory,
} from './PersonalAssistantHelperFunctions';
import { ResetAssistantPage } from '../../redux/slices/personalAssistantSlice';
import { useDispatch } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import TransparentButton from '../../components/Buttons/TransparentButton';
import { IconBasket } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import ChooseSubCategory from './AssistantSteps/ChooseSubCategory/ChooseSubCategory';
import { Item, MenuCategory } from '../../redux/slices/dbFoodMenuSlice';

type PersonalAssistantProps = {
    focusKey: string;
};
const PersonalAssistant = ({ focusKey: focusKeyParam }: PersonalAssistantProps) => {
    const TranslateFunction = createTranslateFunction('personal_assistant_screen');
    const GeneralTranslationFunction = createTranslateFunction('buttons');
    const cart = useSelector((state) => state.cart.cart);
    const navigate = useNavigate();
    const {
        chosenCategory,
        chosenSubCategory,
        chosenExtras,
        itemsToDisplay,
        chosenItem,
        assistantStep,
        chosenCategoryQuery,
        chosenSubCategoryQuery,
        chosenItemQuery,
        chosenExtrasQuery,
    } = useSelector((state) => state.personalAssistant);
    const userCart = useSelector((state) => state.cart.cart);
    const [chooseCategoryKey, setChooseCategoryKey] = useState(1234);
    const [chooseSubCategoryKey, setChooseSubCategoryKey] = useState(2345);
    const [chooseItemKey, setChooseItemKey] = useState(3456);
    const [chooseExtrasKey, setChooseExtrasKey] = useState(4567);
    const [chooseToKeepItemKey, setChooseToKeepItemKey] = useState(5678);
    const dispatch = useDispatch();
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'PA-CHOOSE-CATEGORY-ROW',
    });
    const onRowFocus = useCallback(() => {
        if (ref.current) {
            ref.current.style.scrollBehavior = 'smooth';
            ref.current.scrollTop = 200000;
        }
    }, [ref]);

    const checkIfCategoryIsPizza = (categoryName: string): boolean => {
        return categoryName.includes('פיצות');
    };

    const checkIfCategoryIsDrinks = (categoryName: string): boolean => {
        return categoryName.includes('שתייה');
    };

    console.log(chosenCategory, 'chosenCategory');

    const areThereItemsInChosenCategory = () => {
        if (chosenCategory && chosenCategory.items) {
            return true;
        } else if (chosenSubCategory && chosenSubCategory.items) {
            return true;
        }
        return false;
    };

    const getChosenCategoryUserInputText = () => {
        if (chosenCategoryQuery) {
            return chosenCategoryQuery;
        }
        if (!chosenCategory || !chosenCategory.name) {
            return ``;
        }
        if (doesNameIncludePizza(chosenCategory?.name)) {
            return `אני רוצה פיצה`;
        }
        return `אני רוצה ${chosenCategory?.name}`;
    };

    const getChosenSubCategoryUserInputText = () => {
        if (chosenSubCategoryQuery) {
            return chosenSubCategoryQuery;
        }
        if (!chosenSubCategory || !chosenSubCategory.name) {
            return ``;
        }
        return `אני רוצה ${chosenSubCategory?.name}`;
    };
    const getChosenItemUserInputText = () => {
        if (chosenItemQuery) {
            return chosenItemQuery;
        }
        if (!chosenItem || !chosenItem.name) {
            return ``;
        }
        return `אני רוצה ${chosenItem?.name}`;
    };

    const returnExtrasString = () => {
        let extrasString = '';
        for (const extra of chosenExtras) {
            extrasString += extra.name + ', ';
        }
        return extrasString;
    };
    const getChosenExtrasUserInputText = () => {
        //Add a line that checks if there is a melingo query, if so, display it.
        if (chosenExtrasQuery) {
            return chosenExtrasQuery;
        }
        if (chosenExtras.length < 0) {
            return ``;
        }
        return `אני רוצה ${returnExtrasString()}`;
    };

    const CustomToast = ({ message }) => (
        <div className="flex relative justify-center items-center">
            <img
                className="w-[4vw] h-[4vw] absolute top-[-0.5vw] right-[1vw] rounded-full border-[0.1vw] border-white"
                src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/PizzaHutHat.webp"
                alt=""
            />
            <p className="text-[2vw] text-white">{message}</p>
        </div>
    );

    const handleErrorMessageFromMelingo = (errorMessage?: string) => {
        const toastErrorMessage = errorMessage ? errorMessage : 'לא נמצאו תוצאות, בואו ננסה שוב';
        toast.error(toastErrorMessage, {
            position: 'top-center',
            theme: 'colored',
            transition: Bounce,
        });
    };

    const handleSuccessMessage = (successMessage?: string) => {
        const toastSuccessMessage = successMessage ? successMessage : 'הפריט נוסף בהצלחה לעגלה!';
        toast(<CustomToast message={toastSuccessMessage} />, {
            position: 'top-center',
            className: '!bg-pizzahut-red',
            transition: Bounce,
        });
    };

    const handleResetPageFunction = () => {
        dispatch(ResetAssistantPage());
        setChooseCategoryKey((prev) => prev + 1);
    };
    useEffect(() => {
        focusSelf();
    }, []);

    useEffect(() => {
        //Check if the category key has been changed, if it was already changed and the userCart has changed too, it means a new item was added to the cart.
        if (chooseCategoryKey !== 1234) {
            handleSuccessMessage();
        }
    }, [userCart]);

    // console.log(assistantStep, 'This is the assistant step');

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`w-full h-full flex flex-col ${shouldHavePaddingOnBottom(assistantStep, chosenCategory, chosenSubCategory) && `pb-[16vw]`} overflow-y-scroll items-center`}
            >
                <div className="w-full h-[6vw] justify-start pr-[2.5vw] mb-[4vw]">
                    <TransparentButton
                        className="px-[1.5vw] mt-[2vw] ml-[1vw] w-[13vw] h-[4vw] flex justify-evenly items-center bg-opacity-10 "
                        onClick={() => navigate('/checkout')}
                    >
                        <IconBasket size={'2vw'} />
                        <div className="w-[60%] flex justify-between">
                            <span className="text-[1.5vw] ml-[0.7vw]">{`${GeneralTranslationFunction(`cart`)}`}</span>
                            <span className="text-[1.5vw]">{cart.length}</span>
                        </div>
                    </TransparentButton>
                </div>
                {/*
                    <TransparentButton onClick={() => handleSuccessMessage('הצלחה')}>
                        כפתור לבדיקה
                    </TransparentButton>
                */}
                <ChooseCategory
                    key={chooseCategoryKey}
                    setChooseCategoryKey={setChooseCategoryKey}
                    handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                    focusKey="PA-CHOOSE-CATEGORY-ROW"
                />
                {chosenCategoryQuery && (
                    <UserInputTextRender
                        onRowFocus={onRowFocus}
                        textToDisplay={getChosenCategoryUserInputText()}
                    />
                )}
                {shouldRenderChoosePizzaSubCategory(chosenCategory) && (
                    <ChoosePizzaSubCategory
                        key={chooseSubCategoryKey}
                        setChooseCategoryKey={setChooseCategoryKey}
                        setChooseSubCategoryKey={setChooseSubCategoryKey}
                        handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                        focusKey="PA-CHOOSE-PIZZA-SUBCATEGORY-ROW"
                        onRowFocus={onRowFocus}
                        isDisabled={assistantStep === 'choosePizzaSubCategory' ? false : true}
                    />
                )}
                {shouldRenderChooseSubCategory(chosenCategory) && (
                    <ChooseSubCategory
                        key={chooseSubCategoryKey}
                        setChooseCategoryKey={setChooseCategoryKey}
                        setChooseSubCategoryKey={setChooseSubCategoryKey}
                        handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                        focusKey="PA-CHOOSE-SUBCATEGORY-ROW"
                        onRowFocus={onRowFocus}
                        isDisabled={assistantStep === 'chooseSubCategory' ? false : true}
                    />
                )}
                {chosenSubCategoryQuery && (
                    <UserInputTextRender
                        onRowFocus={onRowFocus}
                        textToDisplay={getChosenSubCategoryUserInputText()}
                    />
                )}
                {shouldRenderChooseItem(itemsToDisplay) && (
                    <ChooseItem
                        setChooseCategoryKey={setChooseCategoryKey}
                        setChooseSubCategoryKey={setChooseSubCategoryKey}
                        setChooseItemKey={setChooseItemKey}
                        handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                        key={chooseItemKey}
                        focusKey="PA-CHOOSE-ITEM-ROW"
                        chosenCategoryItems={itemsToDisplay}
                        onRowFocus={onRowFocus}
                        isDisabled={assistantStep === 'chooseItem' ? false : true}
                    />
                )}
                {chosenItemQuery && (
                    <UserInputTextRender
                        onRowFocus={onRowFocus}
                        textToDisplay={getChosenItemUserInputText()}
                    />
                )}
                {shouldRenderChooseExtras(chosenItem) && (
                    <ChooseExtras
                        setChooseItemKey={setChooseItemKey}
                        setChooseExtrasKey={setChooseExtrasKey}
                        handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                        key={chooseExtrasKey}
                        focusKey="PA-CHOOSE-EXTRAS-ROW"
                        chosenItem={chosenItem!}
                        onRowFocus={onRowFocus}
                    />
                )}
                {chosenExtrasQuery && (
                    <UserInputTextRender
                        className="!mt-[10vw]"
                        textToDisplay={getChosenExtrasUserInputText()}
                        onRowFocus={onRowFocus}
                    />
                )}
                {assistantStep === 'chooseToKeepItem' && (
                    <ChooseToKeepItem
                        setChooseItemKey={setChooseItemKey}
                        setChooseExtrasKey={setChooseExtrasKey}
                        setChooseToKeepItemKey={setChooseToKeepItemKey}
                        handleErrorMessageFromMelingo={handleErrorMessageFromMelingo}
                        key={chooseToKeepItemKey}
                        focusKey="CHOOSE-TO-KEEP-ITEM-ROW"
                        onRowFocus={onRowFocus}
                        handleResetPageFunction={handleResetPageFunction}
                    />
                )}
            </div>
        </FocusContext.Provider>
    );
};

export default PersonalAssistant;
