import {
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { MenuCategory } from '../../../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../../redux/store';
import {
    UpdateChooseCategoryQuery,
    UpdateChosenCategory,
} from '../../../../redux/slices/personalAssistantSlice';
import SelectedOptionCheckmark from './SelectedOptionCheckmark';
import UnselectedOptionCheckmark from './UnselectedOptionCheckmark';

type CategoryCardProps = {
    categoryData: MenuCategory;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
};

export function CategoryCard({
    categoryData,
    onFocus,
    setCurrentFocusIndex,
    componentIndex,
}: CategoryCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const chosenCategory = useSelector((state) => state.personalAssistant.chosenCategory);

    const isChosen = chosenCategory && chosenCategory.name === categoryData.name ? true : false;
    const dispatch = useDispatch<AppDispatch>();

    const handleCategoryCardClick = () => {
        dispatch(UpdateChosenCategory(categoryData));
        dispatch(UpdateChooseCategoryQuery(categoryData.name));
        setIsHovered(false);
    };
    const { ref, focused } = useFocusable({
        onFocus,
        onEnterPress: handleCategoryCardClick,
    });

    useEffect(() => {
        if (focused || isHovered) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused, isHovered]);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleCategoryCardClick}
            ref={ref}
            className={`w-[14.4vw] h-[8vw] m-[0.8vw] px-[0.5vw] flex flex-row justify-start items-center relative rounded-categoryRadius cursor-pointer bg-[#222222] bg-opacity-60 ${
                (focused || isHovered || isChosen) && '!bg-pizzahut-red !bg-opacity-100'
            } ${(focused || isHovered) && isChosen && `border-[0.1vw] border-white`} `}
        >
            {(focused || isHovered || isChosen) && <SelectedOptionCheckmark />}

            <img
                className={`rounded-full w-[4.5vw] h-[4.5vw] object-cover ml-[1vw]`}
                src={categoryData.icon || '/images/pizzas/pan.jpeg'}
                alt="Category icon"
            />
            <span className={`overflow-hidden text-[1.5vw] max-w-[8vw]`}>{categoryData.name}</span>
        </div>
    );
}
