import React, { useCallback } from 'react';
import { Order, changeIsRestored } from '../../../../../redux/slices/ordersSlice';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import RedButton from '../../../../../components/Buttons/RedButton';
import TransparentButton from '../../../../../components/Buttons/TransparentButton';
import PastOrderCartItemRow from './PastOrderCartItemRow';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CartItem, addToCart, clearCart } from '../../../../../redux/slices/cartSlice';
import createTranslateFunction from '../../../../../i18n/createTranslateFunction';
import BackButton from '../../../../../components/Buttons/BackButton';
import { IconBasket } from '@tabler/icons-react';

type PastOrderCartPopupProps = {
    focusKey: string;
    pastOrder: Order;
};
const PastOrderCartPopup = ({ pastOrder, focusKey: focusKeyParam }: PastOrderCartPopupProps) => {
    const pastOrderTranslationFunction = createTranslateFunction('home_screen.past_orders');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'PAST-ORDER-CART-ITEM-0',
    });

    const onRowFocus = useCallback(
        ({ y }) => {
            console.log(y);
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const calculateTotalCartPrice = (cart: CartItem[]): number => {
        let totalPrice = 0;
        cart.forEach((cartItem) => {
            totalPrice += cartItem.item.price;
            // Add extras price
            cartItem.extras.forEach((extra) => {
                totalPrice += extra.price;
            });
            if (cartItem.sauces) {
                cartItem.sauces.forEach((sauces) => {
                    totalPrice += sauces.price * sauces.sauceQuantity;
                });
            }
            totalPrice = Math.round(totalPrice * 100) / 100;
        });
        return totalPrice;
    };
    const handleAddToCart = async () => {
        for (const currentItem of pastOrder.cart) {
            console.log(currentItem, 'CurrentITEM!!!');
            dispatch(addToCart({ ...currentItem, restoredOrderId: pastOrder._id }));
        }
        dispatch(changeIsRestored({ id: pastOrder._id, isRestored: true }));
        navigate(`/`);
    };

    const handleCheckoutButton = async () => {
        dispatch(clearCart());
        for (const currentItem of pastOrder.cart) {
            console.log(currentItem, 'CurrentITEM!!!');
            dispatch(addToCart(currentItem));
        }
        navigate(`/checkout`);
    };
    console.log(pastOrder, 'This is the past order!');

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col w-[100%] h-[100%] pt-[3vw] overflow-y-auto">
                <BackButton className="mr-[0.5vw] mb-[2vw] z-[10]" />
                <div ref={ref} className="w-[90%] h-[74%] overflow-y-auto">
                    {pastOrder.cart.map((cartItem, index) => (
                        <PastOrderCartItemRow
                            focusKey={`PAST-ORDER-CART-ITEM-${index}`}
                            key={index}
                            cartItem={cartItem}
                            onFocus={onRowFocus}
                            cartLength={pastOrder.cart.length}
                            cartIndex={index}
                        />
                    ))}
                </div>
                <div className="flex justify-evenly items-center w-[100%] pt-[2vw]">
                    <RedButton
                        focusKey="add-pastorder-to-cart-button"
                        onClick={() => handleAddToCart()}
                        className="w-[18vw] h-[5vw] px-[2vw] flex items-center justify-evenly !text-[1.5vw]"
                        onFocusClass={`border-white border-[0.1vw]`}
                    >
                        <span>{pastOrderTranslationFunction(`add_to_cart_button`)}</span>
                        <IconBasket size={'2vw'} />
                    </RedButton>
                    <TransparentButton
                        focusKey="checkout-pastorder-button"
                        onClick={() => handleCheckoutButton()}
                        className="w-[22vw] h-[6vw] px-[3vw] flex flex-col items-center justify-between !text-[1.5vw]"
                        focusClassName={`border-white border-[0.1vw]`}
                    >
                        {/* <span>{`${calculateTotalCartPrice(pastOrder.cart)}₪`}</span> */}
                        <span className="text-[1.4vw]">
                            {pastOrderTranslationFunction('move_to_payment')}
                        </span>
                        <span className="text-[1vw]">
                            {pastOrderTranslationFunction('payment_disclaimer')}
                        </span>
                    </TransparentButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default PastOrderCartPopup;
