import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback } from 'react';
import CartItemRow from './CartItemRow';
import { useSelector } from '../../redux/store';
import { IconBasket } from '@tabler/icons-react';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import CartDeliveryPriceCard from './CartDeliveryPriceCard';
import { calculateTotalCartPrice } from '../../screens/EditFoodScreen/EditFoodScreenHelperFunctions';

type cartPopupProps = {
    focusKey: string;
};
const CartPopup = ({ focusKey: focusKeyParam }: cartPopupProps) => {
    const TranslateFunction = createTranslateFunction('checkout_screen.cart_popup');
    // const couponCodeDiscount = 35;

    const { cart } = useSelector((state) => state.cart);
    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const onRowFocus = useCallback(
        ({ y }) => {
            console.log(y);
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const shouldShowDeliveryPrice = () => {
        if (deliveryFormData.delivery_price && deliveryFormData.delivery_price > 0) {
            return deliveryFormData.delivery_price;
        } else {
            return false;
        }
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="h-full w-[32vw] bg-white bg-opacity-[8%] flex flex-col items-center z-10">
                <div className="pt-[2vh] w-[60%] flex justify-start items-center">
                    <IconBasket size={'3vw'} />
                    <span className="text-[2vw] mx-[1vw]">{`${TranslateFunction(`title`)}`}</span>
                    <span className="text-[1vw] text-gray-400 font-bold mt-[0.6vw]">{`${TranslateFunction(`items`, { itemCount: cart.length })}`}</span>
                </div>
                <div ref={ref} className="mt-[2vh] w-[90%] h-[80%] pb-[2vw] overflow-y-auto">
                    {cart.map((cartItem, index) => (
                        <CartItemRow
                            focusKey={`CARTITEM-${index}`}
                            key={index}
                            cartItem={cartItem}
                            cartItemIndex={index}
                            onFocus={onRowFocus}
                        />
                    ))}

                    {shouldShowDeliveryPrice() && (
                        <CartDeliveryPriceCard deliveryPrice={deliveryFormData.delivery_price} />
                    )}
                    {/* {couponCodeDiscount && (
                        <CartCouponCard couponDiscount={couponCodeDiscount}></CartCouponCard>
                    )} */}
                </div>
                <div className=" flex justify-between items-center w-[90%] p-[2vh]">
                    <span className="text-[1.7vw]">{`${TranslateFunction(`total`)}`}</span>
                    <span className="font-semibold text-[1.8vw]">{`${calculateTotalCartPrice(cart, deliveryFormData.delivery_price)}₪`}</span>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default CartPopup;
