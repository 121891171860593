import { Modal } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

import RedButton from '../Buttons/RedButton';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type AlertModalProps = {
    opened: boolean;
    close: () => void;
    addedCloseFunction?: () => void;
    title: string;
    focusKey: string;
};
export const AlertModal = ({
    opened,
    close,
    addedCloseFunction,
    title,
    focusKey: focusKeyParam,
}: AlertModalProps) => {
    const GeneralTranslateFunction = createTranslateFunction('buttons');

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'CONFIRM-MODAL-BUTTON',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`46vw`}
                    radius={`5vw`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.2,
                        blur: 12,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                            outline: 'none',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center justify-center w-[100%] overflow-y-auto max-h-[45vh] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <span className="text-[2.4vw] text-white mb-[2vw] text-center">{`${title}`}</span>
                        <RedButton
                            className="mt-[0.6vw] border-black border-2 text-black py-[0.8vh] text-[2vw] w-[18vw]  rounded-[3vh]"
                            onFocusClass={`!bg-pizzahut-red !text-white !border-white`}
                            focusKey={`CONFIRM-MODAL-BUTTON`}
                            onClick={() => {
                                if (addedCloseFunction !== undefined) {
                                    addedCloseFunction();
                                }
                                close();
                            }}
                        >
                            {`${GeneralTranslateFunction('okay')}`}
                        </RedButton>
                    </div>
                </Modal>
            </div>
            /
        </FocusContext.Provider>
    );
};
