import { useState, useEffect, useCallback } from 'react';
import { getAccessToken } from '../../../lib/auth';
import api from '../../../lib/axios';
import { useDispatch } from 'react-redux';
import {
    ToggleMelingoLoadingFalse,
    ToggleMelingoLoadingTrue,
} from '../../../redux/slices/personalAssistantSlice';

export interface MelingoExtra {
    makat: number;
    name: string;
    extraSize: string;
}

interface MelingoItem {
    makat: number;
    name: string;
    type: string;
    size: string;
    Category: string;
    pizzacategory: string;
    extras: MelingoExtra[];
    sauces: any[];
}

interface MelingoReply {
    items: MelingoItem[];
}

interface CurrentQuery {
    reply: MelingoReply;
    query: string;
}

export interface MelingoResponse {
    data: CurrentQuery;
}
const fetchMelingoDataAPI = async (textToSpeechString: string): Promise<MelingoResponse> => {
    const response = await api.post<MelingoResponse>(
        '/melingo/query',
        { message: textToSpeechString },
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()?.access_token}`,
            },
        },
    );
    return response.data;
};

// Custom hook to manage Melingo data fetching
const useMelingoQuery = () => {
    const [melingoData, setMelingoData] = useState<MelingoResponse | null>(null);
    const [melingoError, setMelingoError] = useState<Error | null>(null);
    const [isMelingoLoading, setIsMelingoLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const fetchMelingoData = useCallback(async (textToSpeechString: string) => {
        dispatch(ToggleMelingoLoadingTrue());
        setIsMelingoLoading(true);
        setMelingoError(null);

        try {
            const result = await fetchMelingoDataAPI(textToSpeechString);
            setMelingoData(result);
        } catch (err) {
            setMelingoError(err as Error);
        } finally {
            dispatch(ToggleMelingoLoadingFalse());
            setIsMelingoLoading(false);
        }
    }, []);

    return { melingoData, melingoError, isMelingoLoading, fetchMelingoData };
};

export default useMelingoQuery;
