import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../components/Buttons/TransparentButton';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useState } from 'react';
import { PickACardModal } from './PickACardModal';
import {
    UpdateDeliveryFormData,
    UpdateDeliveryFormDataType,
} from '../../redux/slices/deliveryFormSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../redux/store';
import QRCode from 'react-qr-code';

type PickACardScreenProps = {
    focusKey: string;
};
const PickACardScreen = ({ focusKey: focusKeyParam }: PickACardScreenProps) => {
    const [isPickACardModalOpen, setIsPickACardModalOpen] = useState<boolean>(false);
    const PickAcardTranslateFunction = createTranslateFunction('choose_payment.pick_a_card');
    const paymentCard = useSelector((state) => state.deliveryFormData.deliveryForm.payment_card);
    const paymentLink = useSelector((state) => state.deliveryFormData.deliveryForm.payment_link);

    const dispatch = useDispatch<AppDispatch>();

    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'phonenumber-login-input',
    });

    const handleCvvPayment = async () => {
        console.log('Pressed on pay now!');
    };

    const handleDeliveryFormUpdation = (updates: UpdateDeliveryFormDataType) => {
        dispatch(UpdateDeliveryFormData(updates));
    };

    const openPickACardModal = () => {
        setIsPickACardModalOpen(true);
        setFocus('pick-a-card-modal');
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="flex flex-col items-center pt-[3vw] h-[60%]">
                {isPickACardModalOpen && (
                    <PickACardModal
                        focusKey="pick-a-card-modal"
                        handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        close={() => setIsPickACardModalOpen(false)}
                        opened={isPickACardModalOpen}
                    />
                )}
                <span
                    className={`text-[2vw] mb-[6vw]`}
                >{`${PickAcardTranslateFunction(`title`)}`}</span>
                <span className="text-[1.4vw] max-w-[24vw] text-center mb-[4vw]">{`${PickAcardTranslateFunction(`description`)}`}</span>
                <div className="flex flex-col justify-center items-center">
                    <TransparentButton
                        className="flex justify-center items-center mb-[3vw] w-[14vw] h-[5vw] !text-[1.4vw]"
                        onClick={() => openPickACardModal()}
                    >
                        {PickAcardTranslateFunction('pick_a_card_modal_title')}
                    </TransparentButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default PickACardScreen;
