import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../lib/axios';
import MinutesToArrivalComponent from './MinutesToArrivalComponent';
import TrackingStatusBar from './TrackingStatusBar';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../components/Buttons/TransparentButton';
import OrderDetailsPopup from './OrderDetailsPopup';
import {
    OrderModel,
    OrderStatusObject,
    setLastOrderStatus,
    trackLastOrder,
} from '../../redux/slices/ordersSlice';
import { getAccessToken } from '../../lib/auth';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import DidYouGetTheOrderPopup from './DidYouGetTheOrderPopup';
import { AppDispatch, useSelector } from '../../redux/store';

type TrackOrderScreenProps = {
    focusKey: string;
};
const TrackOrderScreen = ({ focusKey: focusKeyParam }: TrackOrderScreenProps) => {
    const trackOrderTranslateFunction = createTranslateFunction('buttons');
    const { orderId } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState<boolean>(false);
    const [orderData, setOrderData] = useState<OrderModel>({} as OrderModel);
    const [estimatedTimeOfArrival, setEstimatedTimeOfArrival] = useState<number>(30);
    const { last_order_status } = useSelector((state) => state.orders);
    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
    });

    const fetchOrderData = async () => {
        const { data } = await api.get(`/order/get_order_data/${orderId}`, {
            headers: {
                Authorization: `Bearer ${getAccessToken()?.access_token}`,
            },
        });
        setOrderData(data);
    };
    useEffect(() => {
        focusSelf();
        fetchOrderData();
        dispatch(trackLastOrder());
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/screens/tracking-page/Track_Order_Background.png',
                '',
            ]),
        );

        return () => {
            if (last_order_status === 3) {
                localStorage.removeItem('TrackLastOrder');
            }
        };
    }, []);

    useEffect(() => {
        switch (last_order_status) {
            case 0:
                setEstimatedTimeOfArrival(50);
                break;
            case 1:
                setEstimatedTimeOfArrival(50);
                break;
            case 2:
                setEstimatedTimeOfArrival(25);
                break;
            case 3:
                setEstimatedTimeOfArrival(10);
                break;
            case 4:
                setEstimatedTimeOfArrival(0);
                break;
        }
        if (last_order_status === 4) {
            localStorage.removeItem('TrackLastOrder');
            return;
        }

        const TrackLastOrderObject = {
            orderId: orderId,
            orderStatus: OrderStatusObject[last_order_status],
        };
        localStorage.setItem('TrackLastOrder', JSON.stringify(TrackLastOrderObject));
    }, [last_order_status]);

    const toggleBetweenOrderDetails = (action: 'plus' | 'minus') => {
        if (action === 'plus' && last_order_status < 4) {
            dispatch(setLastOrderStatus(last_order_status + 1));
        } else if (action === 'minus' && last_order_status > 0) {
            dispatch(setLastOrderStatus(last_order_status - 1));
        }
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="relative w-full h-full flex justify-evenly">
                <div className="absolute top-[3vw] left-[5vw] flex flex-col justify-center items-center">
                    <span className="text-[1.6vw]">{`Toggle Between Order Statuses!`}</span>
                    <TransparentButton
                        className="text-[1.6vw] w-[4vw]"
                        onClick={() => toggleBetweenOrderDetails('plus')}
                    >{`+`}</TransparentButton>
                    <TransparentButton
                        className="text-[1.6vw] w-[4vw]"
                        onClick={() => toggleBetweenOrderDetails('minus')}
                    >{`-`}</TransparentButton>
                    <TransparentButton
                        className="text-[1.6vw] w-[10vw]"
                        onClick={() => dispatch(trackLastOrder())}
                    >{`Track Last Order Button`}</TransparentButton>
                </div>
                <TransparentButton
                    className="w-[10vw] absolute top-[3vw] right-[3vw]"
                    onClick={() => navigate('/')}
                    focusKey="RETURN-HOME-PAGE-BUTTON"
                >
                    {trackOrderTranslateFunction('back_to_home')}
                </TransparentButton>
                <TransparentButton
                    className="w-[10vw] absolute top-[3vw] right-[15vw]"
                    onClick={() => setIsOrderDetailsOpen(!isOrderDetailsOpen)}
                >
                    {trackOrderTranslateFunction('order_details')}
                </TransparentButton>
                <div className="flex pt-[16vw]">
                    <TrackingStatusBar />
                    <MinutesToArrivalComponent MinutesToArrival={estimatedTimeOfArrival} />
                </div>
                {isOrderDetailsOpen && orderData._id && (
                    <OrderDetailsPopup orderData={orderData} focusKey="ORDER-DETAILS-POPUP" />
                )}
                {estimatedTimeOfArrival < 1 && <DidYouGetTheOrderPopup />}
            </div>
        </FocusContext.Provider>
    );
};

export default TrackOrderScreen;
