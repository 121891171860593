type KosherSymbolProps = {
    className?: string;
};
const KosherSymbol = ({ className }: KosherSymbolProps) => {
    return (
        <img
            className={`absolute left-[1vw] w-[2.5vw] h-[2.5vw] ${className}`}
            src="https://order.pizzahut.co.il/assets/img/kosher.svg"
            alt="Kosher Symbol"
        />
    );
};

export default KosherSymbol;
