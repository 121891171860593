import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { Extra } from '../../../../../redux/slices/dbFoodMenuSlice';
import { useSelector } from '../../../../../redux/store';
import ExtraPortionButton from './ExtraPortionButton';
import { useEffect } from 'react';

type ExtraPortionsContainerProps = {
    extraData: Extra;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ExtraPortionsContainer = ({ extraData, setIsOpen }: ExtraPortionsContainerProps) => {
    const chosenExtras = useSelector((state) => state.personalAssistant.chosenExtras);

    const { ref } = useFocusable({
        onBlur: () => {
            setIsOpen(false);
        },
        trackChildren: true,
        preferredChildFocusKey: `${extraData.name}-portion-button-0`,
    });

    const isSelectedFunction = (extraName: string, extraSize: string) => {
        if (!chosenExtras) {
            return false;
        }
        const chosenExtra = chosenExtras.find((extra) => extra.name === extraName);
        return chosenExtra && chosenExtra.extraSize === extraSize ? true : false;
    };

    return (
        <div ref={ref} className="flex justify-evenly w-[40%]">
            {['full', 'halfleft', 'halfright'].map((toppingPortion, index) => {
                return (
                    <ExtraPortionButton
                        key={`${extraData.name}-portion-button-${index}`}
                        extraData={extraData}
                        toppingPortion={toppingPortion}
                        focusKey={`${extraData.name}-extra-${toppingPortion}`}
                        isSelected={isSelectedFunction(extraData.name, toppingPortion)}
                    />
                );
            })}
        </div>
    );
};

export default ExtraPortionsContainer;
