import axios from 'axios';
import { getAccessToken, setAccessToken } from '../auth';
import { env } from '../../config/environment';

const api = axios.create({
    baseURL: env.BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(null, async (error) => {
    const config = error.config;
    if (!config._retryCount || config._retryCount < 3) {
        config._retryCount = config._retryCount ? config._retryCount + 1 : 1;

        if (error.response?.data?.message === 'Token expired') {
            config._retryCount += 1;
            const access_token = getAccessToken();

            if (!access_token) {
                return Promise.reject(error);
            }

            const refreshToken = access_token.refresh_token;

            if (!refreshToken) {
                return Promise.reject(error);
            }

            const { data } = await api.post('/auth/refresh_token', { refreshToken });

            setAccessToken(data);

            config.headers.Authorization = `Bearer ${data.access_token}`;

            return axios(config);
        }
    }

    return Promise.reject(error);
});

export default api;
