import createTranslateFunction from '../../i18n/createTranslateFunction';
import { useSelector } from '../../redux/store';

type StatusContainerProps = {
    containerStatusValue: string;
};

const StatusContainer = ({ containerStatusValue }: StatusContainerProps) => {
    const StatusContainerTranslation = createTranslateFunction('track_order.statuses');
    const statusIndexes = { pending: 1, in_progress: 2, on_delivery: 3, done: 4 };
    const { last_order_converted_status } = useSelector((state) => state.orders);

    const isCurrentStatusSelected = containerStatusValue === last_order_converted_status;
    return (
        <div
            className={`!z-[5] w-[37vw] my-[1vw] h-[6vw] px-[2vw] flex items-center text-center bg-[#ffffff2c] rounded-[2vw] ${isCurrentStatusSelected && '!bg-pizzahut-red'}`}
        >
            <span
                className={`w-[3.5vw] h-[3.5vw] flex justify-center items-center text-[1.8vw] rounded-full bg-pizzahut-red ml-[2vw] ${isCurrentStatusSelected && 'bg-white text-pizzahut-red'}`}
            >
                {statusIndexes[containerStatusValue]}
            </span>
            <span className="text-[1.6vw]">{StatusContainerTranslation(containerStatusValue)}</span>
        </div>
    );
};

export default StatusContainer;
