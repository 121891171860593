type ExtraPortionStickerProps = {
    toppingPortion: string;
};

const ExtraPortionSticker = ({ toppingPortion }: ExtraPortionStickerProps) => {
    const PortionSizesLinks = {
        full: 'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/full_topping.svg',
        halfright:
            'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfright_topping.svg',
        halfleft:
            'https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfleft_topping.svg',
        full_css: `justify-center`,
        halfright_css: `justify-start`,
        halfleft_css: `justify-end`,
    };

    return (
        <div>
            <div
                className={`w-[2.5vw] h-[2.5vw] bg-food-portion rounded-full flex ${PortionSizesLinks[`${toppingPortion}_css`]} items-center border-[0.1vw] bg-selected-hut-red  !border-red-600`}
            >
                <img
                    className="w-[55%] h-[55%]"
                    src={`${PortionSizesLinks[toppingPortion]}`}
                    alt="no svg of size"
                />
            </div>
        </div>
    );
};

export default ExtraPortionSticker;
