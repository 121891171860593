import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback } from 'react';
import OrderDetailsPopupRow from './OrderDetailsPopupRow';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import CartDeliveryPriceCard from '../../components/Cart/CartDeliveryPriceCard';
import { OrderModel } from '../../redux/slices/ordersSlice';

type OrderDetailsPopupProps = {
    focusKey: string;
    orderData: OrderModel;
};
const OrderDetailsPopup = ({ focusKey: focusKeyParam, orderData }: OrderDetailsPopupProps) => {
    const TranslateFunction = createTranslateFunction('checkout_screen.cart_popup');

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const onRowFocus = useCallback(
        ({ y }) => {
            console.log(y);
            if (ref.current) {
                ref.current.scrollTop = y;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );

    const shouldShowDeliveryPrice = () => {
        if (orderData.delivery_price && orderData.delivery_price > 0) {
            return orderData.delivery_price;
        } else {
            return false;
        }
    };

    const calculateTotalOrderPrice = () => {
        return (orderData.total_price || 0) + (orderData.delivery_price || 0);
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="h-full w-[30vw] absolute left-0 bg-black bg-opacity-[95%] flex flex-col items-center z-10">
                <span className="text-[2vw] mx-[1vw]">{`${TranslateFunction(`title`)}`}</span>
                <div ref={ref} className="mt-[2vh] w-[90%] h-[80%] overflow-y-auto">
                    {orderData.cart!.map((cartItem, index) => (
                        <OrderDetailsPopupRow
                            focusKey={`CARTITEM-${index}`}
                            key={index}
                            cartItem={cartItem}
                            onFocus={onRowFocus}
                        />
                    ))}
                    {shouldShowDeliveryPrice() && (
                        <CartDeliveryPriceCard deliveryPrice={orderData.delivery_price || 0} />
                    )}
                    {/* {couponCodeDiscount && (
                        <CartCouponCard couponDiscount={couponCodeDiscount}></CartCouponCard>
                    )} */}
                </div>
                <div className=" flex justify-between items-center w-[90%] p-[2vh]">
                    <span className="text-[1.7vw]">{`${TranslateFunction(`total`)}`}</span>
                    <span className="font-semibold text-[1.8vw]">{`₪${calculateTotalOrderPrice()}`}</span>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default OrderDetailsPopup;
