import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import RedButton from '../../../../components/Buttons/RedButton';
import OpenModalDownArrowSvg from '../../../../components/OpenModalDownArrowSvg';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { OrderTimeModal } from '../DeliveryFormModals/OrderTimeModal';
import { useSelector } from '../../../../redux/store';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';

type ChooseTimeOfOrderProps = {
    focusKey: string;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};
const ChooseTimeOfOrder = ({
    focusKey: focusKeyParam,
    handleDeliveryFormUpdation,
}: ChooseTimeOfOrderProps) => {
    const [orderTimeModalOpen, setOrderTimeModalOpen] = useState(false);

    const TranslateFunction = createTranslateFunction('checkout_screen.delivery_form');
    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const isOrderBranchSelected = deliveryFormData.branch_id ? true : false;

    const handleOrderTimeModal = () => {
        if (isOrderBranchSelected) {
            setOrderTimeModalOpen(true);
            setFocus(`ORDER-TIME-MODAL`);
        }
    };

    const formatHour = (date: Date): string => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const isThereBranchId = deliveryFormData.branch_id ? true : false;
    const isDelivery = deliveryFormData.order_type === 0 ? true : false;

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <RedButton
                    className={`${!isThereBranchId && '!bg-white !bg-opacity-10'} w-[80%] h-[4vw] rounded-[1.1vw] pr-[3vw] mb-[1vw]`}
                    onClick={() => handleOrderTimeModal()}
                    focusKey="ORDERTIME-BUTTON"
                >
                    <div className="flex justify-start items-center relative">
                        {isThereBranchId && (
                            <OpenModalDownArrowSvg className="absolute w-[2vw] h-[2vw] left-[0.6vw]" />
                        )}
                        <span className="text-[1.2vw] text-white text-start ml-[2vw]">
                            {isDelivery
                                ? TranslateFunction('modals.delivery_time.title')
                                : TranslateFunction('modals.pickup_time.title')}
                        </span>
                        {deliveryFormData.future_date && (
                            <span className="text-[1.2vw]">{`${formatHour(deliveryFormData.future_date)}`}</span>
                        )}
                    </div>
                </RedButton>
                {orderTimeModalOpen && (
                    <OrderTimeModal
                        focusKey="ORDER-TIME-MODAL"
                        handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        opened={orderTimeModalOpen}
                        close={() => {
                            setOrderTimeModalOpen(false);
                            // setFocus('DELIVERY-FORM-NOTE-INPUT');
                        }}
                        isDelivery={isDelivery}
                    />
                )}
            </div>
        </FocusContext.Provider>
    );
};

export default ChooseTimeOfOrder;
