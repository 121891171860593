import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Extra, Item, Sauce } from './dbFoodMenuSlice';

export type CartItem = {
    item: Item;
    extras: Extra[];
    sauces: Sauce[];
    restoredOrderId?: string;
};

type InitialState = {
    cart: CartItem[];
    isCartOpen: boolean;
};

const initialState: InitialState = {
    cart: JSON.parse(localStorage.getItem('cart') || '[]'),
    isCartOpen: false,
};

const cartSlice = createSlice({
    name: 'itemsCart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            const newItem = action.payload;
            state.cart.push(newItem);
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        removeFromCart: (state, action: PayloadAction<{ itemIndex: number }>) => {
            const { itemIndex } = action.payload;
            if (itemIndex >= 0) {
                state.cart.splice(itemIndex, 1);
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        removeFromCartById: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload;
            if (id.length > 0) {
                state.cart = state.cart.filter((item) => item.restoredOrderId !== id);
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        clearCart: (state) => {
            state.cart = [];
            localStorage.removeItem('cart');
            return state;
        },
        updateExtras: (
            state,
            action: PayloadAction<{ itemIndex: number; updatedCartItem: CartItem }>,
        ) => {
            const { itemIndex, updatedCartItem } = action.payload;
            if (state.cart[itemIndex]) {
                state.cart[itemIndex] = updatedCartItem;
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        openCartModal: (state) => {
            state.isCartOpen = true;
        },
        closeCartModal: (state) => {
            state.isCartOpen = false;
        },
        toggleCartModal: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },
    },
});

// Export actions
export const {
    addToCart,
    removeFromCart,
    removeFromCartById,
    clearCart,
    updateExtras,
    openCartModal,
    closeCartModal,
    toggleCartModal,
} = cartSlice.actions;

// Export reducer
export default cartSlice.reducer;
