import { createSlice } from '@reduxjs/toolkit';

interface couponCodeState {
    isCouponCodeOpen: boolean;
}

const initialCouponCodeState: couponCodeState = {
    isCouponCodeOpen: false,
};

const menuSlice = createSlice({
    name: 'couponCode',
    initialState: initialCouponCodeState,
    reducers: {
        openCouponCode(state) {
            state.isCouponCodeOpen = true;
        },
        toggleCouponCode(state) {
            state.isCouponCodeOpen = !state.isCouponCodeOpen;
        },
        closeCouponCode(state) {
            state.isCouponCodeOpen = false;
        },
    },
});
export const { openCouponCode, toggleCouponCode, closeCouponCode } = menuSlice.actions;
export default menuSlice.reducer;
