import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React from 'react';
import TransparentButton from '../TransparentButton';

type CounterProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    stateValue: number;
    setStateFunction: (value: React.SetStateAction<number>) => void;
    focusKey?: string;
    className?: string;
};

const Counter = ({
    focusKey: focusKeyParam,
    className,
    stateValue,
    setStateFunction,
}: CounterProps) => {
    const decrementOrIncrement = (increment: boolean) => {
        if (increment) {
            setStateFunction((prev) => prev + 1);
        } else if (!increment && stateValue > 1) {
            setStateFunction((prev) => prev - 1);
        }
    };
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={`${className}`}>
                <TransparentButton
                    focusKey="decrement-button"
                    onClick={() => decrementOrIncrement(false)}
                    className="w-[2.8vw] h-[2.8vw] text-[1.8vw] bg-opacity-40 text-center flex justify-center items-center !px-0 !py-0"
                    focusClassName="bg-opacity-60 scale-110"
                >
                    -
                </TransparentButton>
                <span className="text-[1.6vw]">{`${stateValue}`}</span>
                <TransparentButton
                    focusKey="increment-button"
                    onClick={() => decrementOrIncrement(true)}
                    className="w-[2.8vw] h-[2.8vw] text-[1.8vw] bg-opacity-40 text-center flex justify-center items-center !px-0 !py-0"
                    focusClassName="bg-opacity-60 scale-110"
                >
                    +
                </TransparentButton>
            </div>
        </FocusContext.Provider>
    );
};

export default Counter;
