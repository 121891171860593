import { CircularProgressbar } from 'react-circular-progressbar';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type MinutesToArrivalComponentParams = {
    MinutesToArrival: number;
};

const MinutesToArrivalComponent = ({ MinutesToArrival }: MinutesToArrivalComponentParams) => {
    const CircularTranslateFunction = createTranslateFunction('track_order');
    const totalTime = 60; // total time in minutes
    const progress = ((totalTime - MinutesToArrival) / totalTime) * 100;

    return (
        <div className="w-[20vw] h-[20vw] flex justify-center items-center rounded-full bg-white bg-opacity-10">
            <div className="w-[16vw] h-[16vw] flex flex-col justify-center items-center rounded-full bg-white bg-opacity-10 border-[1.45vw] border-white border-opacity-20">
                <span className="text-[4vw] font-semibold">{MinutesToArrival}</span>
                <span className="text-[2vw]">{CircularTranslateFunction('minutes')}</span>
                <CircularProgressbar
                    className="!z-10 absolute h-[15.88vw] w-[15.88vw]"
                    value={progress}
                    styles={{
                        path: {
                            stroke: '#C6102F',
                            strokeWidth: '0.23vw',
                            strokeLinecap: 'round',
                            transitionDuration: '0.5s',
                        },
                        trail: {
                            stroke: 'transparent',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default MinutesToArrivalComponent;
