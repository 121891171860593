import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppDispatch, useSelector } from '../../redux/store';
import { initiateSmsAuth, logout, verifySmsAuth } from '../../redux/slices/userAuthSlice';
import { useDispatch } from 'react-redux';
import { Loading } from '../../components/Loading';
import BackButton from '../../components/Buttons/BackButton';
import { AlertModal } from '../../components/ConfirmModal/AlertModal';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { fetchPastOrders } from '../../redux/slices/ordersSlice';
import TransparentButton from '../../components/Buttons/TransparentButton';
import FocusableOTPInput from '../../components/OTPInputs/FocusableOTPInput';

type LoginVerifyScreenProps = {
    focusKey: string;
};
const LoginVerifyScreen = ({ focusKey: focusKeyParam }: LoginVerifyScreenProps) => {
    const LoginVerifyTranslate = createTranslateFunction('login_verify');
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [smsExpiredModalOpen, setSmsExpiredModalOpen] = useState<boolean>(false);
    const [userNotFinishedBoardingOpen, setUserNotFinishedBoardingOpen] = useState<boolean>(false);
    const [verifyCodeInputValue, setVerifyCodeInputValue] = useState<string>('');
    const { ref, focusKey, focusSelf } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'login-verify-personal-otp-input',
    });

    const handleSmsExpired = () => {
        setSmsExpiredModalOpen(true);
        setFocus('SMS-EXPIRED-MODAL');
    };

    const userAuth = useSelector((state) => state.userAuth);

    const handleCodeSubmit = async () => {
        const userSession = userAuth.session;
        if (!userSession) {
            console.log('Failed');
            return;
        }
        const phoneNumber = localStorage.getItem('phoneNumber');
        if (phoneNumber) {
            await dispatch(
                verifySmsAuth({
                    phone_number: phoneNumber,
                    session: userSession,
                    code: verifyCodeInputValue,
                }),
            );
        }
    };

    const fetchUserPastOrdersFunction = async () => {
        await dispatch(fetchPastOrders());
    };

    const onBackButton = () => {
        localStorage.removeItem('session');
        dispatch(logout());
        navigate('/login');
    };
    const onUserNotFinishedBoarding = () => {
        localStorage.removeItem('session');
        dispatch(logout());
        navigate('/register');
    };
    const handleHaventReceivedCode = async () => {
        const phoneNumber = localStorage.getItem('phoneNumber');
        if (phoneNumber) {
            await dispatch(initiateSmsAuth({ phone_number: phoneNumber }));
            return;
        } else {
            navigate('/', { replace: true });
            return;
        }
    };

    useEffect(() => {
        focusSelf();
        const id = setTimeout(
            () => {
                handleSmsExpired();
            },
            14 * 60 * 1000,
        );
        setTimeoutId(id);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    useEffect(() => {
        if (userAuth.user?.auth) {
            if (userAuth.user.has_completed_onboarding === false) {
                setUserNotFinishedBoardingOpen(true);
                setFocus('USER-NOT-FINISHED-BOARDING-MODAL');
                return;
            }
            console.log('Got into the if successfuly!');
            fetchUserPastOrdersFunction();
            navigate('/');
        }
    }, [userAuth]);

    useEffect(() => {
        if (verifyCodeInputValue.length > 5) {
            handleCodeSubmit();
        }
    }, [verifyCodeInputValue]);

    return (
        <FocusContext.Provider value={focusKey}>
            {userAuth.loading === true ? (
                <Loading />
            ) : (
                <div
                    ref={ref}
                    className="flex flex-row-reverse justify-evenly bg-opacity-70 items-center h-full w-full"
                >
                    {smsExpiredModalOpen && (
                        <AlertModal
                            close={() => setSmsExpiredModalOpen(false)}
                            focusKey="SMS-EXPIRED-MODAL"
                            opened={smsExpiredModalOpen}
                            title={LoginVerifyTranslate('sms_expired_modal_title')}
                            addedCloseFunction={onBackButton}
                        />
                    )}
                    {userNotFinishedBoardingOpen && (
                        <AlertModal
                            close={() => setUserNotFinishedBoardingOpen(false)}
                            focusKey="USER-NOT-FINISHED-BOARDING-MODAL"
                            opened={userNotFinishedBoardingOpen}
                            title={LoginVerifyTranslate('user_didnt_finish_boarding')}
                            addedCloseFunction={onUserNotFinishedBoarding}
                        />
                    )}
                    <img
                        className="z-[1] absolute h-[6vw] bottom-[3vw] right-[3vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/pizzahut_logo.svg"
                        alt="Pizzahut Logo"
                    />
                    <BackButton onClick={onBackButton} className="absolute top-[4vw] right-[4vw]" />
                    <div className="z-[0] absolute bg-red-radial-to-transparent bg-opacity-60 rounded-full top-[-60vw] left-[-60vw] h-[120vw] w-[120vw]"></div>
                    <div className="flex flex-col items-start justify-center w-full h-full z-[1]">
                        <div
                            className={`flex flex-col items-center justify-center w-full mb-[2vw]`}
                        >
                            <span
                                className={`text-[2.4vw]`}
                            >{`${LoginVerifyTranslate('header')}`}</span>
                            <span className="text-[1.8vw]">{`${LoginVerifyTranslate('description')}`}</span>
                        </div>
                        <div className={`flex flex-col justify-center items-center w-full`}>
                            <div ref={ref} className="flex justify-center w-[80%] mb-[2vw]">
                                <FocusableOTPInput
                                    focusKey="login-verify-personal-otp-input"
                                    OTPLength={6}
                                    setOTPValue={setVerifyCodeInputValue}
                                />
                            </div>
                            <TransparentButton
                                focusKey="login-verify-submit-button"
                                onClick={handleCodeSubmit}
                                className="w-[30vw] my-[1vw] h-[5vw] !text-[1.8vw] !bg-white !bg-opacity-10 !rounded-[2vw]"
                                focusClassName="!scale-105 border-white border-[0.1vw]"
                            >{`${LoginVerifyTranslate('accept_button')}`}</TransparentButton>
                            <TransparentButton
                                focusKey="havent-received-code-button"
                                onClick={handleHaventReceivedCode}
                                className="w-[30vw] my-[1vw] h-[5vw] !text-[1.8vw] !bg-white !bg-opacity-10 !rounded-[2vw]"
                                focusClassName="!scale-105 border-white border-[0.1vw]"
                            >{`${LoginVerifyTranslate('havent_received_code')}`}</TransparentButton>
                        </div>
                    </div>
                </div>
            )}
        </FocusContext.Provider>
    );
};

export default LoginVerifyScreen;
