import React, { useEffect, useState } from 'react';

const DateAndTime = () => {
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 60000);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    //Function recieves a date, returns an object with: date: 29 Sep, 2023, time: 17:30
    function formatDate(date: Date): { date: string; time: string } {
        // Define options for date formatting
        const dateOptions: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };

        // Define options for time formatting
        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
        };

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat('he-IL', dateOptions).format(date);
        const formattedTime = new Intl.DateTimeFormat('he-IL', timeOptions).format(date);

        // Return the formatted date and time as an object
        return {
            date: formattedDate,
            time: formattedTime,
        };
    }
    return (
        <div className="absolute top-[2vw] left-[3vw] flex flex-col justify-center text-center z-auto">
            <p className="text-[2vw] font-semibold">{formatDate(date).time}</p>
            <p className="text-[1vw]">{formatDate(date).date}</p>
            {/* <FloatingDeliveryAddress /> */}
        </div>
    );
};

export default DateAndTime;
