import TransperentInput from '../../../../components/Inputs/TransperentInput';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { useDispatch } from 'react-redux';
import { UpdateDeliveryFormData } from '../../../../redux/slices/deliveryFormSlice';
import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';

type OrderNotesInputProps = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
};

const OrderNotesInput = ({ focusKey: focusKeyParam, onRowFocus }: OrderNotesInputProps) => {
    const OrderNotesTranslateFunction = createTranslateFunction(
        'checkout_screen.delivery_form.modals.note',
    );
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: onRowFocus,
    });
    const dispatch = useDispatch();
    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(UpdateDeliveryFormData({ notes: event.target.value }));
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="flex flex-col">
                <span className="text-[1.4vw] text-gray-400">{`${OrderNotesTranslateFunction('title')}`}</span>
                <TransperentInput
                    handleNoteChange={handleNoteChange}
                    placeholderValue={OrderNotesTranslateFunction('placeholder')}
                    focusKey="CHECKOUT-NOTE-INPUT"
                    className="w-[80%] h-[7vw] mt-[1vw] pr-[3vw] rounded-[1.2vw] text-start"
                />
            </div>
        </FocusContext.Provider>
    );
};

export default OrderNotesInput;
