import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';
import { Extra } from '../../redux/slices/dbFoodMenuSlice';

type FoodToppingPortionButtonProps = {
    topping: Extra;
    toppingPortion: string;
    focusKey: string;
    isSelected: boolean;
    onSelectPizzaTopping: (topping: Extra, extraSize: string) => void;
};

const FoodToppingPortionButton = ({
    focusKey: focusKeyParam,
    topping,
    toppingPortion,
    isSelected,
    onSelectPizzaTopping,
}: FoodToppingPortionButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleToppingPortionClick = () => {
        onSelectPizzaTopping(topping, toppingPortion);
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onEnterPress: handleToppingPortionClick,
    });

    return (
        <div
            ref={ref}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {toppingPortion === 'full' && (
                <button
                    className={`w-[3vw] h-[3vw] bg-food-portion rounded-full flex justify-center items-center border-4 ${(focused || isHovered) && 'bg-selected-hut-red'} ${isSelected && `bg-selected-hut-red border-selected-hut-red`} ${focused && isSelected && `!border-red-600`}`}
                    ref={ref}
                    onClick={handleToppingPortionClick}
                >
                    <img
                        className="w-[1.6vw] h-[1.6vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/full_topping.svg"
                        alt="no svg"
                    />
                </button>
            )}
            {toppingPortion === 'halfright' && (
                <button
                    className={`w-[3vw] h-[3vw] bg-food-portion rounded-full flex justify-start items-center border-4 ${(focused || isHovered) && 'bg-selected-hut-red'}  ${isSelected && `bg-selected-hut-red border-selected-hut-red`} ${focused && isSelected && `!border-red-600`}`}
                    ref={ref}
                    onClick={handleToppingPortionClick}
                >
                    <img
                        className="w-[1.6vw] h-[1.6vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfright_topping.svg"
                        alt="no svg"
                    />
                </button>
            )}
            {toppingPortion === 'halfleft' && (
                <button
                    className={`w-[3vw] h-[3vw] bg-food-portion rounded-full flex justify-end items-center border-4 ${(focused || isHovered) && 'bg-selected-hut-red'}  ${isSelected && `bg-selected-hut-red border-selected-hut-red`} ${focused && isSelected && ` !border-red-600`}`}
                    ref={ref}
                    onClick={handleToppingPortionClick}
                >
                    <img
                        className="w-[1.6vw] h-[1.6vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfleft_topping.svg"
                        alt="no svg"
                    />
                </button>
            )}
        </div>
    );
};

export default FoodToppingPortionButton;
