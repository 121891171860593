import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import PastOrderTimeAndDate from './PastOrderTimeAndDate';
import { useLocation } from 'react-router-dom';
import PastOrderCartPopup from './PastOrderCartPopUp/PastOrderCartPopup';
import { useDispatch } from 'react-redux';
import { setAppBackground, setAppBackgroundTwClass } from '../../../../redux/slices/layoutSlice';

type RestorePastOrderScreenProps = {
    focusKey: string;
};
const RestorePastOrderScreen = ({ focusKey: focusKeyParam }: RestorePastOrderScreenProps) => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { pastOrder } = state;
    const { ref, focusKey, focused } = useFocusable({
        focusKey: focusKeyParam,
    });

    useEffect(() => {
        setFocus('PAST-ORDER-CART-ITEM-0');
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                'bg-no-repeat bg-cover bg-opacity-95',
            ]),
        );
    }, [focused]);
    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex w-full h-full" ref={ref}>
                <PastOrderTimeAndDate pastOrder={pastOrder} />
                <PastOrderCartPopup focusKey="PAST-ORDER-CART-POPUP" pastOrder={pastOrder} />
            </div>
        </FocusContext.Provider>
    );
};

export default RestorePastOrderScreen;
