import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useSelector } from '../../../../redux/store';
import RedButton from '../../../../components/Buttons/RedButton';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';
import { useMemo, useState } from 'react';
import OpenModalDownArrowSvg from '../../../../components/OpenModalDownArrowSvg';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import KosherSymbol from '../../../../components/KosherSymbol';
import { BranchPickupModal } from '../DeliveryFormModals/BranchPickupModal';

type PickupInputsType = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};
const PickupInputs = ({
    focusKey: focusKeyParam,
    onRowFocus,
    handleDeliveryFormUpdation,
}: PickupInputsType) => {
    const [branchPickupModalOpen, setBranchPickupModalOpen] = useState(false);

    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);
    const deliveryAddress = useSelector((state) => state.deliveryFormData.deliveryForm.address);
    const { branchesList } = useSelector((state) => state.branches);

    const TranslateFunction = createTranslateFunction('checkout_screen.delivery_form');

    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: onRowFocus,
    });
    const handleBranchPickupModal = () => {
        setBranchPickupModalOpen(true);
        setFocus(`BRANCH-PICKUP-MODAL`);
    };

    const branchName = useMemo(() => {
        return branchesList.find(
            (branch) => branch.external_branch_id == deliveryFormData.branch_id,
        )?.atmos_data.name;
    }, [branchesList, deliveryFormData.branch_id]);

    const isKosher = () => {
        if (deliveryAddress?.closest_branch?.atmos_data?.kosher) {
            return true;
        }
        return false;
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <RedButton
                    // onBtnFocus={onRowFocus}
                    className="w-[80%] h-[4vw] mb-[1.5vw] rounded-[1.1vw] pr-[3vw]"
                    onClick={handleBranchPickupModal}
                    focusKey="BRANCH-PICKUP-BUTTON"
                >
                    <div className="flex justify-start items-center relative">
                        <OpenModalDownArrowSvg className="absolute w-[2vw] h-[2vw] left-[0.6vw]" />
                        <span className="text-[1.2vw] text-white text-start ml-[2vw]">
                            {deliveryFormData.branch_id
                                ? TranslateFunction('modals.pickup_branch.sub_title')
                                : TranslateFunction('modals.pickup_branch.title')}
                            {deliveryFormData.branch_id && (
                                <span className="text-[1.2vw] mr-[2vw]">{branchName}</span>
                            )}
                        </span>
                        {isKosher() && <KosherSymbol className="!left-[3vw]" />}
                    </div>
                </RedButton>
                {branchPickupModalOpen && (
                    <BranchPickupModal
                        focusKey="BRANCH-PICKUP-MODAL"
                        handleDeliveryFormUpdation={handleDeliveryFormUpdation}
                        opened={branchPickupModalOpen}
                        close={() => setBranchPickupModalOpen(false)}
                    />
                )}
            </div>
        </FocusContext.Provider>
    );
};

export default PickupInputs;
