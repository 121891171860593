import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import { SuggestedItemToKeep } from './SuggestedItemToKeep';
import { AddItemToCartButton } from './AddItemToCartButton';
import { DiscardItemButton } from './DiscardItemButton';

type SuggestedItemAndButtonsProps = {
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    handleResetPageFunction: () => void;
};

export function SuggestedItemAndButtons({
    onFocus,
    handleResetPageFunction,
}: SuggestedItemAndButtonsProps) {
    const [isHovered, setIsHovered] = useState(false);

    const { ref, focused, focusKey } = useFocusable({
        onFocus,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ref={ref}
                className={`mb-[2vw] flex flex-col justify-center items-center`}
            >
                <SuggestedItemToKeep />
                <div className="w-[28vw] mt-[1vw] flex justify-between items-center">
                    <AddItemToCartButton
                        handleResetPageFunction={handleResetPageFunction}
                        focusKey={'add-suggested-item-to-cart-button'}
                    />
                    <DiscardItemButton
                        handleResetPageFunction={handleResetPageFunction}
                        focusKey={'discard-suggested-item-button'}
                    />
                </div>
            </div>
        </FocusContext.Provider>
    );
}
