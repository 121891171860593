import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFocusable, FocusContext, setFocus } from '@noriginmedia/norigin-spatial-navigation';
import { useDispatch } from 'react-redux';
import { CartItem } from '../../redux/slices/cartSlice';
import { Extra, Sauce } from '../../redux/slices/dbFoodMenuSlice';
import { AlertModal } from '../../components/ConfirmModal/AlertModal';
import { setAppBackground, setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import { Loading } from '../../components/Loading';
import { handlePreloadImagesFunction, titleForAlertModal } from './EditFoodScreenHelperFunctions';
import ExtrasSide from './ExtrasSide';
import ItemSide from './ItemSide';

type EditFoodScreenProps = {
    focusKey: string;
};

export const EditFoodScreen = ({ focusKey: focusKeyParam }: EditFoodScreenProps) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { item, cartIndex, categoryName } = state;
    const [currentItem, setCurrentItem] = useState<CartItem>(item);
    const [pizzaToppingsArray, setPizzaToppingsArray] = useState<Extra[]>(currentItem.extras ?? []);
    const [itemToppingsArray, setItemToppingsArray] = useState<Extra[]>(currentItem.extras ?? []);
    const [saucesArray, setSaucesArray] = useState<Sauce[]>(
        currentItem.item?.sauces ? currentItem.item.sauces : [],
    );
    const [isMaxToppingsAlertModalOpen, setIsMaxToppingsAlertModalOpen] = useState<boolean>(false);
    const [notEnoughToppingsAlertModalOpen, setNotEnoughToppingsAlertModalOpen] =
        useState<boolean>(false);
    const [extrasImagesLoaded, setExtrasImagesLoaded] = useState<boolean>(false);

    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
        preferredChildFocusKey: 'ADD-TO-CART-BUTTON',
    });

    const IsCategoryPizza = () => {
        return categoryName === 'פיצות';
    };

    const doesProductHaveToppingsOrSauces =
        currentItem.item?.extras?.length > 0 || currentItem.item?.sauces?.length > 0;

    useEffect(() => {
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/blurred.jpg',
                '',
            ]),
        );
    }, []);

    useEffect(() => {
        setCurrentItem({ ...currentItem, extras: pizzaToppingsArray });
    }, [pizzaToppingsArray]);

    useEffect(() => {
        setCurrentItem({ ...currentItem, extras: itemToppingsArray });
    }, [itemToppingsArray]);

    useEffect(() => {
        if (currentItem.item.sauces) {
            const filteredSauces = saucesArray.filter(
                (sauce) => sauce.sauceQuantity && sauce.sauceQuantity > 0,
            );
            setCurrentItem({ ...currentItem, sauces: filteredSauces });
        } else {
            setCurrentItem({ ...currentItem, sauces: [] });
        }
    }, [saucesArray]);

    useEffect(() => {
        if (!IsCategoryPizza()) {
            setExtrasImagesLoaded(true);
            return;
        }
        if (currentItem && !extrasImagesLoaded) {
            console.log(currentItem);
            handlePreloadImagesFunction(currentItem, () => setExtrasImagesLoaded(true));
        }
    }, [currentItem]);

    useEffect(() => {
        if (doesProductHaveToppingsOrSauces) {
            setFocus('EDITFOODSCREEN-EXTRAS-SIDE');
        } else {
            focusSelf();
        }
    }, [extrasImagesLoaded]);

    if (!extrasImagesLoaded) {
        return <Loading />;
    }

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className={`h-full w-full flex justify-center relative`}>
                {(isMaxToppingsAlertModalOpen || notEnoughToppingsAlertModalOpen) && (
                    <AlertModal
                        focusKey="MAX-TOPPINGS-ALERT"
                        title={titleForAlertModal(
                            isMaxToppingsAlertModalOpen,
                            notEnoughToppingsAlertModalOpen,
                            categoryName,
                        )}
                        opened={isMaxToppingsAlertModalOpen || notEnoughToppingsAlertModalOpen}
                        close={() => {
                            setIsMaxToppingsAlertModalOpen(false);
                            setNotEnoughToppingsAlertModalOpen(false);
                        }}
                    />
                )}
                <ItemSide
                    focusKey="EDITFOODSCREEN-ITEM-SIDE"
                    IsCategoryPizza={IsCategoryPizza}
                    cartIndex={cartIndex}
                    categoryName={categoryName}
                    currentItem={currentItem}
                    pizzaToppingsArray={pizzaToppingsArray}
                    itemToppingsArrayLength={itemToppingsArray.length}
                    setNotEnoughToppingsAlertModalOpen={setNotEnoughToppingsAlertModalOpen}
                />
                {doesProductHaveToppingsOrSauces ? (
                    <ExtrasSide
                        categoryName={categoryName}
                        focusKey="EDITFOODSCREEN-EXTRAS-SIDE"
                        itemToppingsArray={itemToppingsArray}
                        pizzaToppingsArray={pizzaToppingsArray}
                        currentItem={currentItem}
                        saucesArray={saucesArray}
                        setSaucesArray={setSaucesArray}
                        setPizzaToppingsArray={setPizzaToppingsArray}
                        setIsMaxToppingsAlertModalOpen={setIsMaxToppingsAlertModalOpen}
                        setItemToppingsArray={setItemToppingsArray}
                        IsCategoryPizza={IsCategoryPizza}
                    />
                ) : null}
            </div>
        </FocusContext.Provider>
    );
};
