import { useEffect } from 'react';
import {
    AtmosDayHours,
    BranchesOperationHours,
    BranchTimeRange,
    WorkingHoursProperties,
} from '../../redux/slices/branchesSlice';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type OpeningHoursTableProps = {
    openingHours: WorkingHoursProperties;
};
type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
type DaySchedule = {
    day: string;
    openingTime: string;
    closingTime: string;
};
const OpeningHoursTable = ({ openingHours }: OpeningHoursTableProps) => {
    const TranslateFunction = createTranslateFunction('chosen_branch_screen');
    const openingHoursData = convertOpeningHoursToArray(openingHours);
    function convertOpeningHoursToArray(openingHours: WorkingHoursProperties): DaySchedule[] {
        // Map of English day names to Hebrew
        const daysMap: Record<DayOfWeek, string> = {
            sunday: 'ראשון',
            monday: 'שני',
            tuesday: 'שלישי',
            wednesday: 'רביעי',
            thursday: 'חמישי',
            friday: 'שישי',
            saturday: 'שבת',
        };

        // Order of days to maintain consistent sorting
        const daysOrder: DayOfWeek[] = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ];

        // Helper function to format a day's schedule
        const formatDaySchedule = (day: DayOfWeek, dayInfo: AtmosDayHours): DaySchedule[] => {
            const dayName = daysMap[day];
            if (dayInfo.all_day) {
                return [{ day: dayName, openingTime: '00:00', closingTime: '23:59' }];
            } else {
                return dayInfo.times.map((time) => ({
                    day: dayName,
                    openingTime: time.start,
                    closingTime: time.end,
                }));
            }
        };

        // Convert operation hours to an array of schedules
        const convertOperationHoursToSchedules = (
            operationHours: BranchesOperationHours,
        ): DaySchedule[] => {
            return daysOrder.reduce((schedules: DaySchedule[], day: DayOfWeek) => {
                const dayInfo = operationHours.days[day];
                if (dayInfo.active) {
                    return schedules.concat(formatDaySchedule(day, dayInfo));
                }
                return schedules;
            }, []);
        };

        // Convert delivery hours and return the result
        return convertOperationHoursToSchedules(openingHours.delivery);
    }
    console.log(openingHoursData, 'This is the opening hours data');

    return (
        <div className="flex flex-col">
            <span className="text-[3vw] mb-[1vw]">{TranslateFunction('opening_hours')}</span>
            {openingHoursData.map((DayOfWeek) => (
                <div
                    className="flex flex-col items-center justify-center mx-[1vw]"
                    key={DayOfWeek.day}
                >
                    <p className="text-[1.6vw]">
                        {TranslateFunction(`day`, { day: DayOfWeek.day })}
                    </p>
                    <p className="text-[1.4vw]">
                        {`${DayOfWeek.closingTime} - ${DayOfWeek.openingTime}`}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default OpeningHoursTable;
