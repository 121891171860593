import React, { useRef, useCallback, useState } from 'react';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { ISauceAsset } from '../../constants/dummy';
import SaladSauce from './SaladSauce';
import { Sauce } from '../../redux/slices/dbFoodMenuSlice';
import createTranslateFunction from '../../i18n/createTranslateFunction';

type SaladSaucesListProps = {
    focusKey: string;
    saucesArray: Sauce[];
    setSauces: React.Dispatch<React.SetStateAction<Sauce[]>>;
};
export const SaladSaucesList = ({
    focusKey: focusKeyParam,
    saucesArray,
    setSauces,
}: SaladSaucesListProps) => {
    const saladSaucesListTranslateFunction = createTranslateFunction('edit_food_screen.sauces');
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        trackChildren: true,
    });
    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (scrollingRef.current) {
                scrollingRef.current.scrollLeft = x;
                scrollingRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [scrollingRef],
    );

    const changeSauceQuantity = (sauceName: string, sauceQuantity: number) => {
        setSauces((prevArray) => {
            const updatedArray = [...prevArray];
            updatedArray[
                updatedArray.findIndex((sauce) => sauce.name === sauceName)
            ].sauceQuantity = sauceQuantity;
            return updatedArray;
        });
    };

    const calculateSaucesQuantity = () => {
        return saucesArray.reduce((acc, sauce) => acc + sauce.sauceQuantity, 0);
    };

    const totalSaucesQuantity = calculateSaucesQuantity();

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="overflow-y-auto" ref={ref}>
                <div className="flex flex-col my-[1vw]">
                    <span className="text-[2vw]">{saladSaucesListTranslateFunction('title')}</span>
                    <span className="text-[1.6vw]">
                        {saladSaucesListTranslateFunction('sub_title')}
                    </span>
                </div>
                <div
                    className={`flex flex-row flex-wrap justify-start overflow-y-auto`}
                    ref={scrollingRef}
                >
                    {saucesArray.map((sauce, index) => (
                        <SaladSauce
                            key={index}
                            sauce={sauce}
                            saucesArray={saucesArray}
                            sauceIndex={index}
                            totalSaucesQuantity={totalSaucesQuantity}
                            changeSauceQuantity={changeSauceQuantity}
                            onFocus={onAssetFocus}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
