const PersonalAssistantLoader = () => {
    return (
        <div className="absolute bottom-[3vw] left-[50%] z-[50]">
            <img
                className="w-[10vw] h-[10vw] object-cover rounded-full bg-transparent"
                src="https://i.gifer.com/XDZT.gif"
                alt=""
            />
        </div>
    );
};

export default PersonalAssistantLoader;
