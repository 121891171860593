import { Modal } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import TransparentButton from '../../../../components/Buttons/TransparentButton';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import { useSelector } from '../../../../redux/store';
import { IconX } from '@tabler/icons-react';
import TransperentInput from '../../../../components/Inputs/TransperentInput';
import { BranchProperties } from '../../../../redux/slices/branchesSlice';
import { UpdateDeliveryFormDataType } from '../../../../redux/slices/deliveryFormSlice';

type BranchPickupModalProps = {
    opened: boolean;
    close: () => void;
    focusKey: string;
    handleDeliveryFormUpdation: (updates: UpdateDeliveryFormDataType) => void;
};

export const BranchPickupModal = ({
    opened,
    close,
    focusKey,
    handleDeliveryFormUpdation,
}: BranchPickupModalProps) => {
    const TranslateFunction = createTranslateFunction(
        'checkout_screen.delivery_form.modals.pickup_branch',
    );
    const branches = useSelector((state) => state.branches.branchesList);
    const [filteredBranchesList, setFilteredBranchesList] = useState<BranchProperties[]>(branches);
    const [searchedBranchName, setSearchedBranchName] = useState<string>('');

    const { ref } = useFocusable({
        focusKey,
        isFocusBoundary: true,
        focusBoundaryDirections: ['down', 'up', 'right', 'left'],
        preferredChildFocusKey: 'PICKUP-BRANCH-SEARCH',
    });
    const modalContainerRef = useRef<HTMLDivElement>(null);
    const onRowFocus = useCallback(
        ({ y }) => {
            if (modalContainerRef.current) {
                modalContainerRef.current.scrollTop = y - 200;
                modalContainerRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [modalContainerRef],
    );

    const handleScrollToTop = useCallback(() => {
        if (modalContainerRef.current) {
            modalContainerRef.current.scrollTop = 0;
            modalContainerRef.current.style.scrollBehavior = 'smooth';
        }
    }, [modalContainerRef]);

    useEffect(() => {
        const tempBranches = [...branches];
        const filtered = tempBranches.filter((branch) =>
            branch.atmos_data.name.toLowerCase().includes(searchedBranchName.toLowerCase()),
        );
        setFilteredBranchesList(filtered);
    }, [searchedBranchName]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref}>
                <Modal
                    opened={opened}
                    onClose={close}
                    centered={true}
                    size={`40vw`}
                    radius={`3vw`}
                    withCloseButton={false}
                    overlayProps={{
                        backgroundOpacity: 0.01,
                        blur: 5,
                    }}
                    styles={{
                        header: {
                            backgroundColor: 'black',
                        },
                        content: {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <div
                        className="bg-black flex flex-col items-center overflow-y-auto max-h-[30vw] p-[2vw]"
                        ref={modalContainerRef}
                    >
                        <TransparentButton
                            className="absolute top-[1vw] right-[2vw] w-[3.3vw] h-[3.3vw] flex items-center justify-center"
                            onClick={() => close()}
                        >
                            <IconX size={'1.7vw'} stroke={2} />
                        </TransparentButton>
                        <span className="text-white text-[2vw] mb-[1vw]">{`${TranslateFunction(`title`)}`}</span>
                        <TransperentInput
                            focusKey="PICKUP-BRANCH-SEARCH"
                            handleScrollToTop={handleScrollToTop}
                            setStateValue={setSearchedBranchName}
                            placeholderValue="הקלד את שם הסניף"
                        ></TransperentInput>
                        {filteredBranchesList.map((branch, index) => (
                            <TransparentButton
                                key={index}
                                className="mt-[0.6vw] border-black border-2 text-black !py-[1vw] !text-[1.7vw] w-[30vw] rounded-[3vw]"
                                focusClassName={`!bg-pizzahut-red !text-white`}
                                focusKey={`PICKUP-BRANCH-${index}`}
                                onClick={() => {
                                    handleDeliveryFormUpdation({
                                        branch_id: branch.external_branch_id,
                                    });
                                    close();
                                }}
                                onBtnFocus={onRowFocus}
                            >
                                {branch.atmos_data.name}
                            </TransparentButton>
                        ))}
                    </div>
                </Modal>
            </div>
        </FocusContext.Provider>
    );
};
