import { setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from '../../redux/store';
import { useLocation } from 'react-router-dom';

interface SidebarItemProps {
    focusKey: string;
    icon: string;
    label: string;
    displayLabel: boolean;
    onPressed: () => void;
    onFocused: () => void;
    previousFocus: string;
    whatSidebarIsFocused: string;
    setWhatSidebarIsFocused: (value: React.SetStateAction<string>) => void;
    isSideMenuOpen: boolean;
    isSidebarFocused: boolean;
}

export function SidebarItem({
    focusKey,
    label,
    icon,
    displayLabel,
    onPressed,
    onFocused,
    previousFocus,
    whatSidebarIsFocused,
    setWhatSidebarIsFocused,
    isSideMenuOpen,
    isSidebarFocused,
}: SidebarItemProps) {
    const [isHovered, setIsHovered] = useState(false);
    const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
    //A function that checks if the pressed arrow key is left, and if it is, sets the focus to the side food menu, so it will not move to homepage.
    const onArrowPress = (direction: string) => {
        if (isMenuOpen && direction === 'left') {
            setFocus('SIDEFOODMENU');
        }
        if (!isMenuOpen && ['right', 'left'].includes(direction)) {
            console.log('previousFocus', previousFocus);
            setFocus(previousFocus);
        }
        return true;
    };
    const { ref, focused } = useFocusable({
        focusKey: focusKey,
        onEnterPress: onPressed,
        onArrowPress: onArrowPress,
    });

    useEffect(() => {
        if (focused) {
            onFocused();
            setWhatSidebarIsFocused(label);
        }
        if (isHovered) {
            setWhatSidebarIsFocused(label);
        }
    }, [focused, isHovered]);

    const shouldDisplayIcon = useCallback(() => {
        if (!isSidebarFocused || focused || (isSideMenuOpen && label === 'תפריט')) return true;
    }, [isSidebarFocused, focused]);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={ref}
            className={`bg-white h-[4vw] w-[4vw] bg-opacity-0 cursor-pointer justify-center box-border rounded-full flex flex-row mb-[2vh] transition-all duration-400 ease-in-out
      ${(focused || isHovered) && '!bg-opacity-20 !w-[10vw]'} ${whatSidebarIsFocused === label && 'bg-opacity-20'} ${isSideMenuOpen && label === 'תפריט' && `!w-[10vw]`}`}
            data-testid="menu-item"
            onClick={onPressed}
        >
            {shouldDisplayIcon() && (
                <div className="flex items-center justify-center">
                    <img className="w-[1.4vw]" src={`${icon}`} alt={''} />
                </div>
            )}
            {displayLabel && (
                <div className="mr-[1vw] flex justify-evenly items-center">
                    <span className="font-semibold text-white text-[2vh] text-center flex items-center">
                        {label}
                    </span>
                </div>
            )}
        </div>
    );
}
