import {
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { MenuCategory } from '../../../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../../redux/store';
import {
    UpdateChooseSubCategoryQuery,
    UpdateChosenSubCategory,
} from '../../../../redux/slices/personalAssistantSlice';
import SelectedOptionCheckmark from '../ChooseCategory/SelectedOptionCheckmark';
import UnselectedOptionCheckmark from '../ChooseCategory/UnselectedOptionCheckmark';

type SubCategoryCardProps = {
    subCategoryData: MenuCategory;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
};

export function SubCategoryCard({
    subCategoryData,
    onFocus,
    setCurrentFocusIndex,
    componentIndex,
}: SubCategoryCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const chosenSubCategory = useSelector((state) => state.personalAssistant.chosenSubCategory);

    const isChosen =
        chosenSubCategory && chosenSubCategory.name === subCategoryData.name ? true : false;
    const dispatch = useDispatch<AppDispatch>();

    const handleCategoryCardClick = () => {
        dispatch(UpdateChosenSubCategory(subCategoryData));
        dispatch(UpdateChooseSubCategoryQuery(subCategoryData.name));
        setIsHovered(false);
        setFocus('STT-CHOOSE-ITEM');
    };
    const { ref, focused } = useFocusable({
        onFocus,
        onEnterPress: handleCategoryCardClick,
    });

    useEffect(() => {
        if (focused || isHovered) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused, isHovered]);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleCategoryCardClick}
            ref={ref}
            className={`w-[17vw] h-[9vw] ml-[1.6vw] px-[0.5vw] rounded-categoryRadius cursor-pointer bg-[#222222] bg-opacity-60 flex flex-row relative justify-evenly items-center ${
                (focused || isHovered || isChosen) && '!bg-pizzahut-red !bg-opacity-100'
            }  ${(focused || isHovered) && isChosen && `border-[0.1vw] border-white`}`}
        >
            {(focused || isHovered || isChosen) && <SelectedOptionCheckmark />}
            <img
                className={`rounded-full w-[7.5vw] h-[7.5vw] object-cover`}
                src={subCategoryData.icon || '/images/pizzas/pan.jpeg'}
                alt=""
            />
            <span className={`overflow-hidden text-[1.3vw] max-w-[8vw]`}>
                {subCategoryData.name}
            </span>
        </div>
    );
}
