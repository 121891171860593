import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook } from 'react-redux';
import { useSelector as useReduxSelector } from 'react-redux';

import ordersReducer from './slices/ordersSlice';
import menuReducer from './slices/menuSlice';
import dbFoodMenuReducer from './slices/dbFoodMenuSlice';
import userAuthReducer from './slices/userAuthSlice';
import cartReducer from './slices/cartSlice';
import couponCodeReducer from './slices/couponCode';
import layoutReducer from './slices/layoutSlice';
import branchesReducer from './slices/branchesSlice';
import textToSpeechReducer from './slices/textToSpeechSlice';
import deliveryFormDataReducer from './slices/deliveryFormSlice';
import personalAssistantReducer from './slices/personalAssistantSlice';

export const store = configureStore({
    reducer: {
        orders: ordersReducer,
        menu: menuReducer,
        dbFoodMenu: dbFoodMenuReducer,
        couponCode: couponCodeReducer,
        userAuth: userAuthReducer,
        cart: cartReducer,
        layout: layoutReducer,
        branches: branchesReducer,
        textToSpeech: textToSpeechReducer,
        deliveryFormData: deliveryFormDataReducer,
        personalAssistant: personalAssistantReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type AppStates = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<AppStates> = useReduxSelector;
