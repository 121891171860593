import {
    FocusContext,
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useState } from 'react';
import { useSelector } from '../../../../redux/store';
import { getImageUrl } from '../../../EditFoodScreen/EditFoodScreenHelperFunctions';
import { Extra, ExtraSizesType } from '../../../../redux/slices/dbFoodMenuSlice';
import { convertExtrasToString } from '../../PersonalAssistantHelperFunctions';
import ToppingExtraImage from '../../../EditFoodScreen/ToppingExtraImage';

type SuggestedItemToKeepProps = {
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};

export function SuggestedItemToKeep({ onFocus }: SuggestedItemToKeepProps) {
    const [isHovered, setIsHovered] = useState(false);
    const chosenItem = useSelector((state) => state.personalAssistant.chosenItem);
    const chooseToKeepItem = useSelector((state) => state.personalAssistant.chooseToKeepItem);

    const handleSuggestedItemToKeepClick = () => {
        // dispatch(UpdateChosenItem());
        setIsHovered(false);
    };
    const { ref, focused, focusKey } = useFocusable({
        onFocus,
        onEnterPress: handleSuggestedItemToKeepClick,
    });
    const chosenItemIcon =
        chosenItem && chosenItem.icon
            ? chosenItem.icon
            : 'https://i.ibb.co/Fz90kTc/Plain-pizza-5pizza.png';

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleSuggestedItemToKeepClick}
                ref={ref}
                className={`w-[28vw] h-[25vw] relative flex flex-col justify-evenly items-center rounded-categoryRadius cursor-pointer bg-[#222222] bg-opacity-60  ${
                    (focused || isHovered) && '!bg-pizzahut-red !bg-opacity-100'
                } `}
            >
                <div
                    className={`absolute top-[-10vw] bg-center bg-cover w-[26vw] h-[26vw] flex justify-center items-center`}
                    style={{ backgroundImage: `url(${chosenItemIcon})` }}
                >
                    <div className="w-full h-full relative">
                        {chooseToKeepItem?.extras
                            ?.slice(0, 3)
                            .map((topping: Extra, key: number) => {
                                return (
                                    <ToppingExtraImage
                                        key={topping.name + key}
                                        url={getImageUrl(topping.name, topping.extraSize ?? 'full')}
                                        menuItemName={`${chosenItem?.name}`}
                                        toppingSize={
                                            (topping.extraSize as ExtraSizesType) ?? 'full'
                                        }
                                    />
                                );
                            })}
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center max-w-[80%] absolute bottom-[11%]">
                    <div className="flex">
                        <span className="text-[1.7vw]">{`${chosenItem?.name} `}</span>
                        <span className="text-[1.7vw]">{` - ${chosenItem?.price}₪`}</span>
                    </div>
                    {chosenItem?.description && (
                        <span className="text-[1.2vw] mb-[0.3vw] text-gray-400 text-center">{`${chosenItem?.description}`}</span>
                    )}
                    {chosenItem?.extras && (
                        <span className="text-[1.3vw] text-center">{`${convertExtrasToString(chooseToKeepItem?.extras ?? [])}`}</span>
                    )}
                </div>
            </div>
        </FocusContext.Provider>
    );
}
