import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

type SpeechToTextButtonProps = {
    focusKey: string;
    startClickFunction: () => void;
    stopClickFunction: () => void;
    isListening: boolean;
    speechToTextButtonClassName?: string;
};
const SpeechToTextButton = ({
    focusKey: focusKeyParam,
    startClickFunction,
    stopClickFunction,
    isListening,
    speechToTextButtonClassName,
}: SpeechToTextButtonProps) => {
    const { ref, focusKey, focused } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
        onEnterPress: isListening ? stopClickFunction : startClickFunction,
    });

    type MicrophoneButtonProps = {
        onClickFunction: () => void;
        isDisabled: boolean;
        buttonCss: string;
        microphoneImageCss: string;
    };

    const MicrophoneButton = ({
        onClickFunction,
        isDisabled,
        buttonCss,
        microphoneImageCss,
    }: MicrophoneButtonProps) => {
        return (
            <button
                onClick={onClickFunction}
                ref={ref}
                disabled={isDisabled}
                className={`${buttonCss} ${speechToTextButtonClassName}`}
            >
                <img
                    className={`${microphoneImageCss}`}
                    src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/TextToSpeechMicrophone.svg"
                    alt=""
                />
            </button>
        );
    };

    return (
        <FocusContext.Provider value={focusKey}>
            {isListening ? (
                <MicrophoneButton
                    onClickFunction={stopClickFunction}
                    isDisabled={!isListening}
                    buttonCss={`h-[5vw] w-[5vw] transition-all flex justify-center items-center rounded-full bg-pizzahut-red ${focused && `border-[0.1vw] border-white scale-115`}`}
                    microphoneImageCss={`w-[3.5vw] h-[3.5vw]`}
                />
            ) : (
                <MicrophoneButton
                    onClickFunction={startClickFunction}
                    isDisabled={isListening}
                    buttonCss={`h-[4vw] w-[4vw] transition-all flex justify-center items-center rounded-full bg-[#2a2a2a] bg-opacity-100 border-[0.1vw] border-black ${focused && `border-white scale-105`}`}
                    microphoneImageCss={`w-[2.5vw] h-[2.5vw]`}
                />
            )}
        </FocusContext.Provider>
    );
};

export default SpeechToTextButton;
