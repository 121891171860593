import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useCallback, useEffect, useState } from 'react';
import BranchCard from './BranchCard';
import { useSelector } from '../../redux/store';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { SearchInput } from '../../components/SearchInput';
import { BranchProperties } from '../../redux/slices/branchesSlice';
import { useDispatch } from 'react-redux';
import { setAppBackground } from '../../redux/slices/layoutSlice';
import BackButton from '../../components/Buttons/BackButton';

type BranchesScreenProps = {
    focusKey: string;
};
export const BranchesScreen = ({ focusKey: focusKeyParam }: BranchesScreenProps) => {
    const TranslateFunction = createTranslateFunction('branches_screen');
    const branchesList = useSelector((state) => state.branches.branchesList);
    const dispatch = useDispatch();
    const [filteredBranches, setFilteredBranches] = useState<BranchProperties[]>(branchesList);
    const [searchTerm, setSearchTerm] = useState('');
    const { ref, focusKey, focusSelf } = useFocusable({
        focusKey: focusKeyParam,
    });

    const onRowFocus = useCallback(
        ({ y }) => {
            if (ref.current) {
                ref.current.scrollTop = y - 500;
                ref.current.style.scrollBehavior = 'smooth';
            }
        },
        [ref],
    );
    const handleInputChange = (value: string) => {
        if (value.length < 34) {
            setSearchTerm(value);
        }
    };
    useEffect(() => {
        setFilteredBranches(
            branchesList.filter((branch) =>
                branch.atmos_data.name.toLowerCase().includes(searchTerm.toLowerCase()),
            ),
        );
    }, [searchTerm]);

    useEffect(() => {
        focusSelf();
        dispatch(
            setAppBackground([
                'https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/generic.jpg',
                'bg-no-repeat bg-cover bg-center',
            ]),
        );
        ref.current.scrollTop = 0;
    }, []);

    return (
        <FocusContext.Provider value={focusKey}>
            <div className={`w-full h-full flex flex-col pt-[3vw] items-center`}>
                <span className="text-[2vw] mb-[2vw]">{`${TranslateFunction('header')}`}</span>
                <BackButton className="absolute top-[3vw] right-[4vw]" />
                <SearchInput
                    classNameParams="!w-[32vw] h-[5vw] text-[2vw] text-center mb-[1vw]"
                    onChange={handleInputChange}
                    inputValue={searchTerm}
                    placeHolderValue={`${TranslateFunction(`search_input_placeholder`)}`}
                />
                <div
                    className={`flex flex-col w-full h-[70%] overflow-y-scroll items-center`}
                    ref={ref}
                >
                    {filteredBranches.map((branch, index) => {
                        return (
                            <BranchCard
                                branchData={branch.atmos_data}
                                branchId={branch.external_branch_id}
                                key={`${branch.atmos_data.name}-${index}`}
                                focusKey={`${branch.atmos_data.name}-${index}`}
                                onRowFocus={onRowFocus}
                            />
                        );
                    })}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
