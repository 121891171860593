import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import CartPopup from '../../components/Cart/CartPopup';
import DeliveryForm from './DeliveryForm/DeliveryForm';
import { useDispatch } from 'react-redux';
import { setAppBackgroundTwClass } from '../../redux/slices/layoutSlice';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { AlertModal } from '../../components/ConfirmModal/AlertModal';
import { Loading } from '../../components/Loading';

type CheckoutScreenProps = {
    focusKey: string;
};
const CheckoutScreen = ({ focusKey: focusKeyParam }: CheckoutScreenProps) => {
    const AlertModalTranslateFunction = createTranslateFunction('checkout_screen.alerts');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
    const [alertModalTitle, setAlertModalTitle] = useState<string>(
        `${AlertModalTranslateFunction('general_problem')}`,
    );

    const dispatch = useDispatch();
    const { ref, focusKey } = useFocusable({
        focusable: true,
        focusKey: focusKeyParam,
    });

    useEffect(() => {
        setFocus(focusKeyParam);
        dispatch(setAppBackgroundTwClass('bg-black'));
        if (JSON.parse(localStorage.getItem('cart') || '[]').length < 1) {
            setFocus('DELIVERYFORM');
        }
        // dispatch(
        //     setAppBackgroundTwClass(
        //         'bg-[url("assets/images/screen-bg/pizzas/faded.png")] bg-no-repeat bg-cover bg-opacity-95',
        //     ),
        // );
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="w-full h-full flex" ref={ref}>
                {isAlertModalOpen && (
                    <AlertModal
                        close={() => setIsAlertModalOpen(false)}
                        focusKey="CHECKOUT-ALERT-MODAL"
                        opened={isAlertModalOpen}
                        title={alertModalTitle}
                    />
                )}
                <DeliveryForm
                    setAlertModalTitle={setAlertModalTitle}
                    setIsAlertModalOpen={setIsAlertModalOpen}
                    focusKey="DELIVERYFORM"
                    setIsLoading={setIsLoading}
                />
                <CartPopup focusKey="CARTPOPUP" />
            </div>
        </FocusContext.Provider>
    );
};

export default CheckoutScreen;
