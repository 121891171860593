import { FocusContext, setFocus, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Extra } from '../../../../redux/slices/dbFoodMenuSlice';
import { useSelector } from '../../../../redux/store';
import ExtraPortionsContainer from './ExtrasRow/ExtraPortionsContainer';
import ExtraPortionSticker from './ExtrasRow/ExtraPortionSticker';
import { calculatePizzaToppingsAmount } from '../../../EditFoodScreen/EditFoodScreenHelperFunctions';

type PizzaExtraCardProps = {
    extraData: Extra;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
    handleErrorMessageFromMelingo: (errorMssage?: string) => void;
};

export function PizzaExtraCard({
    extraData,
    setCurrentFocusIndex,
    componentIndex,
    handleErrorMessageFromMelingo,
}: PizzaExtraCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const { chosenExtras } = useSelector((state) => state.personalAssistant);
    const [chosenExtraSize, setChosenExtraSize] = useState<string>('');

    const handlePizzaExtraCardClick = () => {
        const pizzaExtrasQuantityArray = calculatePizzaToppingsAmount(chosenExtras);
        if (
            (pizzaExtrasQuantityArray[0] >= 3 || pizzaExtrasQuantityArray[1] >= 3) &&
            !chosenExtraSize
        ) {
            handleErrorMessageFromMelingo(`לא ניתן להוסיף יותר מ3 תוספות לצד`);
            return;
        }
        setIsHovered(false);
        setIsOpened((prev) => !prev);
        setFocus(`${extraData.name}-extra-full`);
    };
    const { ref, focused, hasFocusedChild, focusKey } = useFocusable({
        onEnterPress: handlePizzaExtraCardClick,
        onBlur: () => {
            if (isOpened) {
                setIsOpened(false);
            }
        },
        trackChildren: true,
    });
    const removeToppingSizeFromString = (input: string): string => {
        const regex = /[a-zA-Z]/g;
        return input.replace(regex, '');
    };

    const isThereSelectedExtra = () => {
        if (!chosenExtras) {
            return false;
        }
        const extraThatIsSelected = chosenExtras.find((extra) => extra.name === extraData.name);
        if (extraThatIsSelected && extraThatIsSelected.extraSize) {
            setChosenExtraSize(extraThatIsSelected.extraSize);
        } else {
            setChosenExtraSize('');
        }
    };

    useEffect(() => {
        if (focused || isHovered) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused, isHovered]);

    useEffect(() => {
        isThereSelectedExtra();
    }, [chosenExtras]);

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                onClick={handlePizzaExtraCardClick}
                // className={` mx-[1vw] my-[0.5vw] h-[6vw] w-[22%] transition-all duration-200 cursor-pointer flex items-center justify-evenly rounded-full bg-[#2a2a2a] ${isOpened && 'relative transform !w-[30%] !z-10 !bg-[#616161]'} ${focused && 'bg-gray-card border-white border-[0.1vw] scale-105'} ${hasFocusedChild && 'bg-gray-card bg-opacity-80 scale-105'}`} - THIS IS THE ORIGINAL
                className={`transition-all-200 h-[6vw] cursor-pointer flex items-center justify-between px-[2vw] rounded-full bg-[#242424] ${isOpened && `relative !w-[140%] !z-10 !bg-[#616161] ${(componentIndex + 2) % 4 === 0 && `translate-x-[30%]`}`} ${focused && 'bg-gray-card border-white border-[0.1vw] scale-105'} ${hasFocusedChild && 'bg-gray-card bg-opacity-80 scale-105'}`}
            >
                <span className="text-[1.5vw]">{`${removeToppingSizeFromString(extraData.name)}`}</span>
                {isOpened && (
                    <ExtraPortionsContainer extraData={extraData} setIsOpen={setIsOpened} />
                )}
                {!isOpened && chosenExtraSize && (
                    <ExtraPortionSticker toppingPortion={chosenExtraSize} />
                )}
            </div>
        </FocusContext.Provider>
    );
}
