import { getAccessToken } from '../../lib/auth';
import api from '../../lib/axios';
import { PaymentMethod } from '../../types/paymentMethod';

export const getOrderStatus = async (orderId: string) => {
    try {
        const accessToken = getAccessToken()?.access_token;
        const { data } = await api.get(`order/${orderId}/status`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return data;
    } catch (error) {
        console.error('Error fetching order status:', error);
        throw error;
    }
};

export const getOrderStatusNoHash = async (payment_type: string, orderId: string) => {
    try {
        const accessToken = getAccessToken()?.access_token;
        const result = await api.post(
            `/cg/validate_transaction_no_hash`,
            { payment_type: payment_type, order_id: orderId },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return result.data.status;
    } catch (error) {
        return 'error';
    }
};

export const setOrderStatusPending = async (orderId) => {
    try {
        const accessToken = getAccessToken()?.access_token;
        const { data } = await api.post(
            `order/${orderId}/pending`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return data;
    } catch (error) {
        console.error('Error setting order status to pending:', error);
        throw error;
    }
};

export const handleFetchMobilePaymentLink = async (orderId: string) => {
    try {
        const accessToken = getAccessToken()?.access_token;
        const { data } = await api.post(
            `cg/generate_transaction_google_apple_pay`,
            {
                orderId,
            },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        return data.data.link;
    } catch (error) {
        console.error('Error fetching mobile payment link:', error);
        throw error;
    }
};
