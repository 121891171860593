import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Item } from '../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../redux/store';
import { updateNewCurrentQuery } from '../../redux/slices/textToSpeechSlice';

type SuggestionItemCardProps = {
    item: Item;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
};

export function SuggestionItemCard({
    item,
    onFocus,
    focusKey: focusKeyParam,
}: SuggestionItemCardProps) {
    const currentQueryItem = useSelector((state) => state.textToSpeech.currentQuery.reply.items[0]);
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    // const handleMenuItemClick = () => {
    //     const categoryName = item.main_category ? item.main_category.name : item.category.name;
    //     const cartItem = {
    //         item: item,
    //         extras: [],
    //         sauces: [],
    //     };

    //     const state = {
    //         item: cartItem,
    //         categoryName,
    //     };
    //     navigate(`/editfoodscreen`, { state });
    // };

    const handleMenuItemClick = () => {
        console.log(currentQueryItem, 'This is the current query item');

        const updatedItem = {
            ...item,
            extras: currentQueryItem.extras,
        };
        console.log(updatedItem, 'This is the updated item');

        const newCurrentQuery = {
            reply: {
                items: [updatedItem],
            },
        };
        dispatch(updateNewCurrentQuery(newCurrentQuery));
    };

    const { ref, focused } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
        onEnterPress: handleMenuItemClick,
    });

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMenuItemClick()}
            ref={ref}
            className={`w-[16vw] h-[8vw] mx-[1vw] cursor-pointer bg-white bg-opacity-5 border-[0.05vw] border-white text-white rounded-categoryRadius flex justify-evenly items-center ${
                (focused || isHovered) &&
                `
                !bg-selected-hut-red`
            }`}
            data-testid="menu-item"
        >
            <img
                className={`rounded w-[3vw] h-[3vw] object-cover ml-[0.8vw]`}
                src={item.icon}
                alt=""
            />
            <span className={`pl-2 flex items-center text-center justify-center text-[1.2vw]`}>
                {item.name}
            </span>
        </div>
    );
}
