import {
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Item, MenuCategory } from '../../../../redux/slices/dbFoodMenuSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../../redux/store';
import {
    ChangeAssistantStep,
    UpdateChooseItemQuery,
    UpdateChosenCategory,
    UpdateChosenItem,
} from '../../../../redux/slices/personalAssistantSlice';
import SelectedOptionCheckmark from '../ChooseCategory/SelectedOptionCheckmark';
import UnselectedOptionCheckmark from '../ChooseCategory/UnselectedOptionCheckmark';

type ItemCardProps = {
    itemData: Item;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
    shouldBeSmaller: boolean;
    shouldBeBigger: boolean;
    categoryName: string;
};

export function ItemCard({
    itemData,
    onFocus,
    setCurrentFocusIndex,
    componentIndex,
    shouldBeSmaller,
    shouldBeBigger,
    categoryName,
}: ItemCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const { chosenItem } = useSelector((state) => state.personalAssistant);

    const isChosen = chosenItem && chosenItem.name === itemData.name ? true : false;
    const dispatch = useDispatch<AppDispatch>();

    const handleItemCardClick = () => {
        dispatch(UpdateChosenItem(itemData));
        // dispatch(ChangeAssistantStep('chooseToKeepItem'));
        dispatch(UpdateChooseItemQuery(itemData.name));
        setIsHovered(false);
        setFocus('STT-CHOOSE-EXTRAS');
    };
    const { ref, focused } = useFocusable({
        onFocus,
        onEnterPress: handleItemCardClick,
    });

    useEffect(() => {
        if (focused || isHovered) {
            setCurrentFocusIndex(componentIndex);
        }
    }, [focused, isHovered]);

    const shouldReduceSize = categoryName === 'מנות נלוות' ? `!w-[15vw] !h-[8vw]` : ``;
    const shouldReduceKinoohim = categoryName === 'קינוחים' ? `!w-[15vw] !h-[8vw]` : ``;

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleItemCardClick}
            ref={ref}
            className={`w-[17vw] h-[9vw] ${shouldReduceSize} ${shouldReduceKinoohim} ${shouldBeSmaller && '!w-[14.5vw] !h-[7.5vw]'} m-[0.8vw] px-[0.5vw] rounded-categoryRadius cursor-pointer relative bg-[#222222] bg-opacity-60 flex flex-row justify-evenly items-center ${
                (focused || isHovered || isChosen) && '!bg-pizzahut-red !bg-opacity-100'
            } `}
        >
            {(focused || isHovered || isChosen) && <SelectedOptionCheckmark />}
            <img
                className={`rounded-full w-[4vw] h-[4vw] object-cover ${shouldBeBigger && '!w-[7.5vw] h-[7.5vw]'}`}
                src={itemData.icon || '/images/pizzas/pan.jpeg'}
                alt=""
            />
            <span
                className={`overflow-hidden text-[1.6vw] max-w-[50%] ${itemData.name.length > 20 && `!text-[1.4vw]`} ${shouldBeSmaller && `!text-[1.4vw]`}`}
            >
                {itemData.name}
            </span>
        </div>
    );
}
