import i18n from 'i18next';
type TranslationVariables = Record<string, any>;

const createTranslateFunction = (componentName: string) => {
    return (key: string, variables?: TranslationVariables) => {
        return i18n.t(`${componentName}.${key}`, variables);
    };
};

export default createTranslateFunction;
