import { useRef, useCallback, useState } from 'react';
import {
    useFocusable,
    FocusContext,
    FocusableComponentLayout,
    FocusDetails,
} from '@noriginmedia/norigin-spatial-navigation';
import { MenuCard } from './MenuCard';
import { useSelector } from '../../../redux/store';
import IsAbleToScrollIndicator from '../../../components/ScrollingIndicator/IsAbleToScrollIndicator';

type MenuRowProps = {
    title: string;
    onFocus: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    focusKey: string;
};

export const MenuRow = ({ title, onFocus, focusKey: focusKeyParam }: MenuRowProps) => {
    const allMenuItems = useSelector((state) => state.dbFoodMenu.categories);
    const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
    const { ref, focusKey } = useFocusable({
        onFocus,
        focusKey: focusKeyParam,
        preferredChildFocusKey: `${focusKeyParam}-0`,
    });
    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (scrollingRef.current) {
                scrollingRef.current.scrollLeft = x;
                scrollingRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [scrollingRef],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div className="flex flex-col overflow-x-auto" ref={ref}>
                <IsAbleToScrollIndicator
                    indexInRow={currentFocusIndex}
                    rowLength={allMenuItems.length}
                    titleString={title}
                />
                <div className={`flex overflow-x-auto`} ref={scrollingRef}>
                    {allMenuItems.map((asset, index) => (
                        <MenuCard
                            key={index}
                            category={asset}
                            onFocus={onAssetFocus}
                            focusKey={`${focusKey}-${index}`}
                            index={index}
                            setCurrentFocusIndex={setCurrentFocusIndex}
                        />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
