import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useSelector } from '../../redux/store';
import TransparentButton from '../../components/Buttons/TransparentButton';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import BackButton from '../../components/Buttons/BackButton';

const NoFavoriteItems = () => {
    const navigate = useNavigate();
    const userName = useSelector((state) => state.userAuth.user?.first_name);
    const noFavoriteItemsTranslation = createTranslateFunction(
        'favorite_items_screen.no_favorite_items',
    );

    const { ref, focusKey, focusSelf } = useFocusable({});
    useEffect(() => {
        focusSelf();
    }, []);
    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="w-full h-full flex flex-col justify-center items-center">
                <BackButton className="absolute top-[3vw] right-[4vw]" />
                <div className="flex flex-col justify-center items-center mb-[2vw]">
                    <img
                        className="w-[5vw] h-[5vw] mb-[1vw]"
                        src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/pizzahut_logo.svg"
                        alt=""
                    />
                    <span className="text-[2.2vw] font-bold text-gray-500">
                        {noFavoriteItemsTranslation('greetings', { name: userName })}
                    </span>
                    <span className="text-[2.2vw] font-bold text-gray-500">
                        {noFavoriteItemsTranslation('favorite_items_are_empty')}
                    </span>
                </div>
                <div className="flex flex-col justify-center items-center mb-[4vw]">
                    <span className="text-[2vw] text-gray-500">
                        {noFavoriteItemsTranslation('add_items_to_your_favorites')}
                    </span>
                    <span className="text-[2vw] text-gray-500">
                        {noFavoriteItemsTranslation('on_favorite_item_page')}
                    </span>
                </div>
                <TransparentButton
                    className="text-[2vw] w-[16vw] h-[6vw]"
                    onClick={() => navigate('/')}
                >
                    {noFavoriteItemsTranslation('back_to_home_button')}
                </TransparentButton>
            </div>
        </FocusContext.Provider>
    );
};

export default NoFavoriteItems;
