import {
    FocusDetails,
    FocusableComponentLayout,
    setFocus,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useNavigate } from 'react-router-dom';
import { toggleMenu } from '../../../redux/slices/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import 'animate.css';
import { MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';
import { encodeSlash } from '../../../helpers/url';
import { useState } from 'react';

type MenuCardProps = {
    category: MenuCategory;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
};
export function SideMenuCard({ category, onFocus }: MenuCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMenuOpen = useSelector((state: any) => state.menu.isMenuOpen);

    const handleMenuItemClick = () => {
        if (isMenuOpen) {
            setFocus(`CHOSENMENU`);
        } else {
            setFocus(`SIDEBAR`);
        }
        dispatch(toggleMenu());
        navigate(`/menu/${encodeSlash(category.name)}`);
    };

    const { ref, focused } = useFocusable({
        onFocus,
        onEnterPress: handleMenuItemClick,
        isFocusBoundary: true,
    });

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMenuItemClick()}
            ref={ref}
            className={`max-w-[13vw] h-[4vw] px-[1.6vw] my-[0.3vw] cursor-pointer flex justify-start bg-opacity-20 rounded-categoryRadius items-center ${
                (focused || isHovered) && `!bg-white !bg-opacity-20 `
            }`}
            data-testid="menu-item"
        >
            {/* <img
                className={`rounded !w-[1.4vw] !h-[1.4vw] ml-[0.7vw]`}
                src={`${category.icon}`}
                alt=""
            /> */}
            <span className={`pl-2 flex items-center justify-center text-[1.3vw]`}>
                {`${category.name}`}
            </span>
        </div>
    );
}
