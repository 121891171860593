import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';

type FoodToppingPortionStickerProps = {
    extraSize: string;
    focusKey: string;
};

const FoodToppingPortionSticker = ({
    focusKey: focusKeyParam,
    extraSize,
}: FoodToppingPortionStickerProps) => {
    return (
        <div
            className={`w-[2.4vw] h-[2.4vw] p-[0.3vw] rounded-full flex items-center bg-selected-hut-red ml-[1vw] ${extraSize === 'full' ? `justify-center` : `${extraSize === 'halfleft' ? `justify-end` : `justify-start`}`}`}
        >
            {extraSize === 'full' && (
                <img
                    className="w-[1.2vw] h-[1.2vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/full_topping.svg"
                    alt="no svg"
                />
            )}
            {extraSize === 'halfright' && (
                <img
                    className="w-[1.2vw] h-[1.2vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfright_topping.svg"
                    alt="no svg"
                />
            )}
            {extraSize === 'halfleft' && (
                <img
                    className="w-[1.2vw] h-[1.2vw]"
                    src="https://pizzahut-assets.s3.us-east-1.amazonaws.com/icons/assets/halfleft_topping.svg"
                    alt="no svg"
                />
            )}
        </div>
    );
};

export default FoodToppingPortionSticker;
