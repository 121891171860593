import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation';

import { CartItem } from '../../../redux/slices/cartSlice';
import { Extra, Sauce } from '../../../redux/slices/dbFoodMenuSlice';
import ThreeToppingImages from '../../../screens/HomeScreen/PastOrders/RestorePastOrderScreen/PastOrderCartPopUp/ThreeToppingImages';
import {
    calculateTotalCartPrice,
    calculateTotalCartSuggestionPrice,
    removeSizeLetterFromName,
} from '../../../screens/EditFoodScreen/EditFoodScreenHelperFunctions';

type ItemSuggestionPopupProps = {
    chosenSuggestionCart: CartItem[];
    focusKey: string;
};
const ItemSuggestionPopup = ({
    chosenSuggestionCart,
    focusKey: focusKeyParam,
}: ItemSuggestionPopupProps) => {
    const { ref, focusKey, focused } = useFocusable({
        focusKey: focusKeyParam,
    });

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className={`w-[100%] my-[1vw] border-b-[1px] border-b-gray-400 flex flex-col p-[2vh] relative ${focused && `!border-white !border-4 !rounded-lg`}`}
            >
                {chosenSuggestionCart.map((item: CartItem, index) => (
                    <div key={index} className="w-full flex items-start justify-between ml-[1.2vw]">
                        <img
                            className="w-[5vw] h-[5vw] bg-gray-card  rounded-[1.4vw] object-cover"
                            src={`${item.item.icon ? item.item.icon : 'https://png.pngtree.com/png-clipart/20230320/ourmid/pngtree-round-pizza-realistic-fast-food-digital-artwork-png-image_6657786.png'}`}
                            alt="cartItemIcon"
                        />

                        <div className="flex flex-col flex-1 mx-[1vw]">
                            <div className="flex justify-between text-[1.2vw] font-bold">
                                <span className="text-white text-[1.5vw]">{item.item.name}</span>
                                <span className="text-white text-[1.5vw]">₪{item.item.price}</span>
                            </div>
                            <div className="flex flex-col m-[1vw]">
                                {item.extras.map((extra: Extra, index: number) => (
                                    <div
                                        key={`cartItem-Row-${index}`}
                                        className={
                                            'flex items-center justify-between w-[100%] mb-[1.5vw]'
                                        }
                                    >
                                        <div className="flex justify-center items-center my-[0.8vw]">
                                            <img
                                                className="w-[2vw] h-[2vw] ml-[1vw]"
                                                src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                                alt=""
                                            />
                                            <span
                                                className="text-white text-[1.4vw] text-center"
                                                key={index}
                                            >{`${removeSizeLetterFromName(extra.name)}`}</span>
                                        </div>
                                        <div className="flex items-center">
                                            <ThreeToppingImages whatSizeSelected={extra} />
                                            <span
                                                className="text-white text-[1.4vw] mr-[8vw]"
                                                key={index}
                                            >{`+ ${extra.price} ₪`}</span>
                                        </div>
                                    </div>
                                ))}
                                {item.sauces &&
                                    item.sauces.map((sauce: Sauce, index: number) => (
                                        <div
                                            key={`cartItem-Row-sauce-${index}`}
                                            className={'flex flex-col mb-[1.5vw]'}
                                        >
                                            {Array(sauce.sauceQuantity)
                                                .fill(0)
                                                .map((_, index) => {
                                                    return (
                                                        <div
                                                            className="flex justify-between w-[100%] mb-[0.8vw]"
                                                            key={`cartItem-secondRow-sauce-${index}`}
                                                        >
                                                            <div className="flex items-center">
                                                                <img
                                                                    className="w-[2vw] h-[2vw] ml-[1vw]"
                                                                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                                                    alt=""
                                                                />
                                                                <span
                                                                    className="text-white text-[1.4vw] text-center"
                                                                    key={index}
                                                                >{`${sauce.name}`}</span>
                                                            </div>
                                                            <span
                                                                className="text-white text-[1.4vw]"
                                                                key={index}
                                                            >{`+ ${sauce.price} ₪`}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    ))}
                                {
                                    <span className="text-white text-[1.5vw] font-bold absolute left-[2vw] bottom-[0.4vw]">
                                        סך הכל: ₪
                                        {calculateTotalCartSuggestionPrice(chosenSuggestionCart)}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </FocusContext.Provider>
    );
};

export default ItemSuggestionPopup;
