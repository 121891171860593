import {
    FocusDetails,
    FocusableComponentLayout,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Item, MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';
import { useNavigate } from 'react-router';
import { setAppBackgroundTwClass } from '../../../redux/slices/layoutSlice';

type ChosenMenuCardProps = {
    focusKey: string;
    setFocusedData: (categoryOrItem: MenuCategory | Item) => void;
    displaySecondRowFunction?: (categoryData: MenuCategory) => void;
    categoryOrItem: MenuCategory | Item;
    onFocus?: (layout: FocusableComponentLayout, props: object, details: FocusDetails) => void;
    reducedSize?: boolean;
    selectedFirstRowData?: MenuCategory;
    setCurrentFocusIndex: React.Dispatch<React.SetStateAction<number>>;
    componentIndex: number;
};

export function ChosenMenuCard({
    focusKey: focusKeyParam,
    setFocusedData,
    displaySecondRowFunction,
    categoryOrItem,
    onFocus,
    reducedSize,
    selectedFirstRowData,
    setCurrentFocusIndex,
    componentIndex,
}: ChosenMenuCardProps) {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const { ref, focused } = useFocusable({
        onFocus,
        onEnterPress: handleMenuItemClick,
        focusKey: focusKeyParam,
        extraProps: { categoryOrItem },
    });

    function handleMenuItemClick() {
        // Check if categoryOrItem is MenuCategory or Item
        if ('items' in categoryOrItem) {
            displaySecondRowFunction && displaySecondRowFunction(categoryOrItem);
        } else {
            const categoryName = categoryOrItem.main_category
                ? categoryOrItem.main_category.name
                : categoryOrItem.category.name;

            const cartItem = {
                item: categoryOrItem,
                extras: [],
                sauces: [],
            };

            console.log(cartItem, 'This is the cart item!');
            const state = {
                item: cartItem,
                categoryName,
            };
            navigate(`/editfoodscreen`, { state });
        }
    }

    const isCategorySelected = () => {
        if (selectedFirstRowData) {
            if (selectedFirstRowData._id === categoryOrItem._id) {
                return true;
            }
        } else {
            return false;
        }
    };
    useEffect(() => {
        if (focused || isHovered) {
            setFocusedData(categoryOrItem);
            setCurrentFocusIndex(componentIndex);
            if (categoryOrItem.name === 'פיצות מיוחדות') {
                setAppBackgroundTwClass(
                    'bg-[url("https://pizzahut-assets.s3.amazonaws.com/screen-bg/menu/cheesy2.jpg")] bg-no-repeat bg-cover',
                );
            }
        }
    }, [focused, isHovered]);

    const shouldImageBeBigger = categoryOrItem.name.includes('רוטב') ? true : false;

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleMenuItemClick()}
            ref={ref}
            className={`${reducedSize ? `w-[13.6vw] h-[7.3vw]` : `w-[16.7vw] h-[8.5vw]`}  ml-[1.6vw] px-[0.5vw] rounded-categoryRadius cursor-pointer bg-white backdrop-blur-sm bg-opacity-20 flex grow-0 shrink-0 flex-row justify-evenly items-center ${
                (focused || isHovered || isCategorySelected()) && '!bg-pizzahut-red !bg-opacity-100'
            } `}
        >
            <img
                className={`rounded-full ${reducedSize ? `w-[4.7vw] h-[4.7vw]` : `w-[7.5vw] h-[7.5vw]`} object-cover ml-[0.3vw] ${shouldImageBeBigger && `!w-[9vw] !h-[9vw]`}`}
                src={categoryOrItem.icon || '/images/pizzas/pan.jpeg'}
                alt=""
            />
            <span className={`overflow-hidden text-[1.3vw] max-w-[8vw]`}>
                {categoryOrItem.name}
            </span>
        </div>
    );
}
