import {
    FocusableComponentLayout,
    FocusContext,
    FocusDetails,
    useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import createTranslateFunction from '../../../../i18n/createTranslateFunction';
import TransparentButton from '../../../../components/Buttons/TransparentButton';
import { IconPaperBag, IconTruckDelivery } from '@tabler/icons-react';
import {
    UpdateDeliveryFormData,
    UpdateDeliveryFormDataType,
} from '../../../../redux/slices/deliveryFormSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../redux/store';

type DeliveryOrPickupProps = {
    focusKey: string;
    onRowFocus: (layout: FocusableComponentLayout, props: unknown, details: FocusDetails) => void;
};
const DeliveryOrPickup = ({ focusKey: focusKeyParam, onRowFocus }: DeliveryOrPickupProps) => {
    const dispatch = useDispatch();
    const TranslateFunction = createTranslateFunction('checkout_screen.delivery_form');
    const deliveryFormData = useSelector((state) => state.deliveryFormData.deliveryForm);

    const handleDeliveryFormUpdation = (updates: UpdateDeliveryFormDataType) => {
        dispatch(UpdateDeliveryFormData(updates));
    };
    const handleDeliveryButtonPress = () => {
        handleDeliveryFormUpdation({
            order_type: 0,
            address: undefined,
            branch_id: undefined,
            future_date: undefined,
        });
    };

    const handlePickupButtonPress = () => {
        handleDeliveryFormUpdation({
            order_type: 1,
            delivery_price: 0,
        });
    };
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
    });

    const isDeliverySelected = () => {
        return deliveryFormData.order_type === 0;
    };

    const selectedOptionCheckmark = () => {
        return (
            <div
                className={`absolute object-contain bg-cover bg-center top-[-1vw] right-[-1vw] w-[2.7vw] h-[2.7vw]`}
                style={{
                    backgroundImage: `url(https://pizzahut-assets.s3.amazonaws.com/icons/menu_icons/delivery_option_checkmark.svg)`,
                }}
            ></div>
        );
    };

    return (
        <FocusContext.Provider value={focusKey}>
            <div ref={ref} className="mb-[2vw]">
                <div className={'my-[2vw]'}>
                    <span className="text-[1.2vw] text-gray-400">
                        {TranslateFunction('sub_headers.choose_option')}
                    </span>
                </div>
                <div className={'flex items-center'}>
                    <TransparentButton
                        className={`w-[15vw] h-[6vw] ml-[1vw] rounded-[1.1vw] flex items-center justify-center relative
                                ${isDeliverySelected() && '!bg-pizzahut-red'}
                            `}
                        onClick={handleDeliveryButtonPress}
                        onBtnFocus={onRowFocus}
                        focusKey="DELIVERY-BUTTON"
                    >
                        {isDeliverySelected() && selectedOptionCheckmark()}
                        <span className={'mx-[1.5vw]'}>
                            {TranslateFunction('buttons.delivery')}
                        </span>
                        <img
                            className="w-[4vw]"
                            src="https://pizzahut-assets.s3.amazonaws.com/icons/assets/delivery_motorcycle.svg"
                        />
                    </TransparentButton>
                    <TransparentButton
                        className={`w-[15vw] h-[6vw] rounded-[1.1vw] flex items-center justify-center relative
                                ${!isDeliverySelected() && '!bg-pizzahut-red'}
                            `}
                        onBtnFocus={onRowFocus}
                        onClick={handlePickupButtonPress}
                        focusKey="PICKUP-BUTTON"
                    >
                        {!isDeliverySelected() && selectedOptionCheckmark()}
                        <span className={'mx-[1.5vw]'}>{TranslateFunction('buttons.pickup')}</span>
                        <IconPaperBag size={'4vh'} />
                    </TransparentButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default DeliveryOrPickup;
