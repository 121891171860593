import { useNavigate } from 'react-router-dom';
import TransparentButton from '../../components/Buttons/TransparentButton';
import createTranslateFunction from '../../i18n/createTranslateFunction';

const DidYouGetTheOrderPopup = () => {
    const TranslateFunction = createTranslateFunction('track_order.did_you_get_the_order_popup');
    const navigate = useNavigate();
    const handleOrderRecieved = () => {
        localStorage.removeItem('TrackLastOrder');
        navigate('/');
    };
    return (
        <div className="flex flex-col justify-center items-center absolute top-[38vw] left-[10vw]">
            <div className="flex flex-col justify-center items-center mb-[2vw]">
                <span className="text-[2vw] mb-[1vw]">{TranslateFunction('title')}</span>
                <TransparentButton
                    onClick={handleOrderRecieved}
                    className="!text-[1.6vw] w-[20vw] h-[5vw] bg-opacity-20"
                >
                    {TranslateFunction('confirmation_button')}
                </TransparentButton>
            </div>
            <div className="flex flex-col justify-center items-center">
                <span className="text-[1.3vw] opacity-70">{TranslateFunction('problem_text')}</span>
                <span className="text-[1.3vw] opacity-70">{TranslateFunction('call_us')}</span>
            </div>
        </div>
    );
};

export default DidYouGetTheOrderPopup;
