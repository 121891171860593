import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type initialState = {
    appBackgroundTwClass: string;
    appBackgroundSrc: string;
};

const initialState: initialState = {
    appBackgroundSrc: '',
    appBackgroundTwClass: 'bg-no-repeat bg-cover bg-center',
};

// type setAppBackgroundProps = {
//     appBackgroundSrc?: string;
//     appBackgroundTwClass?: string;
// };
type setAppBackgroundProps = [string, string];

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAppBackgroundTwClass: (state, action: PayloadAction<string>) => {
            console.log(action.payload);
            state.appBackgroundTwClass = action.payload;
            state.appBackgroundSrc = '';
        },
        setAppBackground: (state, action: PayloadAction<setAppBackgroundProps>) => {
            const bg_payload = action.payload;
            const appBackgroundSrc = bg_payload[0];
            const appBackgroundTwClass = bg_payload[1];
            if (appBackgroundSrc.length > 0) {
                state.appBackgroundSrc = appBackgroundSrc;
            }
            if (appBackgroundTwClass.length > 0) {
                state.appBackgroundTwClass = appBackgroundTwClass;
            }
        },
    },
});

export const { setAppBackgroundTwClass, setAppBackground } = layoutSlice.actions;

export default layoutSlice.reducer;
