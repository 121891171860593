import React, { useRef, useCallback } from 'react';
import { useFocusable, FocusContext } from '@noriginmedia/norigin-spatial-navigation';
import { SideMenuCard } from './SideMenuCard';
import { useDispatch } from 'react-redux';
import { openMenu, toggleMenuFalse } from '../../../redux/slices/menuSlice';
import { MenuCategory } from '../../../redux/slices/dbFoodMenuSlice';

type MenuRowProps = {
    focusKey: string;
    menuCategories: MenuCategory[];
};

export const SideMenuRow = ({ focusKey: focusKeyParam, menuCategories }: MenuRowProps) => {
    const dispatch = useDispatch();
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus: () => {
            dispatch(openMenu());
        },
        onEnterPress: () => {
            dispatch(toggleMenuFalse());
        },
    });
    const scrollingRef = useRef<HTMLDivElement>(null);

    const onAssetFocus = useCallback(
        ({ x }) => {
            if (scrollingRef.current) {
                scrollingRef.current.scrollLeft = x;
                scrollingRef.current.style.scrollBehavior = 'smooth';
            }
        },
        [scrollingRef],
    );

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                className={`
                absolute right-[14vw] bg-gradient-to-l from-black w-[60%] h-full z-20

                `}
                ref={ref}
            >
                <div className={`flex flex-col justify-center h-full`} ref={scrollingRef}>
                    {menuCategories.map((asset, index) => (
                        <SideMenuCard key={index} category={asset} onFocus={onAssetFocus} />
                    ))}
                </div>
            </div>
        </FocusContext.Provider>
    );
};
