import {
    FocusableComponentLayout,
    FocusContext,
    useFocusable,
    setFocus,
} from '@noriginmedia/norigin-spatial-navigation';
import React, { useState } from 'react';
import TransparentButton from '../Buttons/TransparentButton';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { CartItem, removeFromCart } from '../../redux/slices/cartSlice';
import { Extra, Sauce } from '../../redux/slices/dbFoodMenuSlice';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import FoodToppingPortionSticker from '../FoodToppingPortionSticker';
import createTranslateFunction from '../../i18n/createTranslateFunction';
import { removeSizeLetterFromName } from '../../screens/EditFoodScreen/EditFoodScreenHelperFunctions';

type cartItemProps = {
    cartItem: any;
    focusKey: string;
    cartItemIndex: number;
    onFocus: (layout: FocusableComponentLayout, props: unknown, details: object) => void;
};
const CartItemRow = ({
    cartItem,
    focusKey: focusKeyParam,
    cartItemIndex,
    onFocus,
}: cartItemProps) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ref, focusKey } = useFocusable({
        focusKey: focusKeyParam,
        onFocus,
    });

    const confirmModalTranslate = createTranslateFunction(`confirm_modal`);
    const buttonsTranslate = createTranslateFunction(`buttons`);

    //Add category name to the state
    console.log(cartItem, 'This is the cart item from the checkout');

    const handleEditCartItem = () => {
        const categoryName = cartItem.item.main_category
            ? cartItem.item.main_category.name
            : cartItem.item.category.name;
        navigate(`/editfoodscreen`, {
            state: { item: cartItem, cartIndex: cartItemIndex, categoryName: categoryName },
        });
    };

    const handleRemoveCartItem = () => {
        dispatch(removeFromCart({ itemIndex: cartItemIndex }));
    };

    const shouldDisplayEditButton = () => {
        return cartItem.item.extras?.length > 0 || cartItem.item.sauces?.length > 0;
    };

    const isCategoryPizza = () => {
        return cartItem.item.category.name.includes('פיצה');
    };

    const areThereSelectedExtras = cartItem.extras.length > 0;

    console.log(cartItem, 'This is the cart item from cart item row.');

    return (
        <FocusContext.Provider value={focusKey}>
            <div
                ref={ref}
                className="w-[100%] my-[1vw] border-b-[1px] border-b-gray-400 flex flex-col p-[2vh]"
            >
                {confirmModalOpen && (
                    <ConfirmModal
                        title={confirmModalTranslate(`delete_item_title`)}
                        focusKey="CONFIRM-DELETE-MODAL"
                        opened={confirmModalOpen}
                        close={() => setConfirmModalOpen(false)}
                        modalAnswerFunction={handleRemoveCartItem}
                    />
                )}
                <div className="w-full flex items-start justify-between ml-[1.2vw]">
                    <img
                        className="w-[5vw] h-[5vw] bg-gray-card rounded-[1.4vw] object-cover"
                        src={
                            cartItem.item.icon
                                ? cartItem.item.icon
                                : 'https://png.pngtree.com/png-clipart/20230320/ourmid/pngtree-round-pizza-realistic-fast-food-digital-artwork-png-image_6657786.png'
                        }
                        alt="cartItemIcon"
                    />

                    <div className="flex flex-col flex-1 mx-[1vw]">
                        <div className="flex justify-between text-[1.2vw] font-bold">
                            <span>{removeSizeLetterFromName(cartItem.item.name)}</span>
                            <span>{cartItem.item.price}₪</span>
                        </div>
                        {areThereSelectedExtras && (
                            <div className="flex flex-col text-[1vw] my-[1vw]">
                                {cartItem.extras.map((extra: Extra, index: number) => (
                                    <div
                                        key={`cart-${extra.name}-${index}-row`}
                                        className={'flex justify-between mb-[1vw]'}
                                    >
                                        <div className="flex items-center">
                                            {isCategoryPizza() && (
                                                <FoodToppingPortionSticker
                                                    extraSize={`${extra.extraSize}`}
                                                    focusKey={`${extra.name}-${extra.extraSize}-topping`}
                                                />
                                            )}
                                            <span className="text-[1.2vw]">{`${removeSizeLetterFromName(extra.name)}`}</span>
                                        </div>
                                        <span className="text-[1.2vw]">{`+ ₪ ${extra.price}`}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="flex flex-col text-[1vw] m-[0.7vw]">
                            {cartItem.sauces &&
                                cartItem.sauces.map((sauce: Sauce, sauceIndex: number) => {
                                    return (
                                        <div key={`cart-${sauce.name}-${sauceIndex}-firstrow`}>
                                            {Array(sauce.sauceQuantity)
                                                .fill(0)
                                                .map((_, quantityIndex) => {
                                                    let displayPrice = sauce.price;
                                                    if (sauceIndex === 0 && quantityIndex === 0) {
                                                        displayPrice = 0;
                                                    }
                                                    return (
                                                        <div
                                                            key={`cart-${sauce.name}-${quantityIndex}-row`}
                                                            className={
                                                                'flex mb-[0.2vw] justify-between'
                                                            }
                                                        >
                                                            <span className="text-[1.2vw]">{`${sauce.name}`}</span>
                                                            <span className="text-[1.2vw]">{`+ ₪ ${displayPrice}`}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    );
                                })}
                        </div>
                        {/*<div className=" flex justify-between text-[1.2vw] font-bold">*/}
                        {/*    <span></span>*/}
                        {/*    <span>{`₪${calculateFinalPrice(cartItem)}`}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="flex justify-end mt-[2vh]">
                    {shouldDisplayEditButton() && (
                        <TransparentButton
                            focusKey={`${focusKey}-EDITCARTITEM`}
                            className="w-[7vw] flex items-center mx-[1vw]"
                            onClick={handleEditCartItem}
                        >
                            <span className={'mx-[0.7vw]'}>{buttonsTranslate('edit')}</span>
                            <IconPencil size={'1.3vw'} />
                        </TransparentButton>
                    )}
                    <TransparentButton
                        focusKey={`${focusKey}-REMOVECARTITEM`}
                        className="w-[7vw] flex items-center"
                        onClick={() => {
                            setConfirmModalOpen(true);
                            setFocus(`CONFIRM-DELETE-MODAL`);
                        }}
                    >
                        <span className={'mx-[0.7vw]'}>{buttonsTranslate('remove')}</span>
                        <IconTrash size={'1.3vw'} />
                    </TransparentButton>
                </div>
            </div>
        </FocusContext.Provider>
    );
};

export default CartItemRow;
