const enUS = {
    header: {
        back_button: 'Back',
        add_button: 'Add',
    },
    buttons: {
        home_button: 'Home',
        save_button: 'Save',
    },
    login: {
        title: 'TV Registration',
        form: {
            phoneNumber: 'Phone Number',
            code: 'Enter Code',
            button: 'Register',
        },
    },
    register_form: {
        title: 'Hey Guest',
        sub_title: 'You have to go through a short registration process',
        register_button: 'Register',
        next_button: 'Next',
        skip_button: 'Skip',
        personal_info_form: {
            first_name: 'First Name',
            last_name: 'Last Name',
            birth: 'Date of Birth',
            accept_tos: 'Accept our Privacy Policy',
        },
        address_form: {
            title: 'Address Details',
            sub_title: 'Please fill in your address details',
            city: 'City',
            street: 'Street',
            no: 'No',
            floor: 'Floor',
            instructions: 'Instructions',
        },
        credit_card_form: {
            title: 'Credit Card Details',
            sub_title: 'Please fill in your credit card details',
            card_number: 'Card Number',
            expiration_date: 'Expiration Date (MM/YY)',
            cvv: 'CVV',
            id_number: 'ID Number',
        },
    },
    home: {
        cards: {
            hi: 'Hi, {{name}}',
            credit_card: 'Credit Cards',
            profile: 'Profile',
            connected_tvs: 'TVs',
            my_address: 'Addresses',
        },
        connected_tv_note: '{{count}} TVs connected',
        address_note: '{{count}} addresses',
        credit_card_note: '{{count}} credit cards',
        no_connected_tv: 'No connected TVs',
        no_address: 'No addresses',
        no_credit_card: 'No credit cards',
        complete_details: 'Need to complete details',
        get_a_code: 'Get A Code',
        logout_button: 'Logout',
    },
    address: {
        title: 'Your Addresses',
        city: 'City',
        street: 'Street',
        no: 'No',
        floor: 'Floor',
        instructions: 'Instructions',
        searchInput: 'Search for your street',
        card: {
            set_primary: 'Set as primary',
            edit_button: 'Edit',
            remove_button: 'Remove',
        },
        form: {
            title: 'Address Details',
            sub_title: 'Please fill in your address details',
        },
    },
    credit_card: {
        title: 'Credit Cards',
        card_number: 'No',
        expiration_date: 'Exp',
        cvv: 'CVV',
        id_number: 'ID Number',
        name: 'Name',
        card: {
            edit_button: 'Edit',
            remove_button: 'Remove',
            set_primary: 'Set as primary',
        },
        form: {
            title: 'Credit Card Details',
            sub_title: 'Please fill in your credit card details',
            card_number: 'Card Number',
            expiration_date: 'Expiration Date (MM/YY)',
            cvv: 'CVV',
            id_number: 'ID Number',
            save_button: 'Save',
        },
    },
    skip_card: {
        hi: 'שלום, {{name}}',
        recommend:
            "We recommend you to fill your credit card details now so that you don't have to do it for every order.",
        discount:
            'In addition, if you fill in your credit card details now, you will receive a 50% discount from us including free delivery on your first order.',
        fill_button: 'I want to fill my Credit Card',
        register_button: 'Register',
    },
    profile: {
        hi: 'Hi, {{name}}',
        name: 'Name',
        email: 'Email',
        email_not_set: 'Email not set',
        phone: 'Phone',
        edit_button: 'Edit',
        form: {
            title: 'Profile Details',
            sub_title: 'Please fill in your profile details',
        },
    },
    connected_tv: {
        title: 'Your Connected TVs',
        add_button: 'Add',
        card: {
            remove: 'Remove',
        },
        form: {
            title: 'Set a TV name',
            name: 'TV name',
            sub_title: 'Please fill in your TV details',
        },
    },
    associate_tv: {
        title: 'Your code is',
        time_left: 'Please you have {{time}} seconds to connect',
        new_code: 'Get a new code',
    },
    not_found: {
        title: 'Oops.. you are lost',
    },
    already_registered: {
        title: 'Hey {{name}}',
        message: 'You are already registered in the system, Get the code to connect',
        get_code_button: 'Get a Code',
        skip_button: 'Skip',
    },
    privacy_policy: {
        title: 'Privacy Policy',
        introduction: 'Introduction',
        content: `                In our mission to make commerce better for everyone at Shopify, we collect and use
        information about you, our
        \n
        * merchants using Shopify to power your business
        \n
        * customers who shop at a Shopify-powered business
        \n* partners who develop apps for merchants to use, build stores on behalf of
        merchants, refer potential entrepreneurs to Shopify, or otherwise help merchants
        operate or improve their Shopify-powered business
        \n* users of Shopify apps and services like Shop or Shop Pay
        \n* visitors to Shopify's websites, or anyone contacting Shopify support This
        Privacy Policy will help you better understand how we collect, use, and share your
        personal information. If we change our privacy practices, we may update this privacy
        policy. If any changes are significant, we will let you know (for example, through
        the Shopify admin or by email). Our values Trust is the foundation of the Shopify
        platform and includes trusting us to do the right thing with your information. Three
        main values guide us as we develop our products and services. These values should
        help you better understand how we think about your information and privacy. Your
        information belongs to you We carefully analyze what types of information we need to
        provide our services, and we try to limit the information we collect to only what we
        really need. Where possible, we delete or anonymize this information when we no
        longer need it. When building and improving our products, our engineers work closely
        with our privacy and security teams to build with privacy in mind. In all of this
        work our guiding principle is that your information belongs to you, and we aim to
        only use your information to your benefit.`,
    },
};

export default enUS;
